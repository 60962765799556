<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item active" aria-current="page">Vendors</li>
  </ol>
</nav>


<app-list [items]="items" [actions]="actions" [layout]="layout" (action)="handleAction($event)" ></app-list>



