<div>

  <span class="lead"><b><a [routerLink]="[]" (click)="showSampleIds($event)">{{numberOfIds}}</a></b> VID's Uploaded </span>


  <input #fileInput type="file"
         id="file"
         accept=".csv"
         [disabled]="disabled"
         (change)="handleFileInput($event)">
</div>

