
<div *ngIf="quotas?.totals.length>0" class="row mb-3">
  <div class="col">
    <div class="card">
      <div class="card-header lead">
        Quotas
      </div>
      <div class="card-body">
        <div  class="row">
          <div class="col table-responsive">
            <table class="table">
              <caption>Totals</caption>
              <thead>
                <tr>
                  <th scope="col">Demographic</th>
                  <th scope="col">Option</th>
                  <th scope="col">N</th>
                  <th scope="col">Clicks</th>
                  <th scope="col">Completes</th>
                  <th scope="col">Hard Stop</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of quotas?.totals" [ngClass]="{'table-danger': item.isDeleted}">
                  <td>{{item.questionKey}}</td>
                  <td>{{item.optionText}}</td>
                  <td>{{item.n}}</td>
                  <td>{{item.clicks}}</td>
                  <td>{{item.completes}}</td>
                  <td>

                    {{item.hardStop===1? 'Enabled':'Disabled'}}

                  </td>


                </tr>
              </tbody>
            </table>
          </div>

        </div>

        <!--
          <div  *ngFor="let item of quotas?.vendors"  class="row mt-3">
            <div class="col table-responsive">
              <table class="table">
                <caption>{{item.vendorName}}</caption>
                <thead>
                  <tr>
                    <th scope="col">Demographic</th>
                    <th scope="col">Option</th>
                    <th scope="col">N</th>
                    <th scope="col">Clicks</th>
                    <th scope="col">Completes</th>
                    <th scope="col">Hard Stop</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of item?.quotas" [ngClass]="{'table-danger': item.isDeleted}">
                    <td>{{item.questionKey}}</td>
                    <td>{{item.optionText}}</td>
                    <td>{{item.n}}</td>
                    <td>{{item.clicks}}</td>
                    <td>{{item.completes}}</td>
                    <td>

                      {{item.hardStop===1? 'Enabled':'Disabled'}}

                    </td>


                  </tr>
                </tbody>
              </table>
            </div>





      </div>
        -->

    </div>
