import { DropDown } from './../_models/drop-down';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DropDownService {

  constructor(
    private http: HttpClient
  ) { }

  vendors() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/vendors`)
  }
  clients() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/clients`)
  }

  instances() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/instances`)
  }

  projects() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/projects`)
  }

  users() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/users`)
  }

  categories1List() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/categories1`)
  }

  categories2List() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/categories2`)
  }

  categories3List() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/categories3`)
  }

  appnamesList() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/appnames`)
  }

  domainsList() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/domains`)
  }

  chainsList() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/chains`)
  }

  statesList() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/states`)
  }

  languagesList() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/languages`)
  }

  socialAdvertiserList() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/social-advertiser`)
  }

  socialNetworkList() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/social-network`)
  }

  mediaCategoriesList() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/media-categories`)
  }

  mediaPlayersList() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/media-players`)
  }

  shopperCategoriesList() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/shopper-categories`)
  }

  shopperRetailersList() {
    return this.http.get<DropDown[]>(`${environment.apiUrl}/api/dropdown/shopper-retailers`)
  }

}
