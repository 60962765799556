<div class="row mb-3">
  <div class="col">
    <div class="card">
      <div class="card-header lead">
        Notes
      </div>
      <div class="card-body">
        <div class="row">
          <div class="form-group-wrapper">
            <form [formGroup]="noteForm" (ngSubmit)="addNote()" (keydown.enter)="$event.preventDefault()">
              <div class="form-group">
                <textarea id="noteText" autosize formControlName="noteText" class="form-control ps-2 pe-2" type="text" placeholder="New note..." [ngClass]="{ 'is-invalid': submitted && f.noteText.errors }"></textarea>
                <button pButton pRipple type="submit" title="Add New Note" icon="pi pi-plus" class="p-button-text ms-2" [loading]="loading"></button>
              </div>
            </form>
          </div>
        </div>
        <div *ngIf="notes.length > 0" class="row mt-2">
          <p-table [value]="notes" [tableStyle]="{ 'min-width': '20rem' }" styleClass="p-datatable-striped" dataKey="sortKey"
             [autoLayout]="true"
             [paginator]="true" [rows]="10"
             (onPage)="onPageChange($event)"
             [showCurrentPageReport]="false"
          >
            <ng-template pTemplate="header">
              <tr>
                <th></th>
                <th pSortableColumn="createDate">Date</th>
                <th colspan="4" pSortableColumn="noteText">Note</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-note let-expanded="expanded" let-editing="editing" let-ri="rowIndex">
              <tr>
                <td>
                  <button type="button" pButton pRipple [pRowToggler]="note" class="p-button-text p-button-rounded p-button-plain mr-2" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td>
                  <div *ngIf="this.currentWidth >= 768; else hideTime">
                    <span class="dateWithTime">{{ this.formatDate(note.lastModified, true) }}</span>
                  </div>
                  <ng-template #hideTime>
                    {{ this.formatDate(note.lastModified, false) }}
                  </ng-template>
                </td>
                <td>
                  <table class="limitedTable">
                    <tr>
                      <td class="limitedText">
                        {{ note.noteText }}
                      </td>
                    </tr>
                  </table>
                </td>
                <td class="m-0 p-0">
                  <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text" (click)="onRowEdit(note)"></button>
                </td>
                <td class="m-0 ps-0 pt-0 pb-0 pe-1">
                  <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger" (click)="onRowDelete(note)"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-note>
              <tr>
                <td colspan="5">
                  <div class="card">
                    <div class="card-body">
                      <div class="col">
                        <div class="row">
                          <span><b>{{ note.createdBy }}</b> - {{ this.formatDate(note.lastModified, true) }} <span *ngIf="note.createDate !== note.lastModified" class="text-muted">(edited)</span></span>
                        </div>
                        <div class="row ms-1">
                          {{ note.noteText }}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <span style="text-align: center;" class="text-muted mt-3">Showing {{ this.first + 1 }} to {{ this.first + rows > this.notes.length ? this.notes.length : this.first + rows }} of {{ this.notes.length }} notes</span>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog [(visible)]="editing" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header">
    <span><b>Edit Note</b></span>
  </ng-template>
  <div *ngIf="this.selectedNote" class="container mt-1">
    <div class="col-12">
      <div class="row">
        <textarea #updatedNote class="form-control" style="width: 100%" rows="5" [value]="this.selectedNote.noteText"></textarea>
      </div>
      <div>
        <div class="form-group mt-3 float-end">
          <button (click)="updateNote(updatedNote.value)" class="btn btn-primary">
            Save
          </button>
          <a (click)="this.editing = false" class="btn btn-link">Cancel</a>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
