import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/internal/Subscription';
import { KPIS } from 'src/app/_models/kpis';
import { NubbleService } from 'src/app/_services/nubble.service';

@Component({
  selector: 'app-reviewuser',
  templateUrl: './review-user.component.html',
  styleUrls: ['./review-user.component.css']
})
export class ReviewuserComponent implements OnInit {

  user: any;
  userId: string = '';
  kpiData: KPIS = {daysInstalled: 0, noDaysActive: 0, noFoursquareVisits: 0,
                    noFoursquareHomeVisits: 0, totalEarned: 0, totalRedeemed: 0};

  userIsLoaded: boolean = false;
  daysInstalledIsLoaded: boolean = false;
  noFoursquareVisitsIsLoaded: boolean = false;
  noFoursquareHomeVisitsIsLoaded: boolean = false;
  totalEarnedIsLoaded: boolean = false;
  totalRedeemedIsLoaded: boolean = false;

  installid: string;
  date = new Date();

  private routeSub: Subscription;

  constructor(private route: ActivatedRoute, private nubbleService: NubbleService, private toastrService: ToastrService) {
    this.routeSub = this.route.params.subscribe(params => {
      this.userId = (params['id'])
    });

    this.userFind();
    this.receiveKPIDataFoursquareVisits();
    this.receiveKPIDataComplianceReport();
  }

  ngOnInit() {
    this.userFind();
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  userFind() {
    this.nubbleService.userfind(this.userId).subscribe((data) =>{
      this.user = data;
    }, error =>{
        this.toastrService.error('Failed to fetch user');
    }, () => {
        //complete
        this.userIsLoaded = true;
    })
  }

  receiveKPIDataFoursquareVisits() {
    this.nubbleService.userGetFoursquareVisitsKPI(this.userId).subscribe(data => {
      this.kpiData.noFoursquareHomeVisits = data.homeVisits;
      this.kpiData.noFoursquareVisits = data.totalVisits;

      if (data.installid != null) {
        this.installid = data.installid;
      }
    }, error =>{
    }, () => {
        //complete
        this.noFoursquareHomeVisitsIsLoaded = true;
        this.noFoursquareVisitsIsLoaded = true;
        //this.embedDashboard();
    })
  }

  receiveKPIDataRewardHistory($event) {
    let sum: number = 0;
    $event.forEach(a => sum += a.points);
    this.kpiData.totalRedeemed = sum;
    this.totalRedeemedIsLoaded = true;
  }

  receiveKPIDataComplianceReport() {
    this.nubbleService.userGetComplianceReportKPI(this.userId).subscribe(data => {
      this.kpiData.daysInstalled = data.days_Installed;
      //this.kpiData.noDaysActive = data.Days_Installed;
    }, error =>{
    }, () => {
        //complete
      this.daysInstalledIsLoaded = true;
    })
  }

  receiveKPIDataPointsHistory($event) {
    let sum: number = 0;
    $event.forEach(a => sum += a.points);
    this.kpiData.totalEarned = sum;
    this.totalEarnedIsLoaded = true;
  }

  // dashboard;

  // embedDashboard() {
  //   var containerDiv = document.getElementById("embeddingContainer");
  //   var options = {
  //       url: "https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/560267457632/dashboards/7e4ca3bd-9788-4920-9d52-426ece6279a8#p.installid=96ae2479-03f9-4736-a40d-6b267aefd878",
  //       container: containerDiv,
  //       scrolling: "no",
  //       height: 'Autofit',
  //       width: "1000px",
  //       locale: "en-US",
  //       footerPaddingEnabled: true
  //   };
  //   this.dashboard = this.QuickSightEmbedding.embedDashboard(options);
  //   //this.dashboard.on("error", onError);
  //   //this.dashboard.on("load", onDashboardLoad);
  // }
}
