import { AuthService } from '../app/_services/auth.service';
import { Component, NgZone, ChangeDetectorRef, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { InstanceService } from './_services/instance.service';
import { Router, RoutesRecognized } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { CognitoAttributes } from '@aws-amplify/ui';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Lighthouse';
  instanceName: string;
  showNewFeatureBadge: boolean;
  themeValue: boolean;

  public containerClass: string = 'container';

  constructor(
    private titleService: Title,
    private ref: ChangeDetectorRef,
    public authService: AuthService,
    private instanceService: InstanceService,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private router: Router,
    public authenticatorService: AuthenticatorService,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.showNewFeatureBadge = new Date() < new Date('11/6/2024 11:59:59 PM');
  }

  ngOnInit() {
    // onAuthUIStageChange was deprecated in aws-amplify v2
    // in replacement, we listen/subscribe to AuthenticatorService class
    // only to populate user details and get instance data

    this.themeValue = JSON.parse(
      this.cookieService.get('themeToggle') || 'false',
    );
    console.info('themeValue', this.themeValue);

    this.changeTheme();

    this.authenticatorService.subscribe((data: any) => {
      if (data.authStatus === 'authenticated') {
        this.authService.loadUserDetails();

        this.authService.user?.userDetails?.instanceId.toString();
        this.ngZone.run(() => this.ref.detectChanges());

        if (
          this.authService.user?.userDetails?.instanceId.toString().length < 5
        ) {
          return;
        }

        this.instanceService
          .getInstance(
            this.authService.user?.userDetails?.instanceId.toString(),
          )
          .subscribe(
            (data) => {
              this.instanceName = data?.name;
            },
            (error) => {
              this.toastr.error('Failed to fetch instance name');
            },
            () => {},
          );
      }
    });

    if (
      this.authService.user?.userDetails?.instanceId ==
      '926bcd4a-ce39-4409-804b-f54e08bf8b40'
    ) {
      this.titleService.setTitle('DASHboard');
    }

    this.router.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        // if the route data defines a class to use for the container, use that instead. This allows the project list
        // page to go to container-fluid so that the table can expand to full width.
        // NOTE: This likely only works for top-level routes, not child routes.
        let route = event.state.root.firstChild;
        this.containerClass = route?.data?.containerClass || 'container';
      }
    });
  }

  changeTheme() {
    let themeLink = this.document.getElementById(
      'app-theme',
    ) as HTMLLinkElement;
    const body = document.body as HTMLElement;
    this.cookieService.set(
      'themeToggle',
      `${this.themeValue || 'false'}`,
      undefined,
      '/',
    );
    body.setAttribute('data-bs-theme', this.themeValue ? 'dark' : 'light');

    if (themeLink) {
      themeLink.href = `${this.themeValue ? 'dark' : 'light'}.css`;
    }
  }
}
