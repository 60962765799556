<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/inboxmessages">Messages</a></li>
    <li class="breadcrumb-item active" aria-current="page">Edit Message</li>
  </ol>
</nav>

<form
  [formGroup]="form"
  (keydown.enter)="$event.preventDefault()"
  (ngSubmit)="onSubmit()"
>
<p class="lead text-danger">Only editing text works (for now)</p>
<div class="card">

  <div class="card-body">
    <div class="container">
  <div class="row">

    <div class="col-6">
      <div class="row">
        <label class="lead" for="messageHeader">Title</label>
        <input
          type="text"
          formControlName="messageHeader"
          class="form-control"
        />
      </div>
      <div class="row">
        <label class="lead" for="messageText">Inbox Message</label>
        <textarea

          formControlName="messageText"
          rows="4"
          class="form-control"></textarea>
      </div>
      <div class="row">
        <label class="lead" for="messageText">Notification Message</label>
        <textarea style="white-space: pre-wrap;" formControlName="notificationText" rows="4" class="form-control"></textarea>
      </div>
    </div>
    <div class="col-6 mt-4">
      <div class="card h-100 d-flex  w-100">
        <div class="card-header">
          Target VIDs <small>(Optional)</small>
          <div class="form-check form-check-inline form-switch float-end">
            <input
              class="form-check-input"
              formControlName="enableTargetVIDs"
              type="checkbox"
            />
            <label class="form-check-label">Enable Target VIDs</label>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <app-target-vids
                [disabled]="true"
                [numberOfIds]="0"
                formControlName="targetVIDs"
              ></app-target-vids>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <div class="card">
        <div class="card-header">Target Other Projects</div>
        <div class="card-body">
          <div class="container">
            <div class="row" *ngIf="isLoaded">
              <div class="col">
                <input type="hidden" />
                <ng-multiselect-dropdown
                  [settings]="dropdownSettings"
                  [placeholder]="'Choose Projects'"
                  [data]="otherProjects"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="selectedItems"
                  (onSelect)="onItemSelect($event)"
                  (onDeSelect)="onDeSelect($event)"
                >
                </ng-multiselect-dropdown>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="targetOtherProjectsOnComplete"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Complete
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="targetOtherProjectsOnStart"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Start
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="targetOtherProjectsOnQC"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    QC
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="targetOtherProjectsOnTerm"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Term
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="targetOtherProjectsOnOQ"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    OQ
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="targetOtherProjectsOnNoStatus"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    No Status
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

  <div class="card-footer">
    <div class="form-group float-end">
      <button type="submit" [disabled]="!isLoaded" class="btn btn-primary">
        <span
          *ngIf="!isLoaded"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        Save
      </button>
      <a routerLink="/projects" class="btn btn-link">Cancel</a>
    </div>
  </div>


</div>
</form>
