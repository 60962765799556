import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Vendor } from '../_models/vendor';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(
    private http: HttpClient
  ) { }

  list() {
    return this.http.get<Vendor[]>(`${environment.apiUrl}/api/vendor`)
  }

  find(id: string) {
    return this.http.get<Vendor>(`${environment.apiUrl}/api/vendor/`+id)
  }

  add(model: Vendor) {
    return this.http.post(`${environment.apiUrl}/api/vendor`,model)
  }

  update(model: Vendor) {
    return this.http.put(`${environment.apiUrl}/api/vendor`,model)
  }


  delete(model: Vendor) {
    return this.http.delete(`${environment.apiUrl}/api/vendor/`+ model.id)
  }


}
