import { Component, Input, OnInit } from '@angular/core';
import { FroalaEditorDirective, FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { ToastrService } from 'ngx-toastr';
import { NubbleService } from 'src/app/_services/nubble.service';

@Component({
  selector: 'app-notes-wyswyg',
  templateUrl: './notes-wyswyg.component.html',
  styleUrls: ['./notes-wyswyg.component.scss']
})
export class NotesWyswygComponent implements OnInit {

  cardTitle: string = "Notes Editor";
  isLoaded: boolean = true;
  @Input() user: any;
  @Input() notes: string = '';

  userSaveNotes: any = {userId: '', notes: ''}

  public options: Object = {
    placeholderText: 'Edit Your Content Here!',
    heightMin: 475,
    heightMax: 475
  }

  constructor(private nubbleService: NubbleService, private toastrService: ToastrService) {}

  ngOnInit() {
    this.userSaveNotes = {userId: this.user.userId, notes: this.user.notes};
  }

  saveUserNotes() {
    this.nubbleService.saveUserNotes(this.userSaveNotes)
      .subscribe(data => {
    }, error =>{
      this.toastrService.error("Unable to save notes");
    }, () => {
      this.toastrService.success("Notes saved");
    })
  }
}
