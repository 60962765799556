<div class="card h-100 shadow-sm">
  <div class="card-header lead">
    <div class="row">
      <div class="col-6">
        <span class="float-start">{{cardTitle}}</span>
      </div>
      <div class="col-6" *ngIf="!isLoaded">
        <button type="button" class="btn btn-primary float-end" (click)="listWebsiteVisits()">Load</button>
      </div>
    </div>
  </div>

  <div class="h-100 d-flex justify-content-center align-items-center" *ngIf="isLoading">
    <app-loading-icon></app-loading-icon>
  </div>

  <div class="container vertical-scrollable">
    <div class="row" *ngIf="isLoaded">
      <app-list
        [showCreateNew]="false"
        [items]="websiteVisitsList"
        [layout]="layout"
      ></app-list>
    </div>
  </div>
</div>
