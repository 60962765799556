
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item active" aria-current="page">Campaigns</li>
  </ol>
</nav>

<div class="input-group mb-3 flex-nowrap d-flex justify-content-end">
  <div class="me-2">
    <select
      class="form-control"
      title="Search"
      [(ngModel)]="selectedInstanceId"
      (change)="filterOnStatus($event.target)"
    >
      <option *ngFor="let instance of instances" [value]="instance.value">
        {{ instance.option }}
      </option>
    </select>
  </div>

  <div>
    <button
      (click)="handleAction({ type: 'add', row: {} })"
      class="btn btn-outline-secondary"
    >
      Create New
    </button>
  </div>
</div>

<app-list
  [items]="items"
  [actions]="actions"
  [layout]="layout"
  [showCreateNew]="false"
  (action)="handleAction($event)"
></app-list>
