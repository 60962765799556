import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NubbleService } from 'src/app/_services/nubble.service';

@Component({
  selector: 'app-points-history-view',
  templateUrl: './points-history-view.component.html',
  styleUrls: ['./points-history-view.component.css']
})
export class PointsHistoryViewComponent implements OnInit {

  @Input() userId: string;
  cardTitle: string = 'Points History';

  layout:any[] =[
    {label:"Type",id:"type",type:""},
    {label:"Date",id:"datetime",type:"onlydate"},
    {label:"Points",id:"points",type:""}
  ];

  items:any[]=[];
  isLoaded: boolean = false;

  @Output() action = new EventEmitter();

  constructor(
    private nubbleService: NubbleService,
    private toastr: ToastrService,
    ) {
  }

  ngOnInit() {
    this.getPointsHistory();
  }

  getPointsHistory(){
    this.nubbleService.list(this.userId).subscribe(data=>{
      this.items= data;
    }, error =>{
        this.toastr.error('Failed to fetch points history');
    }, () => {
        this.isLoaded = true;
        this.sendKPIDataPointsHistory();
    })
  }

  sendKPIDataPointsHistory() {
    this.action.emit(this.items);
  }
}
