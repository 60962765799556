<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item active" aria-current="page">Search Users</li>
  </ol>
</nav>


<div class="input-group mb-3 flex-nowrap d-flex justify-content-end">
  <div class="row d-flex justify-content-between">
    <div class="col-12 flex-end">
      <div class="d-flex justify-content-end">
        <button
        (click)="allowScan()"
        title="Allow Scan"
        [ngClass]="getScanButtonStyle()"
        ></button>
        <input
          class="form-control-sm me-1 ms-1"
          placeholder="Search...."
          type="text"
          [(ngModel)]="searchUserDTO.value"
          title="Enter Email or Phonenumber"
        />
        <button
        (click)="clear()"
        title="Clear"
        class="btn btn-outline-danger bi-x me-1 ms-1"
      ></button>
        <button
          (click)="searchPhonenumber()"
          title="Search Phonenumber"
          class="btn btn-outline-success fa fa-phone me-1 ms-1"
        ></button>
        <button
          (click)="searchEmail()"
          title="Search Email"
          class="btn btn-outline-warning fa fa-envelope me-1 ms-1"
        ></button>
      </div>
    </div>
  </div>
</div>

<div class="h-100 d-flex justify-content-center align-items-center" *ngIf="!usersIsLoaded">
  <app-loading-icon></app-loading-icon>
</div>

<app-list *ngIf="usersIsLoaded" [items]="userItems" [layout]="layoutUsers" [actions]="actions" [showCreateNew]="false" (action)="openUserDetailsInNewWindow($event)"></app-list>
