import {Component, Input, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {first} from "rxjs/operators";
import {ClientUserService} from "../../_services/client-user.service";
import {ClientUser} from "../../_models/client-user";
import {ConfirmModalService} from "../../_services/confirm-modal.service";

@Component({
  selector: 'app-client-users',
  templateUrl: './client-users.component.html',
  styleUrls: ['./client-users.component.css']
})

export class ClientUsersComponent implements OnInit {
  @Input() clientId: string;
  @ViewChild('content', {static: false}) modalContent: TemplateRef<void>;

  layout:any[] =[

    {label:"First name",id:"firstName",type:""},
    {label:"Last name",id:"lastName",type:""},
    {label:"Email",id:"email",type:""},
    {label:"Role",id:"accountType",type:""},
  ];

  actions:any[] =[
    {label:"Edit",icon:"",type:"edit"},
    {label:"Delete",icon:"",type:"delete"}
  ];

  users:ClientUser[]=[];
  form: FormGroup;
  submitted = false;
  loading = false;
  isNew = false;
  isExistingUser = false;
  closeResult = '';

  constructor(
    private clientUserService: ClientUserService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private confirmModalService: ConfirmModalService)
  {
    this.initForm();
  }

  ngOnInit() {
    this.getClientUsers();
  }

  get f() { return this.form.controls; }

  handleAction(action:any){

    if(action.type==='edit'){
      this.isNew = false;
      this.toggleModal(this.modalContent, action.row);
    }
    else if(action.type==='add'){
      this.isNew = true;
      this.toggleModal(this.modalContent, null);
    }
    else if(action.type==='delete'){
      this.confirmDelete(action);
    }
  }

  confirmDelete(action:any) {
    this.confirmModalService.confirm('Are you sure?', 'Are you sure you want to delete this user?')
      .then((confirmed) => confirmed ? this.deleteUser(action) : null);
  }

  deleteUser(action:any) {
    this.clientUserService.delete(action.row).subscribe(data=>{

    }, error =>{
      this.toastr.error('Failed to delete ' + action.row.firstName);
    }, () => {
      this.getClientUsers();
      this.toastr.success('Deleted '+ action.row.firstName);
    })
  }

  toggleModal(content:any, clientUser:any){
    this.bindForm(clientUser);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  initForm() {
    this.submitted = false;
    this.form = this.formBuilder.group({
      clientId: [this.clientId,Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      accountType: ['', Validators.required],
    });
    this.form.controls['email'].enable();
  }

  bindForm(clientUser: ClientUser){
    if(clientUser){
      this.form = this.formBuilder.group({
        clientId: [clientUser.clientId,Validators.required],
        firstName: [clientUser.firstName, Validators.required],
        lastName: [clientUser.lastName,  Validators.required],
        email: [clientUser.email, [Validators.required, Validators.email]],
        accountType: [clientUser.accountType, Validators.required],
      });
    } else {
      this.initForm();
    }
  }

  getClientUsers() {
    this.clientUserService.list(this.clientId).subscribe(data=>{
      this.users = data;
    }, error =>{
      this.toastr.error('Failed to fetch client users');
    }, () => {

    })
  }

  updateUser(restoreUser: boolean) {
    if (restoreUser) {
      this.form.value.isDeleted = false;
    }
    this.clientUserService.update(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastr.success('User updated');
          this.getClientUsers();
          this.loading = false;
        },
        error: error => {
          this.toastr.warning(error);
          this.loading = false;
        }
      });
  }

  addUser() {
    this.clientUserService.add(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastr.success('New user added');
          this.getClientUsers();
          this.loading = false;
        },
        error: error => {
          this.toastr.warning(error);
          this.loading = false;
        }
      });
  }

  onSubmit(content:any){
    this.submitted = true;
    this.isExistingUser = false;

    if (this.form.invalid) {
      return;
    }
    this.loading = true;

    if (this.isNew) {
      this.clientUserService.find(this.clientId, this.form.value.email)
        .subscribe(data => {
          if (data) {
            this.isExistingUser = true;
          }
        }, error => {
          this.toastr.error("Error fetching existing users")
        }, () => {
          if (this.isExistingUser) {
            this.updateUser(true);
            this.modalService.dismissAll();
          }
          else {
            this.addUser();
            this.modalService.dismissAll();
          }
        })
    } else {
      this.updateUser(false);
      this.modalService.dismissAll();
    }
  }
}
