import { AuthService } from 'src/app/_services/auth.service';
import { UserService } from '../../../_services/user.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstanceService } from 'src/app/_services/instance.service';
import { first } from 'rxjs/operators';
import { DropDownService } from '../../../_services/drop-down.service';

@Component({
  selector: 'app-register-update-user',
  templateUrl: './register-update-user.component.html',
  styleUrls: ['./register-update-user.component.scss']
})
export class RegisterUpdateUserComponent implements OnInit {
  form: FormGroup;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  instances:any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    public auth: AuthService,
    private dd: DropDownService
  ) {

      this.id = this.route.snapshot.params['email'];
      this.isAddMode = !this.id;
      this.form = this.formBuilder.group({
        email: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        //password: [''],
        role: ['Member', Validators.required],
        instanceId: [this.auth.user.userDetails.instanceId]//

    });

    if (!this.isAddMode) {
      this.userService.find(this.id)
          .pipe(first())
          .subscribe(x => this.form.patchValue(x));
      }

   }

  ngOnInit() {
    this.getInstances();

  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;
    if (this.isAddMode) {
        this.create();
    } else {
        this.update();
    }
}

private create(){
  console.log(this.form.value);

  this.userService.register(this.form.value)
        .pipe(first())
        .subscribe({
            next: () => {
                this.toastr.success('User added successfully');
                this.router.navigate(['/users']);
            },
            error: error => {
                this.toastr.error(error);
                this.loading = false;
            }
        });

}


private update(){

  this.userService.update(this.form.value)
          .pipe(first())
          .subscribe({
              next: () => {
                  this.toastr.success('User updated successfully');
                  this.router.navigate(['/users'], { relativeTo: this.route });
              },
              error: error => {
                  this.toastr.error(error);
                  this.loading = false;
              }
          });

}

  getInstances(){
    this.dd.instances().subscribe(data=>{
      this.instances= data;
    }, error =>{
      console.log(error);
      this.toastr.error('Unable to fetch instances');
    }, () => {

    })
  }

}
