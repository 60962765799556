<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item active" aria-current="page">Projects</li>
  </ol>
</nav>

<div class="input-group mb-3 flex-nowrap d-flex justify-content-end">
  <button (click)="getActiveProjects()" title="Refresh"
    class="btn btn-outline-secondary  bi bi-arrow-clockwise"></button>
  <!--User NGModel SelectedUser-->
  <select class="form-control-sm " title="Filter By Status" [(ngModel)]="selectedUser"
    (change)="filterOnUser($event.target)">
    <option value="">All</option>
    <option *ngFor="let item of users" [value]="item.value">{{item.option}}</option>
  </select>
  <!--
  <ng-multiselect-dropdown
  style="border: 1px; border-color: black;"
  [settings]="dropdownSettings"
  [data]="dropdownList"
  [(ngModel)]="selectedItems"
  (onSelect)="onItemSelect($event)"
  (onSelectAll)="onSelectAll($event)"
>
</ng-multiselect-dropdown>
-->



  <select class="form-control-sm " title="Filter By Status" (change)="filterOnStatus($event.target)">
    <option [selected]="true" value="All">All</option>
    <option value="Test">Test</option>
    <!-- <option value="Soft Launch">Soft Launch</option>-->
    <option value="Full Launch">Live</option>
    <option value="Paused">Paused</option>
    <option value="Closed">Closed</option>
    <option value="Invoiced">Invoiced</option>
  </select>



  <input class="form-control-sm" placeholder="Search" type="text" [(ngModel)]="searchText" title="Search Projects"
    (keyup)="inputChange($event.target)">
  <button (click)="handleAction({type: 'add'})" title="Create New Project"
    class="btn btn-outline-secondary  bi bi-plus-circle"></button>
  <button (click)="exportProjectList()" title="Download Project List"
    class="btn btn-outline-secondary  bi bi-arrow-down-square"></button>
</div>

<div *ngIf="loading; else projects" class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<ng-template #projects>
  <p-table [value]="items" [autoLayout]="true" [paginator]="true" [rows]="100" [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} projects"
    [rowsPerPageOptions]="[20, 50, 100]" [tableStyle]="{'min-width': '50rem','white-space': 'nowrap'}"
    styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="dashCode">Code</th>
        <th pSortableColumn="projectName">Name</th>
        <th pSortableColumn="projectStatus">Status</th>
        <th pSortableColumn="client">Client</th>
        <th pSortableColumn="projectManager">PM</th>
        <th pSortableColumn="salesRep">Sales</th>
        <th pSortableColumn="deadline">Deadline</th>
        <th pSortableColumn="starts">S</th>
        <th pSortableColumn="completes">C</th>
        <th pSortableColumn="resolved">R</th>
        <th pSortableColumn="dor">DOR</th>
        <th pSortableColumn="ir">IR</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-project>
      <tr>
        <td><a routerLink='/projects/view/{{project.id}}'>{{ (project.dashCode !== "" && project.dashCode !== null ) ?
            project.dashCode : 'Unassigned'}}</a></td>
        <td>
          <table class="limitedTable">
            <tr>
              <td class="limitedText">
                {{project.projectName}}
              </td>
            </tr>
          </table>
        </td>
        <td>{{project.projectStatus}}</td>
        <td>{{project.client}}</td>
        <td>{{project.projectManager}}</td>
        <td>{{project.salesRep}}</td>
        <td>{{project.deadline}}</td>
        <td>{{project.starts}}</td>
        <td>{{project.completes}}</td>
        <td>{{project.resolved}}</td>
        <td>
          <span [ngClass]="getDorClass(project.dor)">
            {{project.dor * 100 | number: '1.0-0'}}
          </span>  
        </td>
        <td>
          <span [ngClass]="getIrClass(project.ir, project['estimateIR'])"
            [style.display]="getIrClass(project.ir, project['estimateIR']) === '' ? 'none' : 'initial'">
            {{project.ir * 100 | number: '1.0-0'}}
          </span>
          <span [style.display]="getIrClass(project.ir, project['estimateIR']) !== '' ? 'none' : 'initial'">
            {{project.ir * 100 | number: '1.0-0'}}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>