
<ng-container *ngIf="authenticatorService.authStatus !== 'authenticated'">
  <div class="container" style="height: 100vh;">
    <div class="row align-items-center h-100">
      
        <div class="col-6 mx-auto">
            <img          
            src="../assets/DASH LOGO CROPPED.png"
            class="mx-auto d-block"
            style="width: 100px;"
            />
            <h2 class="mx-auto d-block sign-in-label">Sign in to your account</h2>
            <div class="jumbotron">

              <amplify-authenticator [hideSignUp]="true" [loginMechanisms]="['email']" >
                <ng-template
                  amplifySlot="authenticated"
                  let-user="user"
                  let-signOut="signOut">
                </ng-template>

                <ng-template amplifySlot="sign-in-footer">
                  <div style="text-align: center; margin-bottom: 2rem;">
                    <button
                      (click)="authenticatorService.toResetPassword()"
                      class="amplify-button amplify-field-group__control"
                      data-fullwidth="false"
                      data-size="small"
                      data-variation="link"
                      type="button"
                      style="font-weight: normal; border-style: none;"
                    >
                      Reset Password
                    </button>
                  </div>
                </ng-template>
              </amplify-authenticator>
            </div>
        </div>
    </div>
</div>
</ng-container>

<ng-container *ngIf="authenticatorService.authStatus === 'authenticated'">
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a class="navbar-brand" routerLink="/">
        <img
          *ngIf="authService.user?.userDetails?.dash == true && authService.user?.userDetails?.bb != true"
          src="../assets/DASH LOGO CROPPED.png"
          style="max-height: 40px"
          class="img-fluid ml-3"
        />
        <span
         *ngIf="authService.user?.userDetails?.dash != true || authService.user?.userDetails?.bb == true"
          ><b>PMO</b>
        </span>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" routerLink="/"
              >Projects</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/vendors">Vendors</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/clients">Clients</a>
          </li>
          <li *ngIf="authService.user?.userDetails?.admin" class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Admin
            </a>

            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" routerLink="/users">Users</a></li>
            </ul>
          </li>
          <li
            *ngIf="authService.user?.userDetails?.superAdmin"
            class="nav-item dropdown"
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Super Admin
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <a class="dropdown-item" routerLink="/searchusers">Search Users</a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/instances">Instances</a>
              </li>
              <li   *ngIf="authService.user?.userDetails?.dash != true">
                <a class="dropdown-item" routerLink="/campaigns">Campaigns</a>
              </li>
              <li   *ngIf="authService.user?.userDetails?.dash != true">
                <a class="dropdown-item" routerLink="/pendingrewards">Pending Rewards</a>
              </li>
              <li   *ngIf="authService.user?.userDetails?.dash != true">
                <a class="dropdown-item" routerLink="/engagement">Engagement</a>
              </li>
              <li   *ngIf="authService.user?.userDetails?.dash != true">
                <a class="dropdown-item" routerLink="/markerslist">Markers</a>
              </li >
              <li   *ngIf="authService.user?.userDetails?.dash != true">
                <a class="dropdown-item" routerLink="/fraudmarkers">Fraud</a>
              </li>
              <li   *ngIf="authService.user?.userDetails?.dash != true">
                <a class="dropdown-item" routerLink="/inboxmessages">Messages</a>
              </li>
            </ul>
          </li>
        </ul>

        <ul class="navbar-nav">
          <li class="nav-item" *ngIf="showNewFeatureBadge">
            <a class="nav-link badge text-bg-primary mt-2 p-2 me-4" routerLink="/release-notes" id="navbarDropdown" role="button">
              <span class="p-2 mt-2">New Features! 🎉</span>
            </a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ authenticatorService.user.attributes?.given_name }}
              {{ authenticatorService.user.attributes?.family_name }}
              [{{instanceName}}]
            </a>
            <ul
              class="dropdown-menu mr-3 mt-2"
              aria-labelledby="navbarDropdown"
            >
            <li>
              <div class="form-check form-switch ms-3">
                <input class="form-check-input" type="checkbox" [(ngModel)]="themeValue" (change)="changeTheme()">
                <label class="form-check-label">Dark Mode</label>
              </div>
            </li>
              <li>
                <a class="dropdown-item" routerLink="/release-notes">Release Notes</a>
              </li>
              <li>
                  <a class="dropdown-item" (click)="authenticatorService.signOut()">Log Out</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="{{containerClass}} mt-3">
    <router-outlet></router-outlet>
  </div>
</ng-container>
