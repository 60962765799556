import { map } from 'rxjs/operators';
import { DropDownService } from 'src/app/_services/drop-down.service';
import { AuthService } from 'src/app/_services/auth.service';
import { InstanceService } from 'src/app/_services/instance.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CampaignService } from './../../../_services/campaign.service';
import { DropDown } from 'src/app/_models/drop-down';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
})
export class CampaignComponent implements OnInit {
  layout: any[] = [
    { label: 'Created DateTime', id: 'createdDateTime', type: 'date' },
    { label: 'CampaignName', id: 'campaignName', type: '' },
    { label: 'CPA', id: 'cpa', type: 'currency' },
    { label: 'Source', id: 'source', type: '' },
    { label: 'Number Of Referrals', id: 'numberOfReferrals', type: '' },
    {
      label: 'Number Of Referrals Used',
      id: 'numberOfReferralsUsed',
      type: '',
    },
  ];

  actions: any[] = [
    { label: 'Download', icon: '', type: 'download' },
    { label: 'Delete', icon: '', type: 'delete' },
  ];

  items: any[] = [];
  instances: DropDown[] = [];
  selectedInstanceId = '';
  referrals: any[] = [];
  constructor(
    private campaignService: CampaignService,
    private toastr: ToastrService,
    private router: Router,
    private dropDownService: DropDownService,
    private authService: AuthService
  ) {
    this.selectedInstanceId = this.authService.user?.userDetails?.instanceId;
  }

  ngOnInit() {
    this.getInstances();
    this.getCampaigns();
  }
  handleAction(action: any) {
    if (action.type === 'download') {

      this.dowloadReferrals(action.row.id,action.row.campaignName);

    } else if (action.type === 'add') {
        this.router.navigate(['/campaigns/add']);
    } else if (action.type === 'delete') {
      if (confirm('Are you sure to delete ' + action.row.campaignName)) {
        this.campaignService.delete(action.row.id).subscribe(
          (data) => {},
          (error) => {
            this.toastr.error(
              'Failed to delete ' + action.row.campaignName + ' Campaign'
            );
          },
          () => {
            this.getCampaigns();
            this.toastr.success(
              'Deleted ' + action.row.campaignName + ' Campaign'
            );
          }
        );
      }
    }
  }

  filterOnStatus(event: any) {

    this.selectedInstanceId = event.value;

    this.getCampaigns();
  }

  getInstances() {
    this.dropDownService.instances().subscribe(
      (data) => {
        this.instances = data;
      },
      (error) => {
        this.toastr.error('Failed to fetch instances');
      },
      () => {}
    );
  }

  getCampaigns() {
    this.campaignService.list(this.selectedInstanceId).subscribe(
      (data) => {
        this.items = data;
      },
      (error) => {
        this.toastr.error('Failed to fetch instances');
      },
      () => {}
    );
  }
  dowloadReferrals(campaignId:string, campaignName:string){
     this.campaignService.listReferrals(campaignId).subscribe(
      (data) => {

        const blob = new Blob([data], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = url;
        hiddenElement.target = '_blank';
        hiddenElement.download = campaignName+'.csv';
        hiddenElement.click();
      },
      (error) => {
        this.toastr.error('Failed to fetch instances');
      },
      () => {}
    );
    //get campaign name
    let cleaned= campaignName.replace(" ", "_");
    //download file
  }
}
