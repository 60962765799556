<app-card [cardTitle]="cardTitle">
  <div class="h-100 d-flex justify-content-center align-items-center" *ngIf="!isLoaded">
    <app-loading-icon></app-loading-icon>
  </div>
  <div class="container">
    <div class="row" *ngIf="isLoaded">
      <div [froalaEditor]='options' [(froalaModel)]="userSaveNotes.notes"></div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-10">
    </div>
    <div class="col-2">
      <button type="button" class="btn btn-outline-primary" (click)="saveUserNotes()">Save</button>
    </div>
  </div>
</app-card>
