

<form [formGroup]="form" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()">

  <app-demographics formControlName="model"></app-demographics>
  <button type="submit"  class="btn btn-primary">
    Save
  </button>
</form>



<label class="btn btn-primary form-control mt-5">
  <i class="fa fa-image"></i> Zip (.csv only)<input type="file" style="display: none;"  name="image">
</label>




<input class="" style="content: zips;" content="Upload ZIP CSV" type="file" id="formFile">
<input class="form-control" type="date" />

<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item"><a href="#">Library</a></li>
    <li class="breadcrumb-item active" aria-current="page">Data</li>
  </ol>
</nav>

<div class="form-group">
  <label for="file">Choose File</label>
  <input type="file"
         id="file"
         (change)="handleFileInput($event)">
</div>

<app-link [url]="link.url"   [displayName]="link.display"></app-link>
<div class="clearfix"></div>
<app-check [value]="true" [label]="'Hi'"></app-check>
<app-initials [name]="'Tony Moffatt'"></app-initials>
<div class="clearfix"></div>
<app-mail  [email]="email.email"></app-mail>


<button (click)="downloadFile()">Download File</button>

<app-list [items]="items" [actions]="actions" [layout]="layout" ></app-list>

<select>
  <option *ngFor="let v of vendors" [value]="v.value">
    {{v.option}}
    </option>
</select>

<i class="bi bi-calendar"></i>


<!-- datepicker  -->
<ngb-datepicker></ngb-datepicker>
<ng-multiselect-dropdown
[settings]="dropdownSettings"
  [data]="dropdownList"
  [(ngModel)]="selectedItems"
>
</ng-multiselect-dropdown>


<app-unique-ids formControlName="uniqueIds"></app-unique-ids>
<!--

  <app-project-performance ></app-project-performance>
-->



