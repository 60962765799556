import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NubbleUser } from 'src/app/_models/nubble-user';
import { NubbleService } from 'src/app/_services/nubble.service';

@Component({
  selector: 'app-pending-rewards',
  templateUrl: './pending-rewards.component.html',
  styleUrls: ['./pending-rewards.component.css'],
})
export class PendingRewardsComponent implements OnInit {
  layoutRewards: any[] = [
    { label: 'Email', id: 'email', type: 'text' },
    { label: 'Order Created', id: 'orderCreatedDateTime', type: 'date' },
    { label: 'Amount', id: 'amount', type: 'currency' },
    { label: 'Name', id: 'name', type: 'text' },
    { label: 'Is Reviewed', id: 'isReviewed', type: 'text' },
  ];

  actions: any[] = [{ label: 'Review', icon: '', type: 'view' }];

  rewardItems: any[] = [];

  pendingRewardsIsLoaded: boolean = false;

  constructor(
    private nubbleService: NubbleService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getPendingRewards();
  }

  getPendingRewards() {
    this.nubbleService.pendingRewardsList().subscribe(
      (data) => {
        this.rewardItems = data;
      },
      (error) => {
        this.toastr.error('Failed to fetch pending rewards');
      },
      () => {
        this.pendingRewardsIsLoaded = true;
      }
    );
  }

  openUserDetailsInNewWindow(action: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/reviewuser/${action.row.userId}`])
    );

    window.open(url, '_blank');
  }

}
