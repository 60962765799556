<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
    <li class="breadcrumb-item"><a routerLink="/campaigns">Campaigns</a></li>
    <li class="breadcrumb-item active" aria-current="page">
      Create Campaign
    </li>
  </ol>
</nav>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="card">

    <div class="card-body">



          <div class="row">
            <div class="col">
              <label for="campaignName">Campaign Name</label>
              <input
                type="text"
                [ngClass]="{ 'is-invalid': submitted && f.campaignName.errors }"
                formControlName="campaignName"
                class="form-control"
              />
              <div *ngIf="submitted && f.campaignName.errors" class="invalid-feedback">
                <div *ngIf="f.campaignName.errors?.required">
                  Campaign name is required
                </div>
              </div>
            </div>

            <div class="col">
              <label for="InstanceName">Instance</label>
              <div class="me-2">
                <select
                  class="form-control"
                  title="Search"
                  formControlName="instanceId"
                  [ngClass]="{ 'is-invalid': submitted && f.cpa.errors }"
                >

                  <option
                    *ngFor="let instance of instances"
                    [value]="instance.value"
                  >
                    {{ instance.option }}
                  </option>
                </select>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="form-group col-6">
              <label for="source">Source</label>
              <select class="form-select" formControlName="source"    [ngClass]="{ 'is-invalid': submitted && f.source.errors }">>
                <option [selected]="true">Choose Source</option>

                <option *ngFor="let source of sources" [value]="source.value">
                  {{ source.option }}
                </option>
              </select>

              <div *ngIf="submitted && f.source.errors" class="invalid-feedback">
                 <div *ngIf="f.source.errors?.required">Source is required</div>
               </div>
            </div>
            <div class="col">
              <label for="numberOfReferrals">Number Of Referrals</label>
              <input type="number" formControlName="numberOfReferrals" class="form-control"   [ngClass]="{ 'is-invalid': submitted && f.numberOfReferrals.errors }" />
              <div *ngIf="submitted && f.numberOfReferrals.errors" class="invalid-feedback">
                <div *ngIf="f.numberOfReferrals.errors?.required">Number Of Referrals is required</div>
              </div>
            </div>

            <div class="col">
              <label for="CPA">CPA</label>
              <input type="number" formControlName="cpa" class="form-control"   [ngClass]="{ 'is-invalid': submitted && f.cpa.errors }" />
              <div *ngIf="submitted && f.cpa.errors" class="invalid-feedback">
                <div *ngIf="f.cpa.errors?.required">CPA is required</div>
              </div>
            </div>
          </div>

        </div>

        <div class="card-footer">
          <div class="form-group float-end">
            <button [disabled]="loading" class="btn btn-primary">
              <span
                *ngIf="loading"
                class="spinner-border spinner-border-sm mr-1"
              ></span>
              Save
            </button>
            <a routerLink="/campaigns" class="btn btn-link">Cancel</a>
          </div>
        </div>
      </div>









</form>
