import { VendorService } from '../../_services/vendor.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss'],
})
export class VendorsComponent implements OnInit {
  layout: any[] = [{ label: 'Name', id: 'name', type: '' }];

  actions: any[] = [{ label: 'Edit', icon: '', type: 'edit' }];

  items: any[] = [];

  constructor(
    private vendorService: VendorService,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getVendors();
  }

  createNew() {}

  handleAction(action: any) {
    if (action.type === 'edit') {
      this.router.navigate(['/vendors/edit/' + action.row.id]);
    } else if (action.type === 'add') {
      this.router.navigate(['/vendors/add']);
    }
  }

  getVendors() {
    this.vendorService.list().subscribe(
      (data) => {
        this.items = data;
      },
      (error) => {
        this.toastr.error('Failed to fetch vendors');
      },
      () => {
        // this.toastr.success('Fetched instances');
      },
    );
  }
}
