import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { DropDown } from 'src/app/_models/drop-down';
import { DropDownService } from 'src/app/_services/drop-down.service';
import { InboxService } from 'src/app/_services/inbox.service';
import { NubbleService } from 'src/app/_services/nubble.service';

@Component({
  selector: 'app-create-inbox-message',
  templateUrl: './create-inbox-message.component.html',
  styleUrls: ['./create-inbox-message.component.css']
})
export class CreateInboxMessageComponent implements OnInit {

  form: FormGroup;
  submitted: boolean = false;
  isLoaded: boolean = false;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    enableCheckAll: false,
    idField: 'value',
    textField: 'option',
    allowSearchFilter: true
  };

  selectedItems: DropDown[] = [];
  otherProjects:any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private dropdownService: DropDownService,
    private toastr: ToastrService,
    private inboxService: InboxService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      messageHeader: ['', Validators.required],
      messageText: ['', Validators.required],
      notificationText: ['', Validators.required],
      targetOtherProjectsOnParticipant: [false, Validators.required],
      targetOtherProjectsOnComplete: [false, Validators.required],
      targetOtherProjectsOnStart: [false, Validators.required],
      targetOtherProjectsOnQC: [false, Validators.required],
      targetOtherProjectsOnTerm: [false, Validators.required],
      targetOtherProjectsOnOQ: [false, Validators.required],
      targetOtherProjectsOnNoStatus: [false, Validators.required],
      targetVIDs:[[]],
      enableTargetVIDs:[false],
      targetOtherProjects: [[]]
    });
  }

  ngOnInit() {
    this.getOtherProjects();
  }

  get f() { return this.form.controls; }

  getOtherProjects() {
    this.dropdownService.projects().subscribe(data=>{
      this.otherProjects= data;
    }, error =>{
      this.toastr.error('Unable to fetch other projects');

    }, () => {
      // this.toastr.success('Fetched instances');
      this.isLoaded = true;
    })
  }

  onItemSelect(item: any) { //just take ids for form
    this.form.get('targetOtherProjects')?.setValue(this.selectedItems.map(function(item){return item?.value;}));
  }

  onDeSelect(items: any) { //just take ids for form
    this.form.get('targetOtherProjects')?.setValue(this.selectedItems.map(function(item){return item?.value;}));
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
     this.isLoaded = false;

     let model = this.form.value;

     this.inboxService.createInboxMessage(model).subscribe(data=>{
    }, error =>{
      this.toastr.error('Unable to Create Message');

    }, () => {
      this.isLoaded = true;
      this.toastr.success('Message Created');
      this.router.navigate(['/inboxmessages']);
    })
  }

  targetVIDSUploadToggle() {

    if (this.f.enableTargetVIDs.value == true) {
      return {'pointer-events': 'auto'}
    }
    else (this.f.enableTargetVIDs.value == false)
    {
      return {'pointer-events': 'none', 'opacity': 0.5}
    }
  }
}
