import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

constructor(
  private http: HttpClient
) { }

  add(model: any) {
    return this.http.post(`${environment.apiUrl}/api/campaign`,model)
  }

  list(instanceId:string) {
    return this.http.get<any[]>(environment.apiUrl+'/api/campaign?instanceId='+instanceId)
  }

  listReferrals(campaignId:string) {
    return this.http.get(environment.apiUrl+'/api/campaign/referrals/'+campaignId, { responseType: 'text'})
  }

  pause(campaignId:string) {
    return this.http.get<any>(environment.apiUrl+'/api/campaign/pause/'+campaignId)
  }
  play(campaignId:string) {
    return this.http.get<any>(environment.apiUrl+'/api/campaign/play/'+campaignId)
  }

  delete(campaignId:string) {
    return this.http.delete<any>(environment.apiUrl+'/api/campaign/'+campaignId)
  }
}
