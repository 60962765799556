import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InboxMessage } from '../_models/inbox-message';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InboxService {

constructor(private http: HttpClient) { }

  listInboxMessages() {
    return this.http.get<InboxMessage[]>(`${environment.apiUrl}/api/inbox/getmessages`);
  }

  getInboxMessage(id: string) {
    return this.http.get<InboxMessage>(`${environment.apiUrl}/api/Inbox/getMessage/${id}`);
  }

  // addOrUpdateInboxMessage(inboxMessage: InboxMessage) {
  //   console.log(inboxMessage)
  //   return this.http.post(`${environment.apiUrl}/api/Inbox/updateMessage`, inboxMessage);
  // }

  updateMessage(inboxMessage: InboxMessage) {
    return this.http.post(`${environment.apiUrl}/api/Inbox/updateMessage`, inboxMessage);
  }

  deleteInboxMessage(messageId: string) {
    return this.http.delete(`${environment.apiUrl}/api/inbox/deletemessage/` + messageId);
  }

  createInboxMessage(inboxMessage: InboxMessage) {
    return this.http.post<any>(`${environment.apiUrl}/api/inbox/createmessage`, inboxMessage);
  }

  sendNotificationMessage(messageid: string) {
    return this.http.get<any>(`${environment.apiUrl}/api/inbox/sendmessagenotification/` + messageid);
  }

  sendEmailMessage(messageid: string) {
    return this.http.get<any>(`${environment.apiUrl}/api/inbox/sendmessageemail/` + messageid);
  }
}
