import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { DropDown } from 'src/app/_models/drop-down';
import { DropDownService } from 'src/app/_services/drop-down.service';
import { InboxService } from 'src/app/_services/inbox.service';
import { NubbleService } from 'src/app/_services/nubble.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-edit-inbox-message',
  templateUrl: './edit-inbox-message.component.html',
  styleUrls: ['./edit-inbox-message.component.css']
})
export class EditInboxMessageComponent implements OnInit {

  form: FormGroup;
  submitted: boolean = false;
  isAddMode: boolean;
  isLoaded: boolean = false;
  id: string;
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    enableCheckAll: false,
    idField: 'value',
    textField: 'option',
    allowSearchFilter: true
  };

  selectedItems: DropDown[] = [];
  otherProjects:any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private dropdownService: DropDownService,
    private toastr: ToastrService,
    private inboxService: InboxService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.id = this.route.snapshot.params['id'];
    this.form = this.formBuilder.group({
      messageHeader: ['', Validators.required],
      messageId: [this.id, Validators.required],
      messageText: ['', Validators.required],
      notificationText: ['', Validators.required],
      targetOtherProjectsOnParticipant: [false, Validators.required],
      targetOtherProjectsOnComplete: [false, Validators.required],
      targetOtherProjectsOnStart: [false, Validators.required],
      targetOtherProjectsOnQC: [false, Validators.required],
      targetOtherProjectsOnTerm: [false, Validators.required],
      targetOtherProjectsOnOQ: [false, Validators.required],
      targetOtherProjectsOnNoStatus: [false, Validators.required],
      targetVIDs:[[]],
      enableTargetVIDs:[false],
      targetOtherProjects: [[]]
    });

    if (!this.isAddMode) {
      this.inboxService
        .getInboxMessage(this.id)
        .pipe(first())
        .subscribe((x) => this.form.patchValue(x));
    }
  }

  ngOnInit() {
    this.getOtherProjects();
  }

  get f() { return this.form.controls; }

  getOtherProjects() {
    this.dropdownService.projects().subscribe(data=>{
      this.otherProjects= data;
    }, error =>{
      this.toastr.error('Unable to fetch other projects');

    }, () => {
      // this.toastr.success('Fetched instances');
      this.isLoaded = true;
    })
  }

  onItemSelect(item: any) { //just take ids for form
    this.form.get('targetOtherProjects')?.setValue(this.selectedItems.map(function(item){return item?.value;}));
  }

  onDeSelect(items: any) { //just take ids for form
    this.form.get('targetOtherProjects')?.setValue(this.selectedItems.map(function(item){return item?.value;}));
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    
    this.isLoaded = false;

    this.updateMessage()
    
  }

updateMessage () {
  let model = this.form.value;
  
  this.inboxService
    .updateMessage(model)
    .pipe(first())
    .subscribe({
      next: () => {
        this.toastr.success('Message updated');
        this.router.navigate(['/inboxmessages']);
      },
      error: (error) => {
        this.toastr.error(error);
        this.isLoaded = true;
      },
      
      
    }), () => {
      this.isLoaded = true;
    };
  }
}
