<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item"><a href="/markers">Markers</a></li>
    <li *ngIf="!isAddMode" class="breadcrumb-item active" aria-current="page">
      Edit Marker
    </li>
    <li *ngIf="isAddMode" class="breadcrumb-item active" aria-current="page">
      Add Marker
    </li>
  </ol>
</nav>

<form [formGroup]="markersForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-12">
      <app-card [cardTitle]="queryCardTitle">
          <!-- If is add mode then allow to choose new Marker name -->
          <div class="row">
            <div class="col-4">
              <label class="lead" for="name">Marker Name</label>
              <input
                type="text"
                formControlName="name"
                class="form-control"
                (keypress)="keyPressAlphaNumeric($event)"
                [ngClass]="{
                  'is-invalid': submitted && markersForm.get('name')?.errors
                }"
              />
              <div
                *ngIf="submitted && markersForm.get('name')?.errors"
                class="invalid-feedback"
              >
                <div *ngIf="markersForm.get('name')?.errors?.required">
                  Marker Name is required
                </div>
              </div>
            </div>

            <!-- Marker Type -->
            <div class="col-4">
              <label class="lead" for="markerType">Marker Type</label>

              <select
                class="form-select"
                formControlName="markerType"
                [ngClass]="{
                  'is-invalid':
                    submitted && markersForm.get('markerType')?.errors
                }"
                (change)="setAttributes(markersForm.get('markerType'))"
              >
                <option [selected]="true" value="">Marker Type</option>
                <option
                  *ngFor="let markerType of markerTypeSelection"
                  [value]="markerType.type"
                >
                  {{ markerType.type }}
                </option>
              </select>
              <div
                *ngIf="submitted && markersForm.get('markerType')?.errors"
                class="invalid-feedback"
              >
                <div *ngIf="markersForm.get('markerType')?.errors?.required">
                  Marker Type is required
                </div>
              </div>
            </div>

            <!-- Timeframe -->
            <div class="col-4">
              <label class="lead" for="timeframe">Timeframe</label>
              <select
                class="form-select"
                formControlName="timeframe"
                [ngClass]="{
                  'is-invalid':
                    submitted && markersForm.get('timeframe')?.errors
                }"
              >
                <option [selected]="true" value="">Choose Timeframe</option>
                <option
                  *ngFor="let timeframe of timeframeSelection"
                  [value]="timeframe.value"
                >
                  {{ timeframe.key }}
                </option>
              </select>
              <div
                *ngIf="submitted && markersForm.get('timeframe')?.errors"
                class="invalid-feedback"
              >
                <div *ngIf="markersForm.get('timeframe')?.errors?.required">
                  Timeframe is required
                </div>
              </div>
            </div>
          </div>

          <ng-container formArrayName="markerSegments">
            <div *ngFor="let marker of markerSegments.controls; let i = index">
              <div [formGroupName]="i">
                <!-- Operator Selection if there is more than one Marker Segment -->
                <div
                  class="row mt-5"
                  *ngIf="markerSegments.length > 1 && i != 0"
                >
                  <div class="col-2">
                    <label class="lead" for="attributeName">Operator</label>
                    <select
                      class="form-select"
                      formControlName="operator"
                      [ngClass]="{
                        'is-invalid':
                          submitted && marker.get('operator')?.errors
                      }"
                    >
                      <option [selected]="true" value="">
                        Choose Operator
                      </option>
                      <option
                        *ngFor="let operator of operatorSelection"
                        [value]="operator"
                      >
                        {{ operator }}
                      </option>
                    </select>
                  </div>
                  <div class="col-10"></div>
                </div>

                <div class="row mt-5">
                  <label class="lead">Marker Condition {{ i + 1 }}</label>
                </div>

                <div class="row">
                  <!-- Attribute Name -->
                  <div class="col-4">
                    <label class="lead" for="attributeName"
                      >Attribute Name</label
                    >

                    <select
                      class="form-select"
                      formControlName="attributeName"
                      [ngClass]="{
                        'is-invalid':
                          submitted && marker.get('attributeName')?.errors
                      }"
                    >
                      <option [selected]="true" value="">
                        Choose Attribute
                      </option>
                      <option
                        *ngFor="let attributeName of attributeNameSelection"
                        [value]="attributeName"
                      >
                        {{ attributeName }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted && marker.get('attributeName')?.errors"
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="marker.get('attributeName')?.errors?.required"
                      >
                        Attribute Name is required
                      </div>
                    </div>
                  </div>

                  <!-- Type -->
                  <div class="col-4">
                    <label class="lead" for="attributeName">Type</label>

                    <select
                      class="form-select"
                      formControlName="type"
                      [ngClass]="{
                        'is-invalid': submitted && marker.get('type')?.errors
                      }"
                    >
                      <option [selected]="true" value="">Choose Type</option>
                      <option *ngFor="let type of typeSelection" [value]="type">
                        {{ type }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted && marker.get('type')?.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="marker.get('type')?.errors?.required">
                        Type is required
                      </div>
                    </div>
                  </div>

                  <!-- Condition -->
                  <div class="col-4">
                    <label class="lead" for="attributeName">Condition</label>
                    <select
                      class="form-select"
                      formControlName="condition"
                      [ngClass]="{
                        'is-invalid':
                          submitted && marker.get('condition')?.errors
                      }"
                    >
                      <option [selected]="true" value="">
                        Choose Condition
                      </option>
                      <option
                        *ngFor="let condition of conditionSelection"
                        [value]="condition"
                      >
                        {{ condition }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted && marker.get('condition')?.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="marker.get('condition')?.errors?.required">
                        Condition is required
                      </div>
                    </div>
                  </div>

                  <!-- Value -->
                  <div class="col-4">
                    <label class="lead" for="value">Value</label>
                    <input
                      type="text"
                      formControlName="value"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && marker.get('value')?.errors
                      }"

                      (change)="getMarkerQueryResultsAsync()"
                    />
                    <div
                      *ngIf="submitted && marker.get('value')?.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="marker.get('value')?.errors?.required">
                        Value is required
                      </div>
                    </div>
                  </div>
                    <!-- Query Segment Button -->
                    <div class="col-6">
                      <!-- <label class="lead invisible">Invisible label</label>
                    <button type="button" class="btn btn-info form-control text-light bi bi-bell"></button> -->
                    </div>

                    <!-- Remove Condition Button -->
                    <div class="col-2">
                      <label class="lead invisible">Invisible label</label>
                      <button
                        (click)="removeMarkers(i)"
                        type="button"
                        class="btn btn-danger form-control bi bi-trash"
                      ></button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="row mt-5">
            <div class="col-8"></div>
            <div class="col-2">
              <button
                type="button"
                (click)="getMarkerQueryCount()"
                class="btn btn-info form-control text-light bi bi-bell"
              >
                Count
                <span
                  *ngIf="queryLoading"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
              </button>
            </div>
            <div class="col-2">
              <button
                type="button"
                (click)="addMarkers()"
                class="btn btn-success form-control"
              >
                Add Marker
              </button>
            </div>
          </div>
          <div class="card-footer mt-5">
            <div class="form-group float-end">
              <button type="submit" [disabled]="loading" class="btn btn-primary">
                Save
                <span
                  *ngIf="loading"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
              </button>
              <a routerLink="/markers" class="btn btn-link">Cancel</a>
            </div>
          </div>
        </app-card>
      </div>
    <!-- <div class="col-4">
      <app-card class="asyncQueryCard" [cardTitle]="resultsCardTitle">
        <div class="col" *ngIf="asyncQueryLoading">
          <div class="h-100 d-flex justify-content-center align-items-center">
            <app-loading-icon></app-loading-icon>
          </div>
        </div>
        <ng-container *ngFor="let item of markerQueryResults">
          <div class="row">
            <span [innerHTML]="item | prettyprint">{{item}}</span>
          </div>
        </ng-container>
      </app-card>
    </div> -->
  </div>
</form>
