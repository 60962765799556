import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/_services/auth.service';
import { DropDownService } from 'src/app/_services/drop-down.service';
import { NubbleService } from 'src/app/_services/nubble.service';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.css']
})
export class UserViewComponent implements OnInit {

  userId : string = "12334";
  
  layout:any[] =[

    {label:"userId",id:"userId",type:""},
    {label:"instanceId",id:"instanceId",type:""},
    {label:"points",id:"points",type:""},
    {label:"targetPoints",id:"targetPoints",type:""},
    {label:"targetPointsText",id:"targetPointsText",type:""},
    {label:"firstName",id:"firstName",type:""},
    {label:"lastName",id:"lastName",type:""},
    {label:"phoneNumber",id:"phoneNumber",type:""},
    {label:"genderId",id:"genderId",type:""},
    {label:"gender",id:"gender",type:""},
    {label:"dateOfBirth",id:"dateOfBirth",type:""},
    {label:"personalEmail",id:"personalEmail",type:""},
    {label:"personalEmailVerified",id:"personalEmailVerified",type:""},
    {label:"zipCode",id:"zipCode",type:""},
    {label:"city",id:"city",type:""},
    {label:"stateId",id:"stateId",type:""},
    {label:"state",id:"state",type:""},
    {label:"incomeId",id:"incomeId",type:""},
    {label:"income",id:"income",type:""},
    {label:"ethnicityId",id:"ethnicityId",type:""},
    {label:"ethnicity",id:"ethnicity",type:""},
    {label:"educationId",id:"educationId",type:""},
    {label:"education",id:"education",type:""},
    {label:"familyStatusId",id:"familyStatusId",type:""},
    {label:"familyStatus",id:"familyStatus",type:""},
    {label:"relationshipStatusId",id:"relationshipStatusId",type:""},
    {label:"relationshipStatus",id:"relationshipStatus",type:""},
    {label:"suspectedOfFraud",id:"suspectedOfFraud",type:""},
    {label:"hasRedeemed",id:"hasRedeemed",type:""},
    {label:"allDemosAnswered",id:"allDemosAnswered",type:""},
    {label:"campaign",id:"campaign",type:""},
    {label:"referrer",id:"referrer",type:""},
    {label:"referralCode",id:"referralCode",type:""},
    {label:"currencyCode",id:"currencyCode",type:""},
    {label:"country",id:"country",type:""},
    {label:"currencySign",id:"currencySign",type:""},
    {label:"locale",id:"locale",type:""}
  ];

  user:any = {};

  constructor(
    private nubbleService: NubbleService,
    private toastr: ToastrService,
    private authService: AuthService
    ) {
      this.getNubbleUser();
  }

  ngOnInit() {
  }

  getNubbleUser(){
    this.nubbleService.userfind(this.userId).subscribe(data=>{
      this.user = data;
    }, error =>{
        this.toastr.error('Failed to fetch user details');
    }, () => {
        //complete
        //this.search();
    })
  }
}
