import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UsersForReEngagementSettings } from 'src/app/_models/users-for-reengagement-settings';
import { NubbleService } from 'src/app/_services/nubble.service';

@Component({
  selector: 'app-engagement',
  templateUrl: './engagement.component.html',
  styleUrls: ['./engagement.component.css']
})
export class EngagementComponent implements OnInit {

  isLoaded: boolean = false;
  isDisabled: boolean = true;
  isSending: boolean = false;
  isSendMessageDisabled: boolean = true;

  usersForReEngagementSettings: UsersForReEngagementSettings = {location: 0, accessibility: 0, alldemosanswered: 0, message: '', sendMessage: false,
                                                                numberOfDays: 0, forceMessageChoice: 0};

  selectedLocationType: string = "Ignore";
  selectedAccessibilityType: string = "Ignore";
  selectedDemosAnsweredType: string = "Ignore";
  selectedForceMessageType: string = "Do not force"

  locationSelection: {key: string, value: number}[] =
                    [{key: 'Ignore', value: 0}, {key: 'Never Enabled', value: 1}, {key: 'Is Disabled', value: 2}, {key: 'Is Enabled', value: 3}];

  accessibilitySelection: {key: string, value: number}[] = [{key: 'Ignore', value: 0}, {key: 'Is Disabled', value: 1}, {key: 'Is Enabled', value: 2}];

  allDemosAnsweredSelection: {key: string, value: number}[] = [{key: 'Ignore', value: 0}, {key: 'Not Answered', value: 1}, {key: 'Is Answered', value: 2}];

  forceMessageSelection: {key: string, value: number}[] = [{key: 'Do not force', value: 0}, {key: 'Push Notification', value: 1}, {key: 'Email', value: 2}, {key: 'SMS', value: 3}];

  constructor(private nubbleService: NubbleService, private toastr: ToastrService) { }

  ngOnInit() {
  }

  enableEngagement() {
    this.isDisabled = !this.isDisabled;
  }

  setLocation(item) {
    this.selectedLocationType = item.key;
    this.usersForReEngagementSettings.location = item.value;
  }

  setAccessibility(item) {
    this.selectedAccessibilityType = item.key;
    this.usersForReEngagementSettings.accessibility = item.value;
  }

  setAllDemosAnswered(item) {
    this.selectedDemosAnsweredType = item.key;
    this.usersForReEngagementSettings.alldemosanswered = item.value;
  }

  setForceMessage(item) {
    this.selectedForceMessageType = item.key;
    this.usersForReEngagementSettings.forceMessageChoice = item.value;
  }

  allowSendMessage() {
    if (this.isDisabled == false) {
        this.isSendMessageDisabled = !this.isSendMessageDisabled;
        this.usersForReEngagementSettings.sendMessage = !this.usersForReEngagementSettings.sendMessage;
    }
  }

  onSubmit() {
    this.isSending = true;
    this.toastr.info('Request has been sent');
    this.nubbleService.sendReEngagementRequest(this.usersForReEngagementSettings).subscribe(data => {
      this.toastr.success('Total count of users with given combination ' + data);
      if(this.usersForReEngagementSettings.sendMessage == true) {
        this.toastr.success('Message sent to users')
      }
    }, error =>{
      this.toastr.error('Request failed');
    }, () => {
      this.isSending = false;
    })
  }
}
