import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { NubbleUser } from 'src/app/_models/nubble-user';
import { UserAddPoints } from 'src/app/_models/user-add-points';
import { UserEditDOB } from 'src/app/_models/user-edit-dob';
import { UserEditEmail } from 'src/app/_models/user-edit-email';
import { UserEditPhonenumber } from 'src/app/_models/user-edit-phonenumber';
import { UserMarkFraud } from 'src/app/_models/user-mark-fraud';
import { UserVerifyEmail } from 'src/app/_models/user-verify-email';
import { AuthService } from 'src/app/_services/auth.service';
import { InstanceService } from 'src/app/_services/instance.service';
import { NubbleService } from 'src/app/_services/nubble.service';
import { UserDisable } from '../../../_models/user-disable';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css'],
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .dark-modal .modal-content {
      background-color: #292b2c;
      color: white;
    }
    .dark-modal .close {
      color: white;
    }
    .light-blue-backdrop {
      background-color: #5cb3fd;
    }
  `],
})
export class UserDetailsComponent implements OnInit {

  @Input() isFullReviewPage: boolean;
  @Input() user: NubbleUser;
  @Input() userId: string;

  isLoaded: boolean;
  cardTitle: string = 'User Details';

  userDisable: UserDisable;
  userMarkFraud: UserMarkFraud;
  userVerifyEmail: UserVerifyEmail;

  //Edit Email Attributes
  userEditEmail: UserEditEmail = {userId: '', updatedEmail: ''};

  //Add Points Attributes
  userAddPoints: UserAddPoints = {userId: '', points: 0, type: ''};
  addPointsType: string[] = ['Survey', 'Daily Location Tracking', 'Bonus', 'Answer All Demos Bonus', 'Answer Profile Questions Bonus', 'Support'];
  selectedAddPointsType: string = 'Type';
  pointsToAdd: number = 0;

  //Edit Phonenumber Attributes
  userEditPhonenumber: UserEditPhonenumber = {userId: '', updatedPhonenumber: ''};

  //Edit DateOfBirth Attributes
  userEditDOB: UserEditDOB = { userId: '', dateOfBirth: '' };

  constructor(private nubbleService: NubbleService, private toastr: ToastrService, private authService: AuthService,
              private clipboard: ClipboardService, private modalService: NgbModal, private instanceService: InstanceService) {}

  ngOnInit() {
    this.isLoaded = true;
  }

  /* BROWSER ALERTS TO VERIFY TOGGLE CHANGES BEFORE API CALLS GO THROUGH */

  //Browser Alert: Verify Disable User
  verifyMarkUserDisabled($event) {

    this.user.isDeleted = $event.target.checked;
    this.userDisable = {userId: this.user.userId, isDisabled: this.user.isDeleted};

    if ($event.target.checked == true) {

      if (window.confirm("Are you sure you would like to disable this user?") == true) {

        this.markUserDisabled("User Marked as Disabled", "Failed to Mark User as Disabled");
      }

      else {
        $event.preventDefault();
        this.ngOnInit();
      }
    }

    else if ($event.target.checked == false) {

      if (window.confirm("Are you sure you would like to enable this user?") == true) {

        this.markUserDisabled("User Marked as Enabled", "Failed to Enable User");
      }

      else {
        $event.preventDefault();
        this.ngOnInit();
      }
    }
  }

  //Browser Alert: Verify Mark User as Fraud
  // verifyMarkUserFraud($event) {

  //   this.user.suspectedOfFraud = $event.target.checked;
  //   this.userMarkFraud = {userId: this.user.userId, isMarkedFraud: this.user.suspectedOfFraud};

  //   if ($event.target.checked == true) {

  //     if (window.confirm("Are you sure you would like to mark this user as fraud? They will not receive rewards if you do!") == true) {

  //       this.markUserFraud("User marked as fraud", "Failed to mark user as fraud");
  //     }
  //     else {
  //       $event.preventDefault();
  //       this.ngOnInit();
  //     }
  //   }

  //   else if ($event.target.checked == false) {

  //     if (window.confirm("Are you sure you would like to unmark this user as fraud? They will begin to receive rewards!") == true) {

  //       this.markUserFraud("User unmarked as fraud", "Failed to unmark user as fraud");

  //     } else {
  //       $event.preventDefault();
  //       this.ngOnInit();
  //     }
  //   }
  // }

  //Browser Alert: Verify User Email
  verifyMarkUserEmail($event) {

    this.user.personalEmailVerified = $event.target.checked;
    this.userVerifyEmail = {userId: this.user.userId, isVerified: this.user.personalEmailVerified};

    if ($event.target.checked == true) {

      if (window.confirm("Are you sure you would like to verify this users email? Please ensure it is correct") == true) {

        this.markUserEmailVerified("User email verified", "Failed to mark user email as verified");
      }
      else {
        $event.preventDefault();
        this.ngOnInit();
      }
    }

    else if ($event.target.checked == false) {

      if (window.confirm("Are you sure you would like to unverify this users email?") == true) {

        this.markUserEmailVerified("User email unverified", "Failed to mark user email as unverified");
      }
      else {
        $event.preventDefault();
        this.ngOnInit();
      }
    }
  }

  /*API CALLS TO UPDATE BOOLEAN VALUES ON TOGGLE CHANGE */

  //Toggle: Mark User as Disabled
  markUserDisabled(successMsg, failureMsg) {
    this.nubbleService.userDisable(this.userDisable).subscribe((data) => {},
    error =>{
      this.user.isDeleted = !this.user.isDeleted;
      this.toastr.error(failureMsg);
    }, () => {
        this.toastr.success(successMsg);
        this.isLoaded = true;
    })
  }

  //Toggle: Mark User as Fraud
  markUserFraud(successMsg, failureMsg) {
    this.nubbleService.userMarkFraud(this.userMarkFraud).subscribe((data) => {},
    error =>{
      this.user.suspectedOfFraud = !this.user.suspectedOfFraud;
      this.toastr.error(failureMsg);
    }, () => {
        this.toastr.success(successMsg);
        this.isLoaded = true;
    })
  }

  //Toggle: Mark User Email as Verified
  markUserEmailVerified(successMsg, failureMsg) {
    this.nubbleService.userVerifyEmail(this.userVerifyEmail).subscribe((data) => {},
    error =>{
      this.user.personalEmailVerified = !this.user.personalEmailVerified;
      this.toastr.error(failureMsg);
    }, () => {
        this.toastr.success(successMsg);
        this.isLoaded = true;
    })
  }

  //For copying data from cards to clipboard
  copyContent(text) {
    this.clipboard.copyFromContent(text);
    this.toastr.info('Copied to clipboard');
  }

  /* MODALS FOR EDITING USER DETAILS */

  //Open the edit email modal
  openEditEmailModal(editEmailModal) {
    this.userEditEmail.updatedEmail = this.user.personalEmail;
    this.modalService.open(editEmailModal, { centered: true, scrollable: false});
  }

  //Submitting data from edit email modal
  onSubmitEditEmail(modal) {
    this.userEditEmail.userId = this.user.userId;

    modal.close('Close click');

    this.nubbleService.userEditEmail(this.userEditEmail).subscribe(data => {
      this.user.personalEmail = this.userEditEmail.updatedEmail;
      this.toastr.success('Email has been updated');
    }, error =>{
      this.toastr.error('Failed to update email');
    }, () => {
    })
  }

  //Open the add points modal
  openAddPointsModal(addPointsModal) {
    this.modalService.open(addPointsModal, { centered: true, scrollable: false});
  }

  //Submitting data from add points modal
  onSubmitAddPoints(modal) {
    if (this.selectedAddPointsType == 'Type') {
      this.toastr.error('Type must be selected');
    } else {
      this.userAddPoints = {userId: this.user.userId, points: this.pointsToAdd, type: this.selectedAddPointsType};

      modal.close('Close click');

      this.nubbleService.userAddPoints(this.userAddPoints).subscribe(data => {
        this.user.points += this.pointsToAdd;
        this.toastr.success('Points added to user');
      }, error =>{
        this.toastr.error('Failed to add points to user');
      }, () => {
      })
    }
  }

  setType(item) {
    this.selectedAddPointsType = item;
  }

  //Open the edit phonenumber modal
  openEditPhonenumberModal(editPhonenumberModal) {
    this.userEditPhonenumber.updatedPhonenumber = this.user.phoneNumber;
    this.modalService.open(editPhonenumberModal, { centered: true, scrollable: false});
  }

  //Submitting data from edit email modal
  onSubmitEditPhonenumber(modal) {

    this.userEditPhonenumber.userId = this.user.userId;

    modal.close('Close click');

    this.nubbleService.userEditPhonenumber(this.userEditPhonenumber).subscribe(data => {
      this.user.phoneNumber = this.userEditPhonenumber.updatedPhonenumber;
      this.toastr.success('Phonenumber has been updated');
    }, error =>{
      this.toastr.error('Failed to update Phonenumber');
    }, () => {;
    })
  }

  //Open the edit phonenumber modal
  openEditDateOfBirthModal(editDateOfBirthModal) {
    this.userEditDOB.dateOfBirth = this.user.dateOfBirth;
    this.modalService.open(editDateOfBirthModal, { centered: true, scrollable: false});
  }

  //Submitting data from edit date of birth modal
  onSubmitEditDateOfBirth(modal) {

    this.userEditDOB.userId = this.user.userId;

    modal.close('Close click');

    this.nubbleService.userEditDOB(this.userEditDOB).subscribe(data => {
      this.user.dateOfBirth = this.userEditDOB.dateOfBirth;
      this.toastr.success('Date of Birth has been updated');
    }, error =>{
      this.toastr.error('Failed to update Date of Birth');
    }, () => {;
    })
  }
}
