


<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item"><a href="/users">Users</a></li>
    <li *ngIf="!isAddMode" class="breadcrumb-item active" aria-current="page">Edit User</li>
    <li *ngIf="isAddMode" class="breadcrumb-item active" aria-current="page">Register User</li>
  </ol>
</nav>
<!--

  {{form.value|json}}
-->

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="card">
  <div class="card-body">

  <input type="hidden" formControlName="instanceId" class="form-control" />
    <div class="form-row">
      <div class="form-group">
        <label for="username">Email</label>
        <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
        </div>
    </div>


          <div class="form-group col">
            <label for="firstName">First Name</label>
            <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required">First name is required</div>
            </div>
        </div>

        <div class="form-group col">
            <label for="lastName">Last Name</label>
            <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors.required">Last name is required</div>
            </div>
        </div>
        <!--
        <div class="form-group" *ngIf="isAddMode">
          <label for="password">Password
            <em *ngIf="!isAddMode">(Leave blank to keep the same password)</em>
          </label>
          <input  type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
          </div>
      </div>

        -->

      <div class="form-group col">
        <label for="role">Role</label>

        <select class="form-select"  formControlName="role" [ngClass]="{ 'is-invalid': submitted && f.role.errors }" >
          <option [selected]="true" value="Member">Member</option>
          <option value="Admin">Admin</option>
          <option value="SuperAdmin" *ngIf="auth.user?.userDetails?.superAdmin == true">SuperAdmin</option>
        </select>


        <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
            <div *ngIf="f.role.errors.required">Role is required</div>
        </div>
       </div>

       <div class="form-group col">
        <label for="role">Instance</label>

        <select class="form-select"  formControlName="instanceId" [ngClass]="{ 'is-invalid': submitted && f.instanceId.errors }" >
          <option *ngFor="let item of instances" [value]="item.value">{{item.option}}</option>
        </select>


        <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
            <div *ngIf="f.instanceId.errors?.required">Instance is required</div>
        </div>
       </div>

    </div>
  </div>
  <div class="card-footer">
    <div class="form-group  float-end">
        <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Save
        </button>
        <a routerLink="/users" class="btn btn-link">Cancel</a>
    </div>
  </div>
</div>
</form>
