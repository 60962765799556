import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebsiteVisits } from 'src/app/_models/website-visits';
import { NubbleService } from 'src/app/_services/nubble.service';

@Component({
  selector: 'app-website-visits',
  templateUrl: './website-visits.component.html',
  styleUrls: ['./website-visits.component.css'],
})
export class WebsiteVisitsComponent implements OnInit {

  @Input() userId;
  websiteVisitsList: WebsiteVisits[] = [];

  isLoading: boolean = false;
  isLoaded: boolean = false;

  cardTitle: string = 'Website Visits';

  layout: any[] = [
    { label: 'Website', id: 'pageDomain', type: '' },
    { label: 'Visits', id: 'visitCount', type: '' },
  ];

  constructor(
    private nubbleService: NubbleService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  listWebsiteVisits() {
    this.nubbleService.userListWebsiteVisits(this.userId).subscribe(
      (data) => {
        this.websiteVisitsList = data;
      },
      (error) => {
        this.isLoaded = true;
        this.isLoading = false;
        this.toastr.warning(error);
      },
      () => {
        this.isLoaded = true;
        this.isLoading = false;
      }
    );
  }
}
