import {Component, HostListener, Input, OnInit} from "@angular/core";
import {Note} from "../../../_models/note";
import {ToastrService} from "ngx-toastr";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../_services/auth.service";
import {ConfirmModalService} from "../../../_services/confirm-modal.service";
import {ProjectNoteService} from "../../../_services/project-note.service";

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})

export class NotesComponent implements OnInit {
  constructor(private projectNoteService: ProjectNoteService, private toastr: ToastrService, private formBuilder: FormBuilder, private authService: AuthService, private confirmModalService: ConfirmModalService) {
    this.noteForm = this.formBuilder.group({
      noteText: ['', Validators.required],
    });
    this.onResize();
  }

  @Input() projectId: string = "";
  notes: Note[] = [];
  noteForm: FormGroup;
  loading = false;
  currentWidth = 0;
  submitted = false;
  editing = false;
  selectedNote: Note | null;
  first: number = 0;
  rows: number = 10;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.currentWidth = window.innerWidth;
  }

  ngOnInit() {
    this.getNotes();
  }

  onPageChange(event) {
    this.first = event.first;
    this.rows = event.rows;
  }

  get f() {
    return this.noteForm.controls;
  }

  formatDate(createDate: string, includeTime: boolean) {
    const date = new Date(createDate).toLocaleDateString();
    const time = new Date(createDate).toLocaleTimeString();
    return includeTime ? date + ' ' + time : date;
  }

  getNotes() {
    this.projectNoteService.list(this.projectId).subscribe(data=>{
      this.notes = data;
    }, error =>{
      this.toastr.error('Failed to fetch notes');
    }, () => {

    })
  }
  async addNote() {
    this.submitted = true;

    if (this.noteForm.invalid) {
      return;
    }

    this.loading = true;

    let date = Date.now()
    let dateString = new Date(date).toLocaleString();

    let note: Note = {
      projectId: this.projectId,
      noteText: this.noteForm.controls.noteText.value,
      createDate: dateString,
      lastModified: dateString,
      createdBy: this.authService.user?.userDetails?.email,
      isDeleted: false
    }

    this.projectNoteService.add(note).subscribe(data=>{
      this.toastr.success('New note added');
      this.loading = false;
    }, error =>{
      this.toastr.warning(error);
      this.loading = false;
    }, () => {
      this.noteForm.reset();
      this.submitted = false;
      this.loading = false;
      this.getNotes();
    })
  }

  onRowEdit(note: Note) {
    this.selectedNote = note;
    this.editing = true;
  }

  updateNote(text: string) {
    let date = Date.now()
    let dateString = new Date(date).toLocaleString();

    this.selectedNote!.noteText = text;
    this.selectedNote!.lastModified = dateString;

    this.projectNoteService.update(this.selectedNote!).subscribe(data=>{
      this.toastr.success('Note updated');
    }, error =>{
      this.toastr.warning(error);
    }, () => {
      this.selectedNote = null;
      this.editing = false;
      this.getNotes();
    })
  }

  onRowDelete(note: Note) {
    this.confirmModalService.confirm('Are you sure?', 'Are you sure you want to delete this note?', 'Yes')
      .then((confirmed) => confirmed ? this.deleteNote(note) : null);
  }
  deleteNote(note: Note) {
    this.projectNoteService.delete(note).subscribe(data=>{
      this.toastr.success('Note deleted');
    }, error =>{
      this.toastr.warning(error);
    }, () => {
      this.getNotes();
    })
  }
}
