import { EditInboxMessageComponent } from './_sections/inbox-messages/edit-inbox-message/edit-inbox-message.component';
import { AddEditCampaignComponent } from './_sections/campaigns/campaign/add-edit-campaign/add-edit-campaign.component';
import { CampaignComponent } from './_sections/campaigns/campaign/campaign.component';
import { EditProjectComponent } from './_sections/project/edit-project/edit-project.component';
import { AddProjectComponent } from './_sections/project/add-project/add-project.component';
import { ViewProjectComponent } from './_sections/project/view-project/view-project.component';
import { AddUpdateVendorComponent } from './_sections/vendors/add-update-vendor/add-update-vendor.component';
import { AddUpdateClientComponent } from './_sections/clients/add-update-client/add-update-client.component';
import { RegisterUpdateUserComponent } from './_sections/users/register-update-user/register-update-user.component';
import { UsersComponent } from './_sections/users/users.component';
import { AddEditInstanceComponent } from './_sections/instance/add-edit-instance/add-edit-instance.component';
import { ExamplesComponent } from './_sections/examples/examples.component';
import { AuthGuard } from './_guards/auth.guard';
import { InstanceComponent } from './_sections/instance/instance.component';
import { VendorsComponent } from './_sections/vendors/vendors.component';
import { ClientsComponent } from './_sections/clients/clients.component';
import { ProjectComponent } from './_sections/project/project.component';
import { ReleaseNotesComponent } from './_sections/release-notes/release-notes.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PointsHistoryViewComponent } from './_sections/review-user/points-history-view/points-history-view.component';
import { RewardHistoryViewComponent } from './_sections/review-user/reward-history-view/reward-history-view.component';
import { PendingRewardsComponent } from './_sections/pending-rewards/pending-rewards.component';
import { ReviewuserComponent } from './_sections/review-user/review-user.component';
import { UserViewComponent } from './_sections/nubble/user-view/user-view.component';
import { EngagementComponent } from './_sections/engagement/engagement.component';
import { MarkersComponent } from './_sections/markers/markers.component';
import { AddUpdateMarkerComponent } from './_sections/markers/add-update-marker/add-update-marker.component';
import { FraudFlagsListUsersComponent } from './_sections/fraud-flags-list-users/fraud-flags-list-users.component';
import { TestDropdownsComponent } from './_sections/markers/test-dropdowns/test-dropdowns.component';
import { CreateInboxMessageComponent } from './_sections/inbox-messages/create-inbox-message/create-inbox-message.component';
import { ListInboxMessagesComponent } from './_sections/inbox-messages/list-inbox-messages/list-inbox-messages.component';
import { MarkersListComponent } from './_sections/markers/markers-list/markers-list.component';
import { SearchListUsersComponent } from './_sections/search-list-users/search-list-users.component';

const routes: Routes = [
  {
    path: '',
    component: ProjectComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Member'], containerClass: 'container-fluid' },
  }, //
  {
    path: 'clients',
    component: ClientsComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Member'] },
  },
  {
    path: 'clients/add',
    component: AddUpdateClientComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Member'] },
  },
  {
    path: 'clients/edit/:id',
    component: AddUpdateClientComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Member'] },
  },
  {
    path: 'vendors',
    component: VendorsComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Member'] },
  },
  {
    path: 'vendors/add',
    component: AddUpdateVendorComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Member'] },
  },
  {
    path: 'vendors/edit/:id',
    component: AddUpdateVendorComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Member'] },
  },
  {
    path: 'instances',
    component: InstanceComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { roles: ['SuperAdmin'] },
  },
  {
    path: 'instances/add',
    component: AddEditInstanceComponent,
    canActivate: [AuthGuard],
    data: { roles: ['SuperAdmin'] },
  },
  {
    path: 'release-notes',
    component: ReleaseNotesComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Member'] },
  },
  {
    path: 'campaigns',
    component: CampaignComponent,
    canActivate: [AuthGuard],
    data: { roles: ['SuperAdmin'] },
  },
  {
    path: 'pendingrewards',
    component: PendingRewardsComponent,
    canActivate: [AuthGuard],
    data: { roles: ['SuperAdmin'] },
  },
  {
    path: 'searchusers',
    component: SearchListUsersComponent,
    canActivate: [AuthGuard],
    data: { roles: ['SuperAdmin'] },
  },
  {
    path: 'campaigns/add',
    component: AddEditCampaignComponent,
    canActivate: [AuthGuard],
    data: { roles: ['SuperAdmin'] },
  },
  {
    path: 'instances/edit/:id',
    component: AddEditInstanceComponent,
    canActivate: [AuthGuard],
    data: { roles: ['SuperAdmin'] },
  },

  {
    path: 'projects',
    component: ProjectComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Member'], containerClass: 'container-fluid' },
  },
  {
    path: 'projects/view/:id',
    component: ViewProjectComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Member'] },
  },
  {
    path: 'projects/add',
    component: AddProjectComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Member'] },
  },
  {
    path: 'projects/edit/:id',
    component: EditProjectComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Member'] },
  },

  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },
  {
    path: 'users/add',
    component: RegisterUpdateUserComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },
  {
    path: 'users/edit/:email',
    component: RegisterUpdateUserComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },
  {
    path: 'examples',
    component: ExamplesComponent,
    canActivate: [AuthGuard],
    data: { roles: ['SuperAdmin'] },
  },
  {
    path: 'nubble/pointshistory',
    component: PointsHistoryViewComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },
  {
    path: 'nubble/rewardhistory',
    component: RewardHistoryViewComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },
  {
    path: 'nubble/userfind',
    component: UserViewComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },
  {
    path: 'reviewuser/:id',
    component: ReviewuserComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },
  {
    path: 'engagement',
    component: EngagementComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },
  {
    path: 'createmessage',
    component: CreateInboxMessageComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },
  {
    path: 'editmessage/:id',
    component: EditInboxMessageComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },
  {
    path: 'inboxmessages',
    component: ListInboxMessagesComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },
  {
    path: 'markers',
    component: MarkersComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },

  //To be removed
  //***********//

  {
    path: 'markers/testdropdowns',
    component: TestDropdownsComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },

  //***********//

  {
    path: 'markers/add',
    component: AddUpdateMarkerComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },
  {
    path: 'markers/edit/:id',
    component: AddUpdateMarkerComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },

  {
    path: 'markerslist',
    component: MarkersComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },
  {
    path: 'fraudmarkers',
    component: FraudFlagsListUsersComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin'] },
  },
  {
    path: '**',
    component: ProjectComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { roles: ['Member'] },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
