import { AuthService } from 'src/app/_services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { InstanceService } from 'src/app/_services/instance.service';

@Component({
  selector: 'app-add-edit-instance',
  templateUrl: './add-edit-instance.component.html',
  styleUrls: ['./add-edit-instance.component.css'],
})
export class AddEditInstanceComponent implements OnInit {
  form: FormGroup;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private instanceService: InstanceService,
    public authService: AuthService,
    private toastr: ToastrService
  ) {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.form = this.formBuilder.group({
      id: [''],
      secret: [''],
      name: ['', Validators.required],
      url: ['', Validators.required],
      projectPrefix: [''],
      //projectIndex: : [0],
      forensicThreshold: [25, Validators.required],
      fraudThreshold: [70, Validators.required],
      researchDefenderThreshold: [70, Validators.required],
      forensicAPIKey: ['0E3858C13472CC8775ED0D4B8628AD0D-4004-1'],
      relevantIdAPIKey: ['E692B4E8-9C65-11EB-8813-1264B5C78F33'],
      researchDefenderAPIKey: ['N/A'],
      smartyStreetsKey: ['N/A'],
      smartyStreetsToken: ['N/A'],


      country: ['US'],
      platform: ['Android'],
      realityMineAPIKey: ['py77sssboqhupoderajtziajla'],
      realityMineGroup: ['Lighthouse.ValueMe'],

      isBrandedPanel: [false],
      brandName: [''],
      brandLogo: [''],

      allowEntryWithoutReferralCode: [false],
      isBackUpPanel: [false],

      minPointsToRedeem: [100],
      minPointsToRedeemPaypal: [1000],
      minPointsToRedeemVirtualVisa: [1000],
      bonus: [100],
      bonusThreshold: [400],


      allDemosAnsweredReward: [100],
      firstRewardDelayHours: [48],
      standardRewardDelayHours:[0],
      currencySymbol: [''],
      pointEqualTo: [.01],
      pointEqualToDescription: [''],
      virtualVisaTremendousId: [''],
      payPalTremendousID: [''],
      currencyCode: [''],

      isAllowReferrals: [false],
      referralAmount: [0],

      pointsPerDayForActivityTracking: [8],
      activityTrackingLastConnectionTimeoutHours: [-1],
      realityMineAccessibility: [false],

      locationTrackingLastConnectionTimeoutHours: [-1],
      pointsPerDayForLocationTracking: [7],
      foursquareLocation: [false],

      microphoneTrackingLastConnectionTimeoutHours: [-1],
      kantarACR: [false],
      pointsPerDayForMicrophoneTracking:[8],


      fraudCoolingOffDays:[31],
      fraudTimeframeHours:[72],
      fraudOnBoardingCheckEnabled:[true],
      fraudLocationCheckEnabled:[true],
      fraudAppsCheckEnabled: [true],
      fraudHomeCheckEnabled:  [true],
      fraudSurveyCheckEnabled: [true],
      fraudBatteryCheckEnabled: [true],

      palettePrimaryDefault: ['#3f65c6'],
      palettePrimaryMedium: ['#7a9ffd'],
      palettePrimaryLight: ['#b5caff'],
      palettePrimaryDark: ['#223c80'],
      paletteNeutralWhite: ['#FFFFFF'],
      paletteNeutralGrey100: ['#f5f6f9'],
      paletteNeutralGrey200: ['#f0f0f0'],
      paletteNeutralGrey300: ['#e0e1e3'],
      paletteNeutralGrey400: ['#c4c4c4'],
      paletteNeutralGrey500: ['#989ca3'],
      paletteNeutralGrey600: ['#6b6b6b'],
      paletteNeutralGrey700: ['#444444'],
      paletteNeutralGrey800: ['#313a49'],
      paletteNeutralGrey900: ['#001233'],
      paletteNeutralBlack: ['#000000'],
      paletteStateSuccess: ['#8bc34a'],
      paletteStateError: ['#d30000'],
      paletteOther: [['']],
    });
    if (!this.isAddMode) {
      this.instanceService
        .getInstance(this.id)
        .pipe(first())
        .subscribe((x) => {
          return this.form.patchValue(x)
        });
    }
  }

  ngOnInit() {}

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.addOrUpdateInstance();
  }

  private addOrUpdateInstance() {
    this.instanceService
      .addOrUpdateInstance({ ...this.form.value, paletteOther: [] })
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastr.success('Instance added successfully');
          this.router.navigate(['/instances']);
        },
        error: (error) => {
          this.toastr.error(error);
          this.loading = false;
        },
      });
  }
}
