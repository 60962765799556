import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { UserSurveyHistory } from 'src/app/_models/user-survey-history';
import { PrettyPrintPipe } from 'src/app/_pipes/prettyPrintPipe.pipe';
import { NubbleService } from 'src/app/_services/nubble.service';

@Component({
  selector: 'app-survey-history',
  templateUrl: './survey-history.component.html',
  styleUrls: ['./survey-history.component.css'],
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .dark-modal .modal-content {
      background-color: #292b2c;
      color: white;
    }
    .dark-modal .close {
      color: white;
    }
    .light-blue-backdrop {
      background-color: #5cb3fd;
    }
  `],
  providers: [ PrettyPrintPipe ]
})

export class SurveyHistoryComponent implements OnInit {

  @Input() userId: string;
  items: UserSurveyHistory[] = [];
  isLoaded: boolean = true;
  cardTitle: string = 'Survey History';

  //Dynamic Modal Values
  modalTitle: string;
  modalBody: string;

  layout: any[] = [
    { label: 'Project Id', id: 'projectCode', type: 'text' },
    { label: 'Status', id: 'surveyStatus', type: 'text' },
    { label: 'Buy CPI', id: 'buyCPI', type: 'text' },
    { label: 'City', id: 'city', type: 'text' },
    { label: 'Country', id: 'country', type: 'text' },
    { label: 'Date', id: 'date', type: 'onlydate' },
    { label: 'Fraud Score', id: 'fraudScore', type: 'text' }
  ];

  actions:any[] =[
    {label:"Fraud JSON",icon:"",type:"fraudJSON"}
    // {label:"Request JSON",icon:"",type:"requestJSON"}
  ];

  constructor(
    private modalService: NgbModal,
    private nubbleService: NubbleService,
    private toastr: ToastrService,
    private clipboardApi: ClipboardService
  ) {}

  ngOnInit() {
    this.nubbleService.userListSurveyVisits(this.userId).subscribe(
      (data) => {
        this.items = data;

        this.items.forEach(element => {

          if (element.fraudJSONObject != null && element.fraudJSONObject != "") {
            let fraudJSON = JSON.parse(element.fraudJSONObject);
            element.city = fraudJSON?.City;
            element.country = fraudJSON?.Country;
          }
        });
      },
      (error) => {
        this.toastr.warning(error);
      },
      () => {
      }
    );
  }

  handleAction($event, longContent){

    if($event.type==='fraudJSON'){
      this.modalTitle = "Fraud JSON Data";
      this.modalBody = $event.row.fraudJSONObject;
      this.openVerticallyCentered(longContent)
    }
    // else if($event.type==='requestJSON'){
    //   this.modalTitle = "Request JSON Data";
    //   this.modalBody = $event.row.requestJSON;
    //   this.openVerticallyCentered(longContent)
    // }
  }

  openVerticallyCentered(longContent) {
    this.modalService.open(longContent, { centered: true, scrollable: true, size: 'lg' });
  }

  copyToClipboard(text) {
    this.clipboardApi.copyFromContent(text);
    this.toastr.info("JSON Copied to Clipboard")
  }
}
