import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { FraudMarker } from '../_models/fraud-marker';
import { FraudMarkerEditIsReviewed } from '../_models/fraud-marker-edit-is-reviewed';

@Injectable({
  providedIn: 'root'
})

export class FraudService {

  constructor(private http: HttpClient) { }

  listUsersFraudReview() {
    return this.http.get<any[]>(
      `${environment.apiUrl}/api/userfraud/listusersfraudreview`
    );
  }

  getAllFraudMarkers() {
    return this.http.get<FraudMarker[]>(`${environment.apiUrl}/api/nubble/listfraudmarkers`)
  }

  getUserFraudMarkers(userId: string) {
    return this.http.get<FraudMarker[]>(`${environment.apiUrl}/api/nubble/userfraudmarkers/` + userId)
  }

  fraudMarkerEditIsReviewed(fraudMarkerEditIsReviewed: FraudMarkerEditIsReviewed) {
    return this.http.post(
      `${environment.apiUrl}/api/nubble/fraudmarker/isreviewed`,
      fraudMarkerEditIsReviewed
    );
  }

  updateFraudAttribute(fraudSetting: any) {
    return this.http.post(`${environment.apiUrl}/api/userfraud/updateuserfraudsetting`, fraudSetting);
  }

  updateUserFraudStatus(fraudStatus: any) {
    return this.http.post<any>(`${environment.apiUrl}/api/userfraud/updateusersfraudstatus`, fraudStatus);
  }

  getUserBatteryLevelHistory(userId: string) {
    return this.http.get<any>(`${environment.apiUrl}/api/userfraud/listuserbatterylevels/` + userId);
  }
}
