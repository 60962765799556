<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item active" aria-current="page">Engagement</li>
  </ol>
</nav>

<div class="card-body">
  <div class="row mt-3">
    <div class="col">
      <div class="card">
        <div class="card-header">Engagement
          <div class="form-check form-check-inline form-switch float-end" (click)="enableEngagement()">
            <input class="form-check-input" type="checkbox" [(ngModel)]="isDisabled"/>
            <label class="form-check-label">Disabled</label>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="btn-group">
              <div class="col-2 m-3">
                <div class="row">
                  <label class="form-check-label">Location</label>
                </div>
                <div class="row">
                  <button type="button" class="btn btn-outline-secondary dropdown-toggle" style="white-space:normal; width: 180px;" [disabled]="isDisabled" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{selectedLocationType}}
                  </button>
                <div class="dropdown-menu">
                  <div *ngFor="let item of locationSelection">
                    <a class="dropdown-item" (click)="setLocation(item)">{{item.key}}</a>
                  </div>
                </div>
                </div>
              </div>

              <div class="col-2 m-3">
                <div class="row">
                  <label class="form-check-label">Accessibility</label>
                </div>
                <div class="row">
                  <button type="button" class="btn btn-outline-secondary dropdown-toggle overflow" style="white-space:normal; width: 180px;" [disabled]="isDisabled" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{selectedAccessibilityType}}
                  </button>
                  <div class="dropdown-menu">
                    <div *ngFor="let item of accessibilitySelection">
                      <a class="dropdown-item" (click)="setAccessibility(item)">{{item.key}}</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-2 m-3">
                <div class="row">
                  <label class="form-check-label">All Demos Answered</label>
                </div>
                <div class="row">
                  <button type="button" class="btn btn-outline-secondary dropdown-toggle" style="white-space:normal; width: 180px;" [disabled]="isDisabled" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{selectedDemosAnsweredType}}
                  </button>
                  <div class="dropdown-menu">
                    <div *ngFor="let item of allDemosAnsweredSelection">
                      <a class="dropdown-item" (click)="setAllDemosAnswered(item)">{{item.key}}</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-2 m-3">
                <div class="row">
                  <label class="form-check-label">Force Message</label>
                </div>
                <div class="row">
                  <button type="button" class="btn btn-outline-secondary dropdown-toggle" style="white-space:normal; width: 180px;" [disabled]="isDisabled" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{selectedForceMessageType}}
                  </button>
                  <div class="dropdown-menu">
                    <div *ngFor="let item of forceMessageSelection">
                      <a class="dropdown-item" (click)="setForceMessage(item)">{{item.key}}</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-2 m-3">
                <div class="row">
                  <label class="form-check-label">Days since last engagement</label>
                </div>
                <div class="row">
                  <input type="number" class="form-control" min="0" required [(ngModel)]="usersForReEngagementSettings.numberOfDays" [disabled]="isDisabled"/>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6 m-3">
              <div class="row">
                <textarea type="text" class="form-control" placeholder="Engagement Message" [(ngModel)]="usersForReEngagementSettings.message" title="Engagement Message" [disabled]="isSendMessageDisabled"></textarea>
              </div>
            </div>
            <div class="col-2 m-3">
              <div class="row">
                <div class="form-check form-check-inline form-switch" (click)="allowSendMessage()">
                  <input class="form-check-input" type="checkbox" [disabled]="isDisabled" [(ngModel)]="usersForReEngagementSettings.sendMessage"/>
                  <label class="form-check-label">Send Message</label>
                </div>
              </div>
            </div>
            <div class="col-3 m-3">
              <div class="row justify-content-end">
                <button class="btn btn-outline-success form-control" type="button" style="width: 200px; margin-right: 40px;" (click)="onSubmit()" [disabled]="isDisabled" *ngIf="!isSending">
                  Send
                </button>
                <button class="btn btn-outline-success form-control" type="button" style="width: 200px; margin-right: 40px;" *ngIf="isSending">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Sending...
                </button>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
