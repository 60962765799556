import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  list() {
    return this.http.get<User[]>(`${environment.apiUrl}/api/user`);
  }

  find(id: string) {
    return this.http.get<User>(`${environment.apiUrl}/api/user/` + id);
  }

  register(model: User) {
    return this.http.post(`${environment.apiUrl}/api/user`, model);
  }

  update(model: User) {
    return this.http.put(`${environment.apiUrl}/api/user`, model);
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/user/` + id);
  }
}
