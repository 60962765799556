import { first } from 'rxjs/operators';
import { DropDown } from 'src/app/_models/drop-down';
import { AuthService } from './../../../../_services/auth.service';
import { DropDownService } from './../../../../_services/drop-down.service';
import { ToastrService } from 'ngx-toastr';
import { CampaignService } from './../../../../_services/campaign.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-edit-campaign',
  templateUrl: './add-edit-campaign.component.html',
  styleUrls: ['./add-edit-campaign.component.scss']
})
export class AddEditCampaignComponent implements OnInit {
  submitted = false;
  form: FormGroup;
  loading = false;
  instances: DropDown[] = [];
  selectedInstanceId = '';
  sources: DropDown[] = [
      { value: 'Facebook', option: 'Facebook' },
      { value: 'Instagram', option: 'Instagram' },
      { value: 'Snapchat', option: 'Snapchat' },
      { value: 'Google', option: 'Google' },
      { value: 'Other', option: 'Other' },
    ];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private campaignService: CampaignService,
    private toastr: ToastrService,
    private dd: DropDownService,
    private authService: AuthService
    ){
       this.selectedInstanceId = this.authService.user?.userDetails?.instanceId;
       this.form = this.formBuilder.group({
          instanceId: [this.selectedInstanceId],
          campaignName: ['', Validators.required],
          source: ['', Validators.required],
          numberOfReferrals: [0, Validators.required],
          cpa: [0, Validators.required],
        });
    }

  ngOnInit(){
    this.getInstances();
  }
  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.loading = true;

    this.campaignService.add(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastr.success('Campaign added successfully');
          this.router.navigate(['/campaigns']);
        },
        error: (error) => {
          this.toastr.error(error);
          this.loading = false;
        },
      });

  }

   getInstances() {
    this.dd.instances().subscribe(
      (data) => {
        this.instances = data;
      },
      (error) => {
        this.toastr.error('Failed to fetch instances');
      },
      () => {}
    );
  }
}
