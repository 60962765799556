import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  @Input() cardTitle: string;

  constructor() { }

  ngOnInit() {
  }

  setTitle(cardTitle: string) {
    this.cardTitle = cardTitle;
  }
}
