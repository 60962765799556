<div class="card h-100 shadow-sm">
  <div class="card-header lead">
    <div class="row">
      <div class="col-6">
        <span class="float-start">{{cardTitle}}</span>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <ng-content></ng-content>
    </div>
  </div>
</div>
