import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

constructor(
  private http: HttpClient
) { }



allQuestions() {
  return this.http.get<any[]>(`${environment.linkProcessorURl}/api/Supplier/AllQuestions`)
}

questionByKey(key: string) {
  return this.http.get<any>(`${environment.linkProcessorURl}/api/QuestionByKey/`+key)
}


}
