
<div class="table-responsive">


<button *ngIf="showCreateNew"  (click)="actionSelected({},'add')"  class="btn btn-outline-secondary float-end">Create New</button>

<table class="table table-striped table-hover">
  <thead>
  <tr>
    <th *ngFor="let col of layout">{{ col.label }}</th>
    <th *ngFor="let link of links"></th>
    <th *ngFor="let action of actions"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of items">
    <td *ngFor="let col of layout" style="white-space: nowrap">
      <ng-container *ngIf="col.type===null || col.type==='' || col.type==='text'">
        <table *ngIf="col.label == 'Project Name'; else unlimitedText" class="limitedTable">
          <tr>
            <td class="limitedText">
              {{item[col.id]}}
            </td>
          </tr>
        </table>
        <ng-template #unlimitedText>
          {{item[col.id]}}
        </ng-template>
      </ng-container>
      <ng-container *ngIf="col.type==='tremendousLink'">
        <app-link [url]="item[col.link]" [displayName]="item[col.id]"></app-link>
      </ng-container>
      <ng-container *ngIf="col.type==='link'">
        <app-link [url]="item[col.id]"></app-link>
      </ng-container>
      <ng-container *ngIf="col.type==='email'">
        <app-mail [email]="item[col.id]"></app-mail>
      </ng-container>
      <ng-container *ngIf="col.type==='initials'">
        <app-initials [name]="item[col.id]"></app-initials>
      </ng-container>
      <ng-container *ngIf="col.type==='check'">
        <app-check disabled [value]="item[col.id]" [label]="''"></app-check>
      </ng-container>
      <ng-container *ngIf="col.type==='yes'">
        <span class="badge bg-success" *ngIf="item[col.id]" >Yes</span>
        <span  class="badge bg-secondary" *ngIf="!item[col.id]" >No</span>
      </ng-container>
      <ng-container *ngIf="col.type==='open'">
        <span class="badge bg-success" *ngIf="item[col.id]" >Open</span>
        <span  class="badge bg-danger" *ngIf="!item[col.id]" >Closed</span>
      </ng-container>
      <ng-container *ngIf="col.type==='enabled'">
        <span class="badge bg-success" *ngIf="item[col.id]" >Enabled</span>
        <span class="badge bg-light text-dark" *ngIf="!item[col.id]" ><del>Disabled</del></span>
      </ng-container>
      <ng-container *ngIf="col.type==='percent'">
        {{item[col.id] | percent}}
      </ng-container>
      <ng-container *ngIf="col.type==='percentNoSign'">
        {{item[col.id] * 100 | number: '1.0-0'}}
      </ng-container>
      <ng-container *ngIf="col.type==='onlydate'">
        {{item[col.id] | date: 'MMM d'}}
      </ng-container>
      <ng-container *ngIf="col.type==='date'">
        {{item[col.id] | date:'medium'}}
      </ng-container>
      <ng-container *ngIf="col.type==='longdate'">
        {{item[col.id] | date:'mediumDate'}}
      </ng-container>
      <ng-container *ngIf="col.type==='shorttime'">
        {{item[col.id] | date:'shortTime'}}
      </ng-container>
      <ng-container *ngIf="col.type==='currency'">
        {{item[col.id] | currency}}
      </ng-container>
      <ng-container *ngIf="col.type==='projectlink'">
        <a style="white-space: nowrap;" routerLink='/projects/view/{{item["id"]}}'>{{item[col.id]}}</a>
      </ng-container>
      <ng-container *ngIf="col.type==='toggle'">
        <div class="form-check form-check-inline form-switch float-end">
          <input class="form-check-input" type="checkbox" [(ngModel)]="item[col.id]" (change)="actionSelected(item,'toggle')"/>
        </div>
      </ng-container>
      <ng-container *ngIf="col.type==='button'">
        <button type="button" class="btn btn-success" (click)="actionSelected(item, col.id)">{{col.label}}</button>
      </ng-container>
    </td>
    <td *ngFor="let link of links">

    </td>

    <td *ngFor="let action of actions">
      <button (click)="actionSelected(item,action.type)" [ngClass]="'btn btn-link' + action.icon" >{{action.label}}</button>
    </td>
  </tr>
  </tbody>
</table>
</div>
