import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ComplianceReport } from 'src/app/_models/compliance-report';
import { NubbleService } from 'src/app/_services/nubble.service';

@Component({
  selector: 'app-rm-compliance-report',
  templateUrl: './rm-compliance-report.component.html',
  styleUrls: ['./rm-compliance-report.component.css']
})
export class RmComplianceReportComponent implements OnInit {

  @Input() userId;
  complianceReportList: ComplianceReport[]=[];

  isLoading: boolean = false;
  isLoaded: boolean = false;

  cardTitle: string = 'Compliance Reports';

  layout:any[] =[

    {label:"Date",id:"received_date",type:"onlydate"},
    {label:"Compliance",id:"compliance",type:""},
    {label:"Make",id:"device_manufacturer",type:""},
    {label:"Model",id:"device_model",type:""},
    {label:"Enabled",id:"accessibility_enabled",type:""},
    {label:"Running",id:"accessibility_service_running",type:""}
  ];

  @Output() action = new EventEmitter();

  constructor(private nubbleService: NubbleService, private toastr: ToastrService) { }

  ngOnInit() {}

  listComplianceReports() {
    this.nubbleService.userListComplianceReports(this.userId).subscribe(data=>{
      this.complianceReportList = data;
      }, error =>{
        this.isLoaded = true;
        this.isLoading = false;
        this.toastr.warning(error);
      }, () => {
      })

      this.isLoaded = true;
      this.isLoading = false;
      this.actionSendKPIData();
  }

  actionSendKPIData() {
    this.action.emit(this.complianceReportList);
  }
}
