<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item"><a href="/clients">Clients</a></li>
    <li *ngIf="!isAddMode" class="breadcrumb-item active" aria-current="page">Edit Client</li>
    <li *ngIf="isAddMode" class="breadcrumb-item active" aria-current="page">Add Client</li>
  </ol>
</nav>

<form [formGroup]="form" (ngSubmit)="onSubmit()" class="mb-4">
  <div class="form-row">

    <input type="hidden" formControlName="id" class="form-control" />
    <div class="card">

      <div class="card-body">

        <div class="row">
          <div class="col-md-10 col-xl-12">
            <div class="form-group">
              <label for="firstName">Name</label>
              <input type="text" formControlName="name" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">Name is required</div>
              </div>
            </div>
          </div>

          <div class="col-md-2 col-xl-12">
            <div class="form-group">
              <label for="lastName">CX</label>
              <input type="text" formControlName="cx" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.cx.errors }" />
              <div *ngIf="submitted && f.cx.errors" class="invalid-feedback">
                <div *ngIf="f.cx.errors.required">CX is required</div>
              </div>
            </div>
          </div>

          <div class="col-md-2 col-xl-2">
            <div class="form-group">
              <label class="lead" for="salesRep">Sales Rep</label>
              <select class="form-select" formControlName="salesRep"
                [ngClass]="{ 'is-invalid': submitted && f.salesRep.errors }">
                <option [selected]="true" value="">Choose Sales Rep</option>
                <option *ngFor="let user of users" [value]="user.value">
                  {{ user.option }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-group col">
          <label for="notes">Notes</label>
          <textarea rows="7" id="notes" autosize formControlName="notes" class="form-control ps-2 pe-2" type="text"></textarea>
        </div>

      </div>
      <div class="card-footer">
        <div class="form-group float-end">
          <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Save
          </button>
          <a routerLink="/clients" class="btn btn-link">Cancel</a>
        </div>
      </div>
    </div>
  </div>
</form>

<app-client-users *ngIf="!isAddMode" [clientId]="id"></app-client-users>