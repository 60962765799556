import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { FraudService } from 'src/app/_services/fraud.service';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { InstanceService } from 'src/app/_services/instance.service';
import { AuthService } from 'src/app/_services/auth.service';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-fraud-flags-list-users',
  templateUrl: './fraud-flags-list-users.component.html',
  styleUrls: ['./fraud-flags-list-users.component.scss']
})
export class FraudFlagsListUsersComponent implements OnInit {

  instance: any;
  instanceIsLoaded: boolean = false;

  cardTitle: string = 'Instance Fraud Settings';

  users: any[];

  selectedUsers: any[] = [];
  selectedFilters: any[] = [];

  statusFilters: any[] = [];

  statuses: any[];

  loading: boolean = true;

  reviewStatusFilters: any[];

  constructor(private fraudService: FraudService, private primengConfig: PrimeNGConfig, public datePipe: DatePipe,
              private messageService: MessageService, private confirmationService: ConfirmationService,
              private instanceService: InstanceService, private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.fraudService.listUsersFraudReview().subscribe(data => {
        this.users = data;

        this.users.forEach((user, index) => {
          this.statusHandler(user, index);
          user.flaggedOn = new Date(user.flaggedOn);
          }
        );

        this.loading = false;
    });

    this.getInstance();

    this.reviewStatusFilters = ['Needs Reviewing', 'Suspected of Fraud', 'Permanently Banned'];

    this.statusFilters = ['Okay', 'In Breach', 'Excluded'];

    this.primengConfig.ripple = true;
  }

  getInstance() {
    this.instanceService.getInstance(this.authService.user?.userDetails?.instanceId.toString()).subscribe(
      (data) => {
      this.instance = data;
    },
      (error) => {
        //this.toastr.error('Failed to fetch instance');
      },
      () => {
        this.instanceIsLoaded = true;
      }
    );
  }

  fraudSettingsTextMutation(fraudLocationCheckEnabled: boolean) {

    if (fraudLocationCheckEnabled == true) {
      return 'Enabled';
    }
    else (fraudLocationCheckEnabled == false)
    {
      return 'Disabled';
    }
  }

  editReviewStatusSelectedUsers(choice: string) {

    if (choice == 'markOkay') {

      this.confirmationService.confirm({
        message: 'Are you sure you want to mark the selected users as okay?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {

          var markUsersOkay = {userIds: this.selectedUsers.map(function(a) {return a.userId;}), permanentlyBanned: 0, suspectedOfFraud: false, needsReviewing: 0, setCooling: true};

          this.editUsersReviewStatus(markUsersOkay, 1);

          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Users Marked Okay', life: 3000});
        }
      });
    }

    if (choice == 'markSuspectedOfFraud') {

      this.confirmationService.confirm({
        message: 'Are you sure you want mark the selected users as suspected of fraud?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {

          var markUsersSuspectedOfFraud = {userIds: this.selectedUsers.map(function(a) {return a.userId;}), permanentlyBanned: 0, suspectedOfFraud: true, needsReviewing: 0, setCooling: false};

          this.editUsersReviewStatus(markUsersSuspectedOfFraud, 2);

          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Users Marked Suspected of Fraud', life: 3000});
        }
      });
    }

    if (choice == 'markPermanentlyBanned') {

      this.confirmationService.confirm({
        message: 'Are you sure you want to permanently ban the selected users?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {

          var markUsersPermanentlyBanned = {userIds: this.selectedUsers.map(function(a) {return a.userId;}), permanentlyBanned: 1, suspectedOfFraud: true, needsReviewing: 0, setCooling: false};

          this.editUsersReviewStatus(markUsersPermanentlyBanned, 3);

          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Users Permanently Banned', life: 3000});
        }
      });
    }
  }

  editUsersReviewStatus(usersForReviewEdit: any, btnChoice: number) {

    this.loading = true;

    this.fraudService.updateUserFraudStatus(usersForReviewEdit).subscribe(data => {
    }, error =>{
    }, () => {
        //complete

        if (btnChoice == 1) {

          this.users = this.users.filter(val => !this.selectedUsers.includes(val));
        }

        if (btnChoice == 2) {

          this.selectedUsers.forEach(val =>
            {
              const index = this.users.map(e => e.userId).indexOf(val.userId);

              this.users[index].fraudStatus = 'Suspected of Fraud';
              this.users[index].needsReviewing = 0;
              this.users[index].suspectedOfFraud = true;
              this.users[index].permanentlyBanned = 0
              this.users[index].coolingOffDeadline = 0
            });

          this.selectedUsers = [];
        }

        if (btnChoice == 3) {

          this.selectedUsers.forEach(val =>
            {
              const index = this.users.map(e => e.userId).indexOf(val.userId);

              this.users[index].fraudStatus = 'Permanently Banned';
              this.users[index].needsReviewing = 0;
              this.users[index].suspectedOfFraud = true;
              this.users[index].permanentlyBanned = 1
              this.users[index].coolingOffDeadline = 0
            });
        }

        this.loading = false;
    })
  }

  statusHandler(user: any, i: number) {

    this.setStatus(user.fraudBattery, user.excludeFromFraudBatteryCheck, i, 'fraudBatteryStatus');
    this.setStatus(user.fraudLocation, user.excludeFromFraudLocationCheck, i, 'fraudLocationStatus');
    this.setStatus(user.fraudSurvey, user.excludeFromFraudSurveyCheck, i, 'fraudSurveyStatus');
    this.setStatus(user.fraudHome, user.excludeFromFraudHomeCheck, i, 'fraudHomeStatus');
    this.setStatus(user.fraudApp, user.excludeFromFraudAppsCheck, i, 'fraudAppsStatus');
    this.setStatus(user.fraudEmailDomain, user.excludeFromFraudEmailDomainCheck, i, 'fraudEmailDomainStatus');
    this.setStatus(user.fraudMultipleEmails, user.excludeFromFraudMultipleEmailsCheck, i, 'fraudMultipleEmailsStatus');
    this.setStatus(user.fraudPhoneNumber, user.excludeFromFraudPhoneNumberCheck, i, 'fraudPhoneNumberStatus');
    this.setStatus(user.fraudPhoneManufacturer, user.excludeFromFraudPhoneManufacturerCheck, i, 'fraudPhoneManufacturerStatus');
    this.setStatus(user.fraudOnboarding, false, i, 'fraudOnboardingStatus');
  }

  setStatus(value: boolean, isExcluded: boolean, i: number, statusProp: string) {

    if (value == false && isExcluded == false) {
      this.users[i][statusProp] = 'Okay';
    }
    else if (value == true  && isExcluded == false) {
      this.users[i][statusProp] = 'In Breach';
    }
    else if (value == false && isExcluded == true) {
      this.users[i][statusProp] = 'Excluded';
    }
    else if (value == true && isExcluded == true) {
      this.users[i][statusProp] = 'Excluded';
    }
  }

  getStyle(status: string) {

    if (status == 'Okay') {
      return 'text-success';
    }
    else if (status == 'In Breach')
    {
      return 'text-warning';
    }
    else (status == 'Excluded')
      return 'text-muted';
  }

  openUserDetailsInNewWindow(userid: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/reviewuser/${userid}`])
    );

    window.open(url, '_blank');
  }
}
