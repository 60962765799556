<div class="row">

  <!-- Marker Type -->
  <!-- <div class="col-2">

    <div class="form-group">
      <label for="table">Marker Table</label>
      <select
        class="form-control"
        id="table"
        required
        [(ngModel)]="selectedTable"
        (change)="setAttributes()"
      >
        <option *ngFor="let pow of markerTypeSelection"
          [value]="pow"
          [selected]="pow"
        >{{pow}}
        </option>
      </select>
    </div>
  </div> -->

  <!-- Combine all in HTML -->
    <!-- <ng-container *ngIf="selectedTable==='visits'"> -->

      <div class="col">
        <div class="row">
          <div class="col-3">
            <div class="container">
              <div class="row">
                <div class="col">
                  <input type="hidden" />
                  <ng-multiselect-dropdown
                    [placeholder]="'Visits Categories 1'"
                    [settings]="dropdownSettings"
                    [data]="dropdownVisitsList1"
                    [(ngModel)]="selectedItems1"
                  >
                </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>

          <div class="col-3">
            <div class="container">
              <div class="row">
                <div class="col">
                  <input type="hidden" />
                  <ng-multiselect-dropdown
                    [placeholder]="'Visits Categories 2'"
                    [settings]="dropdownSettings"
                    [data]="dropdownVisitsList2"
                    [(ngModel)]="selectedItems2"
                  >
                </ng-multiselect-dropdown>
                </div>
              </div>
            </div>

          </div>

          <div class="col-3">
            <div class="container">
              <div class="row">
                <div class="col">
                  <input type="hidden" />
                  <ng-multiselect-dropdown
                    [placeholder]="'Visits Categories 3'"
                    [settings]="dropdownSettings"
                    [data]="dropdownVisitsList3"
                    [(ngModel)]="selectedItems3"
                  >
                </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>

       <div class="row">
          <div class="col-3">
            <div class="container">
              <div class="row">
                <div class="col">
                  <input type="hidden" />
                  <ng-multiselect-dropdown
                    [placeholder]="'MediaPlayers Categories 1'"
                    [settings]="dropdownSettings"
                    [data]="dropdownMediaPlayerCatList"
                  >
                </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>

          <div class="col-3">
            <div class="container">
              <div class="row">
                <div class="col">
                  <input type="hidden" />
                  <ng-multiselect-dropdown
                    [placeholder]="'MediaPlayers Categories 2'"
                    [settings]="dropdownSettings"
                    [data]="dropdownMediaPlayerList"
                  >
                </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-3">
            <div class="container">
              <div class="row">
                <div class="col">
                  <input type="hidden" />
                  <ng-multiselect-dropdown
                    [placeholder]="'Shopper Categories 1'"
                    [settings]="dropdownSettings"
                    [data]="dropdownShopperCategoriesList"
                  >
                </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>

          <div class="col-3">
            <div class="container">
              <div class="row">
                <div class="col">
                  <input type="hidden" />
                  <ng-multiselect-dropdown
                    [placeholder]="'Shopper Categories 2'"
                    [settings]="dropdownSettings"
                    [data]="dropdownShopperRetailersList"
                  >
                </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-3">
            <div class="container">
              <div class="row">
                <div class="col">
                  <input type="hidden" />
                  <ng-multiselect-dropdown
                    [placeholder]="'Social Categories 1'"
                    [settings]="dropdownSettings"
                    [data]="dropdownSocialNetworksList"
                  >
                </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>

          <div class="col-3">
            <div class="container">
              <div class="row">
                <div class="col">
                  <input type="hidden" />
                  <ng-multiselect-dropdown
                    [placeholder]="'Social Categories 2'"
                    [settings]="dropdownSettings"
                    [data]="dropdownSocialAdvertisersList"
                  >
                </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-3">
            <div class="container">
              <div class="row">
                <div class="col">
                  <input type="hidden" />
                  <ng-multiselect-dropdown
                    [placeholder]="'App Names Categories'"
                    [settings]="dropdownSettings"
                    [data]="dropdownAppNamesList"
                  >
                </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>

          <div class="col-3">
            <div class="container">
              <div class="row">
                <div class="col">
                  <input type="hidden" />
                  <ng-multiselect-dropdown
                    [placeholder]="'Chain Categories'"
                    [settings]="dropdownSettings"
                    [data]="dropdownChainsList"
                  >
                </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>

          <div class="col-3">
            <div class="container">
              <div class="row">
                <div class="col">
                  <input type="hidden" />
                  <ng-multiselect-dropdown
                    [placeholder]="'Web Domains Categories'"
                    [settings]="dropdownSettings"
                    [data]="dropdownDomainsList"
                  >
                </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>

          <div class="col-3">
            <div class="container">
              <div class="row">
                <div class="col">
                  <input type="hidden" />
                  <ng-multiselect-dropdown
                    [placeholder]="'States Categories'"
                    [settings]="dropdownSettings"
                    [data]="dropdownStatesList"
                  >
                </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
     </div>
</div>
