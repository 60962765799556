import { ClientService } from '../../_services/client.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {
  layout: any[] = [{ label: 'Name', id: 'name', type: '' }];

  actions: any[] = [{ label: 'Edit', icon: '', type: 'edit' }];

  items: any[] = [];

  constructor(
    private clientService: ClientService,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getClients();
  }

  handleAction(action: any) {
    if (action.type === 'edit') {
      this.router.navigate(['/clients/edit/' + action.row.id]);
    } else if (action.type === 'add') {
      this.router.navigate(['/clients/add']);
    }
  }

  getClients() {
    this.clientService.list().subscribe(
      (data) => {
        this.items = data;
      },
      (error) => {
        this.toastr.error('Failed to fetch clients');
      },
      () => {},
    );
  }
}
