<!-- TODO: Add the following release note to next release:
 
      <li>
        Test data now only shows when the project is in test status. When project moves to live, 
        test data is no longer shown in the project performance section.
      </li>
-->


<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item active" aria-current="page">Release Notes</li>
  </ol>
</nav>

<div class="row">
  <div class="col text-center">
    <h1>DASH Release Notes</h1>
  </div>
</div>

<!-- REMINDER: Share these with Katherine a day or two before the release when possible. -->
 
<div class="row mt-5">
  <div class="col left-col">
    <h3 class="">Release 2024.Q4.4</h3>
  </div>
  <div class="col-8">
    <h3>Misc Improvements</h3>
    <ul>
      <li>Setup redirect hashing for PureSpectrum</li>
    </ul>
    <h3 class="mt-5">Misc Fixes</h3>
    <ul>
      <li>Data download filter issue for same starting and ending date</li>
      <li>Issue with switching video sources in Media Recorder on some Android devices</li>
    </ul>
  </div>
</div>

<div class="row mt-5">
  <div class="col left-col">
    <h3 class="">Release 2024.Q4.3</h3>
  </div>
  <div class="col-8">
    <p>
      Misc fixes and improvements to Dash+ language translation and media recorder.
    </p>
  </div>
</div>

<div class="row mt-5">
  <div class="col left-col">
    <h3 class="">Release 2024.Q4.2 🇫🇷 🇩🇪 🇲🇽</h3>
  </div>
  <div class="col-8">
    <p>
      Dash+ now supports automatic video/audio/text transcript translation to
      English from other supported languages.
    </p>
  </div>
</div>

<div class="row mt-5">
  <div class="col left-col">
    <h3 class="">Release 2024.Q4.1 🖥️</h3>
  </div>
  <div class="col-8">
    <p>
      The first release of Q4 includes some notable features and improvements to
      the PMO and Dash+.
    </p>

    <h3 class="mt-5">Dark Mode!</h3>
    <img
      src="./assets/release-notes/2024-Q4-1/PMO-dark-mode.png"
      class="img-fluid"
      alt="Dark mode screenshot" />
    <p class="mt-2">
      The PMO now supports dark mode! To enable it, flip the toggle in the upper
      right menu.
    </p>

    <h3 class="mt-5">Participant Export Filters</h3>
    <img
      src="./assets/release-notes/2024-Q4-1/PMO-participant-export-filters.png"
      class="img-fluid"
      alt="Participant export screenshot" />
    <p class="mt-2">
      The PMO participant export feature now allows you to filter the data to
      export to CSV. You can filter by date range, statuses, and vendors.
    </p>

    <h3 class="mt-5">Dash+ Data Upload</h3>
    <img
      src="./assets/release-notes/2024-Q4-1/Dash-plus-uploader.png"
      class="img-fluid"
      alt="Dash+ upload screenshot" />
    <p class="mt-2">
      Dash+ now allows you to upload a CSV file of participation data for
      processing and display in Dash+. If a client collected open text responses
      outside of Dash+, you can now import them for analysis after the fact.
    </p>

    <h3 class="mt-5">Tremendous Bulk Approval</h3>
    <img
      src="./assets/release-notes/2024-Q4-1/Tremendous-bulk-selection.png"
      class="img-fluid"
      alt="Tremendous bulk selection screenshot" />
    <img
      src="./assets/release-notes/2024-Q4-1/Tremendous-bulk-payout.png"
      class="img-fluid"
      alt="Tremendous bulk payout screenshot" />
    <p class="mt-2">
      Tremendous incentive payouts can now be approved in bulk to save time.
    </p>

    <h3 class="mt-5">Dash+ Tech Check Disabled Response Options</h3>
    <img
      src="./assets/release-notes/2024-Q4-1/Media-Recorder-failed-tech-check.png"
      class="img-fluid"
      alt="Tech Check failed screenshot" />
    <img
      src="./assets/release-notes/2024-Q4-1/Media-Recorder-reduced-options.png"
      class="img-fluid"
      alt="Tech Check reduced screenshot" />
    <p class="mt-2">
      If the Dash+ Tech Check fails, the related response options are
      automatically hidden. For example, if the open end question is configured
      to allow the user to choose video, audio, or text for their response type
      but the participant does not pass the video tech check, they will only see
      audio or text for response options.
    </p>

    <h3 class="mt-5">Default Sales Rep for Client</h3>
    <img
      src="./assets/release-notes/2024-Q4-1/PMO-client-sales-rep.png"
      class="img-fluid"
      alt="Default sales rep screenshot" />
    <p class="mt-2">
      A sales rep can now be assigned to a client. When a new project for the
      client is created, it will default to that sales rep.
    </p>

    <h3 class="mt-5">51 Degrees Integration</h3>
    <img
      src="./assets/release-notes/2024-Q4-1/51D-logo.png"
      class="img-fluid"
      alt="51Degrees Logo" />
    <p class="mt-2">
      We will start collecting detailed device information for participants that
      will later be used to identify and track fraudsters.
    </p>

    <h3 class="mt-5">Misc Improvements</h3>
    <ul>
      <li>Cloned projects now open in a new tab.</li>
      <li>
        Dash+ Open Text responses do not allow pasting. This will discourage
        participants from using AI-generated responses.
      </li>
      <li>Improved relevancy check accuracy in Dash+</li>
      <li>
        surveys.dashmr.com now runs on the latest version of Microsoft .Net.
      </li>
    </ul>

    <h3 class="mt-5">Misc Fixes</h3>
    <ul>
      <li>
        When adding a new segment to an existing project in PMO, the segment
        status will no longer be overridden by the project status.
      </li>
      <li>Dash+ Tech Check now supports Decipher test mode</li>
      <li>Fixed Dash+ performance and paging for larger studies</li>
      <li>Fixed formatting issues with demographics in the PMO</li>
      <li>Fixed link to Release Notes page</li>
    </ul>
  </div>
</div>

<div class="row mt-5">
  <div class="col left-col">
    <h3 class="">2024-09-19 Release 📲</h3>
  </div>
  <div class="col-8">
    <h3>Amazon Pinpoint Phone Validation</h3>
    <img
      src="./assets/release-notes/2024-09-19/Phonenumbervalidation_featuresimage.png"
      class="img-fluid"
      alt="Pinpoint screenshot" />
    <p class="mt-2">
      We integrated with Amazon Pinpoint to run an additional check when PII
      Phone Validation is enabled. Besides verifying ownership by sending the
      number a one-time passcode &lpar;OTP&rpar;, we now also make a call to the
      Pinpoint API to check that the phone number is not a Voice Over IP
      &lpar;VoIP&rpar; or otherwise invalid number. This should prevent users
      from utilizing phone numbers from Google Voice, Zoom, or other VoIP-based
      services.
    </p>
  </div>
</div>

<div class="row mt-5">
  <div class="col left-col">
    <h3 class="">2024-08-31 Release 🎉</h3>
  </div>
  <div class="col-8 ps-5">
    <p>
      The August release includes a lot of changes to DASH+, the Media Recorder,
      the DASHboard, and the Link Processor (survey.dashmr.com).
    </p>

    <h3 class="mt-5">Tremendous Integration</h3>
    <img
      src="./assets/release-notes/2024-08-31/tremendous-dashboard.png"
      class="img-fluid"
      alt="Tremendous screenshot" />
    <p>
      The team has been hard at work building an integration with Tremendous
      that allows staff and client users to approve and create incentive payouts
      from the Dash+ UI. This is a preview release as there are still a few
      features that we're still working on, like bulk approvals.
    </p>
    <img
      src="./assets/release-notes/2024-08-31/tremendous-opt-in-screenshot.png"
      class="img-fluid"
      alt="Tremendous screenshot" />
    <p>
      Survey participants will be able to provide a different email address for
      incentives or they can opt out.
    </p>

    <h3 class="mt-5">Media Recorder Tech Check</h3>
    <img
      src="./assets/release-notes/2024-08-31/tech-check-screenshot.png"
      class="img-fluid"
      alt="Media Recorder Tech Check screenshot" />
    <p>
      The Dash+ Media Recorder now supports adding a "Tech Check" as a separate
      step that precedes video or audio open-ended questions. The tech check
      will allow the user to adjust their video and audio settings, preview
      their video, test their ability to record audio, and verify their
      connection speed is fast enough for multimedia uploads. For now, the tech
      check only sets Decipher variables that the survey programmer can use to
      react accordingly.
    </p>

    <h3 class="mt-5">DASH+ Multiple Client Access</h3>
    <img
      src="./assets/release-notes/2024-08-31/client-switcher-screenshot.png"
      class="img-fluid"
      alt="Client switcher screenshot" />
    <p>
      Previously, Dash staff had to use a workaround to add themselves to
      multiple DASH+ client projects. Now, you can use the same email address
      across clients and DASH+ will show an option to switch clients in the
      upper right.
    </p>

    <h3 class="mt-5">DASH+ Chart Exports</h3>
    <p>
      You can now generate a printable PDF of certain DASH+ page with visuals,
      like the project and question overview pages. You can access this feature
      under the "More" menu.
    </p>

    <h3 class="mt-5">DSH Salesforce Field</h3>
    <p>
      The original version of the PMO generated a "DMR-" code that was never
      widely used. Instead, the Salesforce DSH number was used and typically
      added to the project title since there was no other place for it. Starting
      with this release, there is a separate field for the Salesforce number,
      and it no longer needs to be added to the title. The DMR number is no
      longer shown. We ran a script to move DSH numbers from the title to the
      new field. There may be a few cases where it was not able to move it
      automatically. Worth noting, if the DSH-XXXX was the only text in the
      title, we left it in the title.
    </p>
    <p>
      The Salesforce field won't be required on projects with a "Test" status
      but will be required once the project goes live.
    </p>

    <h3 class="mt-5">Release Notes</h3>
    <p>
      When new releases are made, this page will be updated with a quick summary
      of the new features, improvements, and fixes. A badge will show in the
      upper right to draw your attention to it. To access this page after the
      badge disappears, you can click on the username menu and click on the
      "Release Notes" link.
    </p>

    <h3 class="mt-5">Misc Improvements</h3>
    <ul>
      <li>
        When a response in DASH+ passes the relevancy check, we now
        automatically approve the response.
      </li>
      <li>
        The support email link at the bottom of survey.dashmr.com now
        automatically populates the subject and the body with helpful
        information, including the participant id.
      </li>
      <li>
        Added a short delay with countdown UI before the DASH+ Media Recorder
        begins recording video.
      </li>
      <li>
        Improved the UX when a user accidentally denies access to their video or
        audio device in the Media Recorder
      </li>
      <li>
        Key phrases, entities, the block type, and the transcript in VTT format
        is now included in the DASH+ export file.
      </li>
      <li>
        Created an automated evaluation framework for assessing changes to the
        prompts used with the LLM
      </li>
      <li>
        Demographic dropdown lists in the PMO can be bulk selected with the new
        "Select All" option
      </li>
      <li>The PII country was added to the PMO participant export</li>
      <li>
        The supplier API returns the Salesforce ID instead of the project title
      </li>
      <li>
        The PMO project performance section now shows the timestamp of the last
        complete.
      </li>
      <li>Upgraded the .Net version for the PMO API</li>
    </ul>

    <h3 class="mt-5">Misc Fixes</h3>
    <ul>
      <li>
        Various fixes to the real-time text transcription in the Media Recorder
      </li>
      <li>
        Fixed issues with Bluetooth headsets used to record audio in DASH+
      </li>
      <li>
        Adjusted the DASH+ code generator for the example Decipher sample source
      </li>
      <li>Fixed error when deleting a DASH+ client user</li>
      <li>
        Fixed issues with the Media Recorder when using Decipher in test mode
        without a valid SPID
      </li>
      <li>
        Disallowed changing the client on a PMO project once the project has
        traffic
      </li>
      <li>
        The "QC: " label in the project overview correctly shows the fraud type.
      </li>
      <li>Admins can now delete PMO users</li>
      <li>Fixed JavaScript error with the PII email field</li>
      <li>
        Fixed an edge case issue with sending the project auto pause
        notification email if a user had two user records in the PMO
      </li>
    </ul>
  </div>
</div>

<!-- 
  Feature
  Improvement
  Fix
   -->

<!-- 
  DASH+
  DASHboard
  DASH Surveys 
   -->
