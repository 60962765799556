import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Note} from "../_models/note";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProjectNoteService {
  constructor(
    private http: HttpClient
  ) {}

  list(projectId:string) {
    return this.http.get<Note[]>(`${environment.apiUrl}/api/project/` + projectId + `/note`)
  }

  add(model: Note) {
    return this.http.post(`${environment.apiUrl}/api/project/` + model.projectId + `/note`, model)
  }

  update(model: Note) {
    return this.http.put(`${environment.apiUrl}/api/project/` + model.projectId + `/note`, model)
  }

  delete(model: Note) {
    return this.http.delete(`${environment.apiUrl}/api/project/` + model.projectId + `/note/` + model.sortKey)
  }
}
