import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NubbleService } from 'src/app/_services/nubble.service';

@Component({
  selector: 'app-user-app-history-list',
  templateUrl: './user-app-history-list.component.html',
  styleUrls: ['./user-app-history-list.component.css']
})
export class UserAppHistoryListComponent implements OnInit {

  @Input() userId: string;

  appHistoryList: any[] = [];

  isLoading: boolean = false;
  isLoaded: boolean = false;

  cardTitle: string = 'App Visits List';

  layout: any[] = [
    { label: 'App Name', id: 'appName', type: 'text' },
    { label: 'Visits', id: 'visitCount', type: 'text' }
  ];

  constructor(private nubbleService: NubbleService, private toastr: ToastrService) { }

  ngOnInit() {}

  listAppHistory() {
    this.isLoading = true;
    this.nubbleService.userListAppHistory(this.userId).subscribe((data) => {
        this.appHistoryList = data;
      },
      (error) => {
        this.isLoaded = true;
        this.isLoading = false;
        this.toastr.warning(error);
      },
      () => {
        this.isLoaded = true;
        this.isLoading = false;
      }
    );
  }
}
