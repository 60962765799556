import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  Component,
  OnInit,
  Input,
  forwardRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import * as Papa from 'papaparse';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-unique-ids',
  templateUrl: './unique-ids.component.html',
  styleUrls: ['./unique-ids.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UniqueIdsComponent),
    },
  ],
})
export class UniqueIdsComponent implements OnInit, ControlValueAccessor {
  @Input() numberOfIds: number = 0;
  @Input() sampleIds: string = '';
  @Input() isEdit: boolean = false;
  file: any;
  dataList: string[] = [];
  @ViewChild('myInput')
  fileInputVariable: ElementRef;

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor(private toastr: ToastrService) {}

  writeValue(obj: any[]): void {
    this.dataList = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit() {}

  showSampleIds(event: any) {
    event.preventDefault();
    this.toastr.info(this.sampleIds, 'Sample of Ids in file', {
      closeButton: true,
      timeOut: 6000,
      progressBar: true,
      enableHtml: true,
    });
  }

  handleFileInput(event: any) {

    this.file = event.target.files.item(0);

    //console.log(this.file);

    Papa.parse(this.file, {
      header: false,
      skipEmptyLines: true,
      complete: (result, file) => {
        this.dataList = result.data.map((x) => x[0]);

        if (this.dataList.length > environment.maxUids) {
          this.toastr.warning('Max number of ids is '+environment.maxUids);
          this.fileInputVariable.nativeElement.value = '';
          //Clear fileinput
        } else {

          this.onChange(this.dataList);
          this.numberOfIds = result.data.length;
          let newList = this.dataList.slice();
          if (this.numberOfIds < 6) {
            this.sampleIds = newList
              .splice(0, newList.length)
              .join(' </br> </br>');
          } else {
            this.sampleIds = newList.splice(0, 5).join('  </br> </br>');
          }
        }
      },
    });
  }
}
