import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InboxService } from 'src/app/_services/inbox.service';

@Component({
  selector: 'app-list-inbox-messages',
  templateUrl: './list-inbox-messages.component.html',
  styleUrls: ['./list-inbox-messages.component.css']
})

export class ListInboxMessagesComponent implements OnInit {

  layout: any[] = [
    { label: 'Title', id: 'messageHeader', type: '' },
    { label: 'Message', id: 'messageText', type: '' },
    { label: 'Enable Target VIDs', id: 'enableTargetVIDs', type: '' },
    { label: 'Send Message', id: 'sendNotificationBtn', type: 'button' },
    { label: 'Send Email', id: 'sendEmailBtn', type: 'button' },
  ];

  actions: any[] = [
    //{ label: 'Send Notification', icon: '', type: 'xxx' },
    { label: 'Edit', icon: '', type: 'edit' },
    { label: 'Delete', icon: '', type: 'delete' },
  ];

  items: any[] = [];

  constructor(
    private inboxService: InboxService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getMessages();
  }

  handleAction(action: any) {

    if (action.type === 'add') {
      this.router.navigate(['/createmessage']);
    }
    else if (action.type === 'edit') {
      this.router.navigate(['/editmessage/'+action.row.messageId]);
    }
    else if (action.type === 'sendNotificationBtn') {
      this.inboxService.sendNotificationMessage(action.row.messageId).subscribe(
        (data) => {},
        (error) => {
          this.toastr.error('Failed to send Notification');
        },
        () => {
          this.getMessages();
          this.toastr.success('Notification Sent');
        }
      );
    }

    else if (action.type === 'sendEmailBtn') {
      this.inboxService.sendEmailMessage(action.row.messageId).subscribe(
        (data) => {},
        (error) => {
          this.toastr.error('Failed to send Email');
        },
        () => {
          this.getMessages();
          this.toastr.success('Email Sent');
        }
      );
    }

    else if (action.type === 'delete') {
      this.inboxService.deleteInboxMessage(action.row.messageId).subscribe(
        (data) => {},
        (error) => {
          this.toastr.error('Failed to delete Message');
        },
        () => {
          this.getMessages();
          this.toastr.success('Deleted Message');
        }
      );
    }
  }

  getMessages() {
    this.inboxService.listInboxMessages().subscribe(
      (data) => {
        this.items = data;
      },
      (error) => {
        this.toastr.error('Failed to fetch Messages');
      },
      () => {
        //this.toastr.success('Fetched Messages');
      }
    );
  }
}
