import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DropDownService } from 'src/app/_services/drop-down.service';

@Component({
  selector: 'app-test-dropdowns',
  templateUrl: './test-dropdowns.component.html',
  styleUrls: ['./test-dropdowns.component.css']
})
export class TestDropdownsComponent implements OnInit {

  selectedTable = '';
  dropdownSettings: IDropdownSettings;
  dropdownVisitsList1: any = [];
  dropdownVisitsList2: any = [];
  dropdownVisitsList3: any = [];

  dropdownMediaPlayerList: any = [];
  dropdownMediaPlayerCatList: any = [];

  dropdownShopperCategoriesList: any = [];
  dropdownShopperRetailersList: any = [];

  dropdownSocialAdvertisersList: any = [];
  dropdownSocialNetworksList: any = [];

  dropdownAppNamesList: any = [];
  dropdownChainsList: any = [];
  dropdownDomainsList: any = [];
  dropdownStatesList: any = [];

  selectedItems1: any = [];
  selectedItems2: any = [];
  selectedItems3: any = [];

  chainIds: any = [];

  constructor(private dropdownService: DropDownService) { }

  ngOnInit() {

    this.getCategories1();
    this.getCategories2();
    this.getCategories3();

    this.getMediaPlayerList();
    this.getMediaPlayerCategories();

    this.getShopperCategories();
    this.getShopperRetailers();

    this.getSocialAdvertisers();
    this.getSocialNetworks();

    this.getAppNamesList();
    this.getDomainsList();
    this.getChainsList();
    this.getStatesList();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'option',
      textField: 'value',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  markerTypeSelection = ['visits', 'media', 'social', 'shopper']

  setAttributes() {
    if (this.selectedTable == 'visits') {
      this.getCategories1();
      this.getCategories2();
      this.getCategories3();
    }

    if (this.selectedTable == 'media') {
      this.getMediaPlayerList();
      this.getMediaPlayerCategories();
    }

    if (this.selectedTable == 'media') {
      this.getMediaPlayerList();
      this.getMediaPlayerCategories();
    }
  }

  getCategories1() {
    this.dropdownService.categories1List()
      .subscribe(data=>{
        this.dropdownVisitsList1 = data;
      }, error =>{
        //this.toastr.error('Failed to delete ' + $event.row.name);
      }, () => {

        //this.toastr.success('Deleted ' + $event.row.name + ' Marker');
      });
  }

  getCategories2() {
    this.dropdownService.categories2List()
      .subscribe(data=>{
        this.dropdownVisitsList2 = data;
      }, error =>{
        //this.toastr.error('Failed to delete ' + $event.row.name);
      }, () => {

        //this.toastr.success('Deleted ' + $event.row.name + ' Marker');
      });
  }

  getCategories3() {
    this.dropdownService.categories3List()
      .subscribe(data=>{
        this.dropdownVisitsList3 = data;
      }, error =>{
        //this.toastr.error('Failed to delete ' + $event.row.name);
      }, () => {

        //this.toastr.success('Deleted ' + $event.row.name + ' Marker');
      });
  }

  getMediaPlayerList() {
    this.dropdownService.mediaPlayersList()
      .subscribe(data=>{
        this.dropdownMediaPlayerList = data;
      }, error =>{
        //this.toastr.error('Failed to delete ' + $event.row.name);
      }, () => {

        //this.toastr.success('Deleted ' + $event.row.name + ' Marker');
      });
    }

    getMediaPlayerCategories() {
      this.dropdownService.mediaCategoriesList()
        .subscribe(data=>{
          this.dropdownMediaPlayerCatList = data;
        }, error =>{
          //this.toastr.error('Failed to delete ' + $event.row.name);
        }, () => {

          //this.toastr.success('Deleted ' + $event.row.name + ' Marker');
        });
      }

    getShopperCategories() {
      this.dropdownService.shopperCategoriesList()
        .subscribe(data=>{
          this.dropdownShopperCategoriesList = data;
        }, error =>{
          //this.toastr.error('Failed to delete ' + $event.row.name);
        }, () => {

          //this.toastr.success('Deleted ' + $event.row.name + ' Marker');
        });
      }

    getShopperRetailers() {
      this.dropdownService.shopperRetailersList()
        .subscribe(data=>{
          this.dropdownShopperRetailersList = data;
        }, error =>{
          //this.toastr.error('Failed to delete ' + $event.row.name);
        }, () => {

          //this.toastr.success('Deleted ' + $event.row.name + ' Marker');
        });
      }

      getSocialAdvertisers() {
        this.dropdownService.socialAdvertiserList()
          .subscribe(data=>{
            this.dropdownSocialAdvertisersList = data;
          }, error =>{
            //this.toastr.error('Failed to delete ' + $event.row.name);
          }, () => {

            //this.toastr.success('Deleted ' + $event.row.name + ' Marker');
          });
        }

      getSocialNetworks() {
        this.dropdownService.socialNetworkList()
          .subscribe(data=>{
            this.dropdownSocialNetworksList = data;
          }, error =>{
            //this.toastr.error('Failed to delete ' + $event.row.name);
          }, () => {

            //this.toastr.success('Deleted ' + $event.row.name + ' Marker');
          });
        }

        getAppNamesList() {
          this.dropdownService.appnamesList()
          .subscribe(data=>{
            this.dropdownAppNamesList = data;
          }, error =>{
            //this.toastr.error('Failed to delete ' + $event.row.name);
          }, () => {

            //this.toastr.success('Deleted ' + $event.row.name + ' Marker');
          });
        }

        getChainsList() {
          this.dropdownService.chainsList()
          .subscribe(data=>{
            this.dropdownChainsList = data;
          }, error =>{
            //this.toastr.error('Failed to delete ' + $event.row.name);
          }, () => {

            //this.toastr.success('Deleted ' + $event.row.name + ' Marker');
          });
        }

        getDomainsList() {
          this.dropdownService.domainsList()
          .subscribe(data=>{
            this.dropdownDomainsList = data;
          }, error =>{
            //this.toastr.error('Failed to delete ' + $event.row.name);
          }, () => {

            //this.toastr.success('Deleted ' + $event.row.name + ' Marker');
          });
        }

        getStatesList() {
          this.dropdownService.statesList()
          .subscribe(data=>{
            this.dropdownStatesList = data;
          }, error =>{
            //this.toastr.error('Failed to delete ' + $event.row.name);
          }, () => {

            //this.toastr.success('Deleted ' + $event.row.name + ' Marker');
          });
        }
}
