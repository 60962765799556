import { element } from 'protractor';
import { ForgottenPassword, LoginRequest } from './../_models/user';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../_models/user';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment'
import { JwtHelperService } from '@auth0/angular-jwt';
import {Auth}  from "@aws-amplify/auth";
import { AuthenticatorService } from '@aws-amplify/ui-angular';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user: User = {
    token:'',
    userDetails:{
      email:'',
      firstName:'',
      lastName:'',
      role:'',
      roles:[],
      admin:false,
      superAdmin:false,
      dash:false,
      bb:false,
      instanceId:''
    }
  };

loggedIn:boolean=false;
jwtHelper = new JwtHelperService();

constructor(
  public authenticator: AuthenticatorService
  )
  {

  }

  public loadUserDetails() {
  if (this.authenticator.authStatus === 'authenticated') {
    this.user.userDetails.email = this.authenticator.user.attributes?.email!;
    this.user.userDetails.firstName = this.authenticator.user.attributes?.given_name!;
    this.user.userDetails.lastName = this.authenticator.user.attributes?.family_name!;
    this.user.userDetails.instanceId = this.authenticator.user.attributes?.preferred_username!;

    if(this.user.userDetails.instanceId ==='926bcd4a-ce39-4409-804b-f54e08bf8b40' || this.user.userDetails.instanceId ==='c0902152-3d8f-434c-a9ec-70ed698668a9'){
      this.user.userDetails.dash = true;
    }
    if(this.user.userDetails.instanceId ==='c0902152-3d8f-434c-a9ec-70ed698668a9'){
      this.user.userDetails.bb = true;
    }
    var userAccessToken = this.authenticator.user.getSignInUserSession()!;
    this.user.userDetails.roles = userAccessToken.getAccessToken().payload['cognito:groups']

    if(this.user.userDetails.roles.includes('SuperAdmin')) {
      this.user.userDetails.superAdmin =true;
      this.user.userDetails.admin =true;
      this.user.userDetails.role="SuperAdmin";
    }
    else if(this.user.userDetails.roles.includes('Admin')) {
      this.user.userDetails.superAdmin =false;
      this.user.userDetails.admin =true;
      this.user.userDetails.role="Admin";
    }
    else if(this.user.userDetails.roles.includes('Member')) {
        this.user.userDetails.superAdmin =false;
        this.user.userDetails.admin =false;
        this.user.userDetails.role="Member";
    }
    else {
        this.user.userDetails.superAdmin =false;
        this.user.userDetails.admin =false;
        this.user.userDetails.role="Unknown";
    }

    this.loggedIn=true;
  }
    else {
      this.loggedIn = false;
      console.log("User is not authenticated");
    }
  }

  public get userValue(): User {
    return this.user;
  }

  logout() {
    this.authenticator.signOut({ global: true });
  }

  roleMatch(allowedRoles: string[]): boolean{
    let isMatch=false;

    if (this.loggedIn) {
      allowedRoles.forEach(element => {
        if (this.user.userDetails.roles.includes(element)) {
          isMatch = true;
        }
      });

      return isMatch;
    }

    return isMatch;
  }
}
