import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VendorApiKey } from '../_models/vendor-api-key';

@Injectable({
  providedIn: 'root'
})
export class VendorKeyService {

  constructor(
    private http: HttpClient
  ) { }

  list(vendorId: string) {
    return this.http.get<VendorApiKey[]>(`${environment.apiUrl}/api/vendorkey/`+vendorId)
  }

  find(vendorId: string, id: string) {
    return this.http.get<VendorApiKey>(`${environment.apiUrl}/api/vendorkey/`+vendorId+'/'+id)
  }

  add(model: VendorApiKey) {
    return this.http.post(`${environment.apiUrl}/api/vendorkey`,model)
  }

  update(model: VendorApiKey) {
    return this.http.put(`${environment.apiUrl}/api/vendorkey`,model)
  }

  delete(vendorId: string, apiKey: string) {
    return this.http.delete(`${environment.apiUrl}/api/vendorkey/`+vendorId+'/'+apiKey)
  }
}
