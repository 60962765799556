<app-card [cardTitle]="cardTitle">

    <div class="h-100 d-flex justify-content-center align-items-center" *ngIf="!isLoaded">
    <app-loading-icon></app-loading-icon>
    </div>

    <div class="row" *ngIf="isLoaded">
      <div class="container">

        <!-- Only show on full review page -->
        <ng-container class="row" *ngIf="isFullReviewPage">

          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-3">
                  <div class="row float-start">
                    <label class="form-check-label" for="emailVerificationSwitch">Email Verified:</label>
                  </div>
                </div>
                <div class="col-9">
                  <div class="form-check form-switch" (click)="verifyMarkUserEmail($event)">
                    <input class="form-check-input" type="checkbox" id="emailVerificationSwitch" [(ngModel)]="user.personalEmailVerified"/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-3">
                  <div class="row float-start">
                    <label class="form-check-label" for="emailVerificationSwitch">User Disabled:</label>
                  </div>
                </div>
                <div class="col-9">
                  <div class="form-check form-switch" (click)="verifyMarkUserDisabled($event)">
                    <input class="form-check-input" type="checkbox" id="emailVerificationSwitch" [(ngModel)]="user.isDeleted"/>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </ng-container>

        <div class="row" *ngIf="user.userId != ''">
          <div class="col">
            <span>User Id: {{user.userId}} &nbsp;
              <i (click)="copyContent(user?.userId)"
              class="fa fa-copy"
              style="font-size: 12px"
            ></i></span>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <span>Points: <a role="button" class="link-primary" (click)="openAddPointsModal(addPointsModal)">{{user.points}}</a></span>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <span>LocationTrackingEnabled: {{user.locationTrackingEnabled}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span>Has Redeemed (and received): {{user.hasRedeemed}}</span>
          </div>
        </div>

        <div class="row" *ngIf="user.personalEmail != ''">
          <div class="col">
            <span>Email: <a role="button" class="link-primary" (click)="openEditEmailModal(editEmailModal)">{{user.personalEmail}}</a></span>
          </div>
        </div>

        <div class="row" *ngIf="user.phoneNumber != ''">
          <div class="col">
            <span>Phone: <a role="button" class="link-primary" (click)="openEditPhonenumberModal(editPhonenumberModal)">{{user.phoneNumber}}</a></span>
          </div>
        </div>
        <div class="row" *ngIf="user.dateOfBirth != ''">
          <div class="col">
            <span>DOB: <a role="button" class="link-primary" (click)="openEditDateOfBirthModal(editDateOfBirthModalModal)">{{user.dateOfBirth}}</a></span>
          </div>
        </div>
        <div class="row" *ngIf="user.instanceId != ''">
          <div class="col">
            <span>Instance ID: {{user.instanceId}}</span>
          </div>
        </div>
        <div class="row" *ngIf="user.instanceName != ''">
          <div class="col">
            <span>Instance Name: {{user.instanceName}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span>All Demos Answered: {{user.allDemosAnswered}}</span>
          </div>
        </div>
        <div class="row" *ngIf="user.zipCode != ''">
          <div class="col">
            <span>Zip: {{user.zipCode}}</span>
          </div>
        </div>
        <div class="row" *ngIf="user.city != ''">
          <div class="col">
            <span>City: {{user.city}}</span>
          </div>
        </div>
        <div class="row" *ngIf="user.state != ''">
          <div class="col">
            <span>State: {{user.state}}</span>
          </div>
        </div>


        <div class="row" *ngIf="user.gender != ''">
          <div class="col">
            <span>Gender: {{user.gender}}</span>
          </div>
        </div>


        <div class="row" *ngIf="user.ethnicity != ''">
          <div class="col">
            <span>Ethnicity: {{user.ethnicity}}</span>
          </div>
        </div>
        <div class="row" *ngIf="user.education != ''">
          <div class="col">
            <span>Education: {{user.education}}</span>
          </div>
        </div>


        <div class="row" *ngIf="user.familyStatus != ''">
          <div class="col">
            <span>Has Children: {{user.familyStatus}}</span>
          </div>
        </div>


        <div class="row" *ngIf="user.income != ''">
          <div class="col">
            <span>Income: {{user.income}}</span>
          </div>
        </div>

        <div class="row" *ngIf="user.relationshipStatus != ''">
          <div class="col">
            <span>Relationship: {{user.relationshipStatus}}</span>
          </div>
        </div>

        <div class="row" *ngIf="user.hispanic != ''">
          <div class="col">
            <span>Hispanic: {{user.hispanic}}</span>
          </div>
        </div>

        <div class="row" *ngIf="user.employment != ''">
          <div class="col">
            <span>Employment: {{user.employment}}</span>
          </div>
        </div>

        <div class="row" *ngIf="user.descriptions != ''">
          <div class="col">
            <span>Descriptions: {{user.descriptions}}</span>
          </div>
        </div>

        <div class="row" *ngIf="user.referralCode != ''">
          <div class="col">
            <span>Referral Code: {{user.referralCode}}</span>
          </div>
        </div>

        <div class="row" *ngIf="user.campaign != ''">
          <div class="col">
            <span>Campaign: {{user.campaign}}</span>
          </div>
        </div>

          <div class="col">
            <span>Carrier: {{user.carrier}} | CarrierType: <b>{{user.carrierType}}</b> | MobileNetworkCode: {{user.mobileNetworkCode}} | MobileCountryCode: {{user.mobileCountryCode}}</span>
          </div>
      </div>
    </div>
</app-card>

<!-- Edit Email Modal -->
<ng-template #editEmailModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Email</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <input type="email" class="form-control" value="{{user.personalEmail}}" [(ngModel)]="userEditEmail.updatedEmail"/>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close('Close click')">Close</button>
    <button class="btn btn-outline-success" type="button" (click)="onSubmitEditEmail(modal)">
      Edit
    </button>
  </div>
</ng-template>

<!-- Add Points Modal -->
<ng-template #addPointsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add Points</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-4">
        <input type="number" class="form-control" min="0" required [(ngModel)]="pointsToAdd"/>
      </div>

      <div class="col-8">
        <div class="btn-group">
          <button type="button" class="btn btn-outline-secondary dropdown-toggle" style="white-space:normal; width: 140px;" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{selectedAddPointsType}}
          </button>
          <div class="dropdown-menu">
            <div *ngFor="let item of addPointsType">
              <a class="dropdown-item" (click)="setType(item)">{{item}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close('Close click')">Close</button>
    <button class="btn btn-outline-success" type="button" (click)="onSubmitAddPoints(modal)">
      Add
    </button>
  </div>
</ng-template>

<!-- Edit Phonenumber Modal -->
<ng-template #editPhonenumberModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Phonenumber</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <input type="phonenumber" class="form-control" value="{{user.phoneNumber}}" [(ngModel)]="userEditPhonenumber.updatedPhonenumber"/>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close('Close click')">Close</button>
    <button class="btn btn-outline-success" type="button" (click)="onSubmitEditPhonenumber(modal)">
      Edit
    </button>
  </div>
</ng-template>

<!-- Edit Phonenumber Modal -->
<ng-template #editDateOfBirthModalModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Date of Birth</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <input type="date" class="form-control" value="{{user.dateOfBirth}}" [(ngModel)]="userEditDOB.dateOfBirth"/>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close('Close click')">Close</button>
    <button class="btn btn-outline-success" type="button" (click)="onSubmitEditDateOfBirth(modal)">
      Edit
    </button>
  </div>
</ng-template>
