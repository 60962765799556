<app-card [cardTitle]="cardTitle">
  <div class="h-100 d-flex justify-content-center align-items-center" *ngIf="!isLoaded">
    <app-loading-icon></app-loading-icon>
  </div>
  <div class="container vertical-scrollable">
    <div class="row" *ngIf="isLoaded">
      <app-list [showCreateNew]="false" [items]="items" [actions]="actions" [layout]="layout" (action)="handleAction($event, longContent)"></app-list>
    </div>
  </div>
</app-card>

<!-- Modal -->
<ng-template #longContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{modalTitle}}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p [innerHTML]="modalBody | prettyprint">{{modalBody}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-info" (click)="copyToClipboard(modalBody)">Copy</button>
    <button type="button" class="btn btn-outline-danger" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
