


<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="/">Home</a></li>
    <li class="breadcrumb-item"><a href="vendors">Vendors</a></li>
    <li *ngIf="!isAddMode" class="breadcrumb-item active" aria-current="page">Edit Vendor</li>
    <li *ngIf="isAddMode" class="breadcrumb-item active" aria-current="page">Add Vendor</li>
  </ol>
</nav>


<form [formGroup]="form" (ngSubmit)="onSubmit()" class="mb-4">

  <div class="card">

    <div class="card-body">

    <div class="form-row">

      <input type="hidden" formControlName="id" class="form-control" />
      <div class="row">

        <div class="col-6">
            <label  class="lead" for="firstName">Name</label>
            <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">Name is required</div>
            </div>
        </div>


        <div class="col">
          <label  class="lead" for=""><span class="invisible">hi</span></label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox"  formControlName="canEdit">
            <label class="form-check-label" for="flexCheckDefault">
              Allow Edit Urls In Project
            </label>
          </div>
        </div>
        <div class="col">
          <label  class="lead" for=""><span class="invisible">hi</span></label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox"  formControlName="signRedirects">
            <label class="form-check-label" for="flexCheckDefault">
              Sign Redirects
            </label>
          </div>
        </div>
      </div>

        <div class="row mt-2">

          <div class="col">
            <label  class="lead" for="completeUrl">Complete Url <small> (#vid# #spid#)</small></label>
            <input
              type="url"
              formControlName="completeUrl"
              pattern="https?://.*"
              placeholder="https://vendor.com?status=complete&vid=#vid#&spid=#spid#"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.completeUrl.errors }"
            />
            <div *ngIf="submitted && f.completeUrl.errors" class="invalid-feedback">
              <div *ngIf="f.completeUrl.errors?.required">Complete Url is required</div>
              <div *ngIf="f.completeUrl.errors?.pattern">http:// or https://</div>
            </div>
          </div>

          <div class="col-6">
            <label  class="lead" for="termUrl">Term Url<small>  (#vid# #spid#)</small></label>
            <input
              type="url"
              pattern="https?://.*"
              placeholder="https://vendor.com?status=term&vid=#vid#&spid=#spid#"
              formControlName="termUrl"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.termUrl.errors }"
            />
            <div *ngIf="submitted && f.termUrl.errors" class="invalid-feedback">
              <div *ngIf="f.termUrl.errors?.required">Term Url is required</div>
              <div *ngIf="f.termUrl.errors?.pattern">http:// or https://</div>
            </div>
          </div>

        </div>
        <div class="row mt-2">

          <div class="col">
            <label  class="lead" for="oqUrl">OQ Url<small> (#vid# #spid#)</small></label>
            <input
              type="url"
              pattern="https?://.*"
              placeholder="https://vendor.com?status=oq&vid=#vid#&spid=#spid#"
              formControlName="oqUrl"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.oqUrl.errors }"
            />
            <div *ngIf="submitted && f.oqUrl.errors" class="invalid-feedback">
              <div *ngIf="f.oqUrl.errors?.required">Live Url is required</div>
              <div *ngIf="f.oqUrl.errors?.pattern">http:// or https://</div>
            </div>
          </div>

          <div class="col-6">
            <label  class="lead" for="qcUrl">QC Url<small> (#vid# #spid#)</small></label>
            <input
              type="url"
              pattern="https?://.*"
              placeholder="https://vendor.com?status=qc&vid=#vid#&spid=#spid#"
              formControlName="qcUrl"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.qcUrl.errors }"
            />
            <div *ngIf="submitted && f.qcUrl.errors" class="invalid-feedback">
              <div *ngIf="f.qcUrl.errors?.required">QC Url is required</div>
              <div *ngIf="f.qcUrl.errors?.pattern">http:// or https://</div>
            </div>
          </div>

        </div>

        <div class="row mt-2">
        <div class="col-6">
          <label  class="lead" for="firstName">Signing Key <small>(Admin only)</small></label>
          <input type="text" formControlName="signingKey" class="form-control"/>
      </div>
    </div>

    </div>
  </div>
  <div class="card-footer">
    <div class="form-group float-end">
        <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Save
        </button>
        <a routerLink="/vendors" class="btn btn-link">Cancel</a>
    </div>
  </div>
</div>
</form>

<app-vendor-keys *ngIf="!isAddMode" [vendorId]="id"></app-vendor-keys>
