import { Router } from '@angular/router';
import { InstanceService } from '../../_services/instance.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-instance',
  templateUrl: './instance.component.html',
  styleUrls: ['./instance.component.scss'],
})
export class InstanceComponent implements OnInit {
  layout: any[] = [
    { label: 'Name', id: 'name', type: '' },
    { label: 'Prefix', id: 'projectPrefix', type: '' },
    { label: 'Country', id: 'country', type: '' },
    { label: 'Open', id: 'allowEntryWithoutReferralCode', type: 'open' },

    { label: 'Location', id: 'foursquareLocation', type: 'enabled' },
    { label: 'Activity', id: 'realityMineAccessibility', type: 'enabled' },
    { label: 'Microphone', id: 'kantarACR', type: 'enabled' },
    { label: 'Backup', id: 'isBackUpPanel', type: 'yes' },
  ];

  actions: any[] = [
    { label: 'Edit', icon: '', type: 'edit' },
    { label: 'Delete', icon: '', type: 'delete' },
  ];

  items: any[] = [];

  constructor(
    private instanceService: InstanceService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getInstances();
  }

  createNew() {}

  handleAction(action: any) {
    if (action.type === 'edit') {
      this.router.navigate(['/instances/edit/' + action.row.id]);
    } else if (action.type === 'add') {
      this.router.navigate(['/instances/add']);
    } else if (action.type === 'delete') {
      this.instanceService.delete(action.row).subscribe(
        (data) => {},
        (error) => {
          this.toastr.error('Failed to delete ' + action.row.name);
        },
        () => {
          this.getInstances();
          this.toastr.success('Deleted ' + action.row.name);
        }
      );
    }
  }

  getInstances() {
    this.instanceService.listInstances().subscribe(
      (data) => {
        this.items = data;
      },
      (error) => {
        this.toastr.error('Failed to fetch instances');
      },
      () => {
        // this.toastr.success('Fetched instances');
      }
    );
  }
}
