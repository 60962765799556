import { DropDownService } from '../../_services/drop-down.service';
import { ExampleService } from '../../_services/example.service';
import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-examples',
  templateUrl: './examples.component.html',
  styleUrls: ['./examples.component.css']
})
export class ExamplesComponent implements OnInit {
  form: FormGroup;

  fileToUpload: any;
  email = {email:"tony.moffatt2@hotmail.com", display:"Tony Moffatt"};
  link = {url:"https://hotmail.com", display:"Tony Moffatt"};
  initials = {fname:"Tony", lname:"Moffatt"};

  layout:any[] =[
    {label:"Name",id:"name",type:""},
    {label:"Url",id:"url",type:"link"}
  ];

  actions:any[] =[
    {label:"Edit",icon:"",type:"edit"},
    {label:"Delete",icon:"",type:"delete"}
  ];

  items:any[] =[
    {id:"1",name:"Lighthouse Consulting",url:"lighthouseconsulting.io"},
    {id:"2",name:"Austins",url:"austins.io"},
    {id:"3",name:"Tony",url:"tony.io"}
  ];

  vendors: any[]=[];

  dropdownList: any[] = [];
  selectedItems: any[] = [];
  dropdownSettings: any = {};

  constructor( private formBuilder: FormBuilder, private exampleService: ExampleService, private dropDownService: DropDownService) {

    this.form = this.formBuilder.group({
      model: [{
        targets:[{questionKey: "GENDER", ids:[1,2]}, { questionKey: "AGE", ids: [] } ],
        quotas:[],
        zips:[],
        ageTarget:{
          target: {
            min:25,
            max:35
          },
          buckets:[{
            min:25,
            max:29,

          },{
            min:30,
            max:35
          }]
        }
      }],//


    });

  }

  ngOnInit() {
    this.getVendorsForDropDown();

    this.dropdownList = [
      { item_id: 1, item_text: 'Mumbai' },
      { item_id: 2, item_text: 'Bangaluru' },
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' },
      { item_id: 5, item_text: 'New Delhi' }
    ];
    this.selectedItems = [
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' }
    ];
    this.dropdownSettings = <IDropdownSettings>{
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  onSubmit() {
    let formObject = this.form.value;
  }

  downloadFile(){
    this.exampleService.downloadCSV().subscribe(data =>{
      const blob = new Blob([data], { type: 'text/csv' });
      const url= window.URL.createObjectURL(blob);
      const hiddenElement = document.createElement('a');
      hiddenElement.href = url;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'example.csv';
      hiddenElement.click();
    })
  }

  handleFileInput(event: any) {

    this.fileToUpload = event.target.files.item(0);
    this.uploadFileToActivity();
}

uploadFileToActivity() {
  this.exampleService.uploadCSV(this.fileToUpload).subscribe(data => {
    // do something, if upload success
    });
}

getVendorsForDropDown(){
  this.dropDownService.instances().subscribe(data=>{
    this.vendors= data;
    //console.log(data);
  }, error =>{
    console.log(error);

  }, () => {
    // this.toastr.success('Fetched instances');
  })
}

}
