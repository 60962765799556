import { Instance } from './../_models/instance';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InstanceService {
  constructor(private http: HttpClient) {}

  listInstances() {
    return this.http.get<Instance[]>(`${environment.apiUrl}/api/instance`);
  }

  getInstance(id: string) {
    return this.http.get<Instance>(`${environment.apiUrl}/api/instance/` + id);
  }

  addOrUpdateInstance(model: Instance) {
    console.log(model)
    return this.http.post(`${environment.apiUrl}/api/instance`, model);
  }

  delete(model: Instance) {
    return this.http.delete(`${environment.apiUrl}/api/instance/` + model.id);
  }
}
