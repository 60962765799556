import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  layout: any[] = [
    { label: 'First Name', id: 'firstName', type: '' },
    { label: 'Last Name', id: 'lastName', type: '' },
    { label: 'Email', id: 'email', type: 'email' },
    //{label:"Role",id:"role",type:""}
  ];

  actions: any[] = [
    { label: 'Edit', icon: '', type: 'edit' },
    { label: 'Delete', icon: '', type: 'delete' },
  ];

  items: any[] = [];

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getUsers();
  }

  handleAction(action: any) {
    if (action.type === 'edit') {
      this.router.navigate(['users/edit/' + action.row.email]);
    } else if (action.type === 'add') {
      this.router.navigate(['users/add']);
    } else if (action.type === 'delete') {
      this.userService.delete(action.row.email).subscribe(
        (data) => {},
        (error) => {
          this.toastr.error('Failed to delete user');
        },
        () => {
          this.getUsers();
          this.toastr.success('User deleted');
        },
      );
    }
  }

  getUsers() {
    this.userService.list().subscribe(
      (data: any[]) => {
        this.items = data;
        console.log(data);
      },
      (error) => {
        this.toastr.error('Failed to fetch users');
      },
      () => {
        // this.toastr.success('Fetched instances');
      },
    );
  }
}
