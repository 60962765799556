import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PointsHistory } from '../_models/points-history';
import { Reward } from '../_models/reward';
import { NubbleUser } from '../_models/nubble-user';
import { UserDisable } from '../_models/user-disable';
import { UserMarkFraud } from '../_models/user-mark-fraud';
import { UserVerifyEmail } from '../_models/user-verify-email';
import { ComplianceReport } from '../_models/compliance-report';
import { UserAddPoints } from '../_models/user-add-points';
import { UserEditEmail } from '../_models/user-edit-email';
import { UserEditDOB } from '../_models/user-edit-dob';
import { WebsiteVisits } from '../_models/website-visits';
import { UserEditPhonenumber } from '../_models/user-edit-phonenumber';
import { UsersForReEngagementSettings } from '../_models/users-for-reengagement-settings';
import { Marker } from '../_models/marker';
import { RewardEditIsReviewed } from '../_models/reward-edit-is-reviewed';
import { MarkerIsDisabled } from '../_models/marker-is-disabled';
import { UserSurveyHistory } from '../_models/user-survey-history';
import { SearchUserDTO } from '../_models/searchUserDTO';

@Injectable({
  providedIn: 'root',
})
export class NubbleService {
  constructor(private http: HttpClient) {}

  list(userId: any) {
    return this.http.get<PointsHistory[]>(
      `${environment.apiUrl}/api/nubble/` + userId
    );
  }

  rewardlist(userId: any) {
    return this.http.get<Reward[]>(
      `${environment.apiUrl}/api/nubble/reward/` + userId
    );
  }

  pendingRewardsList() {
    return this.http.get<Reward[]>(
      `${environment.apiUrl}/api/nubble/reward/pending`
    );
  }

  userfind(userId: any) {
    return this.http.get<NubbleUser>(
      `${environment.apiUrl}/api/nubble/user/` + userId
    );
  }

  userFindByEmail(searchUserDTO: SearchUserDTO) {
    return this.http.post<NubbleUser[]>(
      `${environment.apiUrl}/api/nubble/user/searchbyemail`, searchUserDTO
    );
  }

  userFindByPhone(searchUserDTO: SearchUserDTO) {
    return this.http.post<NubbleUser[]>(
      `${environment.apiUrl}/api/nubble/user/searchbyphone`, searchUserDTO
    );
  }

  usersList() {
    return this.http.get<NubbleUser[]>(
      `${environment.apiUrl}/api/nubble/user/userslist`
    );
  }

  userDisable(userDisable: UserDisable) {
    return this.http.post<NubbleUser>(
      `${environment.apiUrl}/api/nubble/user/userdisable`,
      userDisable
    );
  }

  userVerifyEmail(userVerifyEmail: UserVerifyEmail) {
    return this.http.post<NubbleUser>(
      `${environment.apiUrl}/api/nubble/user/userverifyemail`,
      userVerifyEmail
    );
  }

  userMarkFraud(userMarkFraud: UserMarkFraud) {
    return this.http.post<NubbleUser>(
      `${environment.apiUrl}/api/nubble/user/usermarkfraud`,
      userMarkFraud
    );
  }

  rewardEditIsReviewed(rewardEditIsReviewed: RewardEditIsReviewed) {
    return this.http.post<NubbleUser>(
      `${environment.apiUrl}/api/nubble/reward/isreviewed`,
      rewardEditIsReviewed
    );
  }

  userAddPoints(addPoints: UserAddPoints) {
    return this.http.post<NubbleUser>(
      `${environment.apiUrl}/api/nubble/user/addpoints`,
      addPoints
    );
  }

  userEditEmail(userEditEmail: UserEditEmail) {
    return this.http.post<NubbleUser>(
      `${environment.apiUrl}/api/nubble/user/editemail`,
      userEditEmail
    );
  }

  userEditDOB(userEditDOB: UserEditDOB) {
    return this.http.post<NubbleUser>(
      `${environment.apiUrl}/api/nubble/user/editdob`,
      userEditDOB
    );
  }

  userEditPhonenumber(userEditPhonenumber: UserEditPhonenumber) {
    return this.http.post<NubbleUser>(
      `${environment.apiUrl}/api/nubble/user/editphonenumber`,
      userEditPhonenumber
    );
  }

  userListComplianceReports(userId: any) {
    return this.http.get<ComplianceReport[]>(
      `${environment.apiUrl}/api/nubble/user/rmdevicestatus/` + userId
    );
  }

  userListAppHistory(userId: string) {
    return this.http.get<any[]>(
      `${environment.apiUrl}/api/nubble/user/apphistory/` + userId
    );
  }

  userListLocationHistory(userid: string) {
    return this.http.get<any[]>(
      `${environment.apiUrl}/api/nubble/user/locationhistory/` + userid
    );
  }

  userGetComplianceReportKPI(userId: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/api/nubble/user/usercompliancereportkpis/` + userId
    );
  }

  userGetFoursquareVisitsKPI(userId: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/api/nubble/user/foursqvisits/` + userId
    );
  }

  userListWebsiteVisits(userId: any) {
    return this.http.get<WebsiteVisits[]>(
      `${environment.apiUrl}/api/nubble/user/rmwebsitevisits/` + userId
    );
  }

  userListSurveyVisits(userId: any) {
    return this.http.get<UserSurveyHistory[]>(
      `${environment.apiUrl}/api/nubble/user/surveyhistory/` + userId
    );
  }

  sendReEngagementRequest(usersForReEngagementSettings: UsersForReEngagementSettings) {
    return this.http.post<number>(
      `${environment.apiUrl}/api/nubble/user/usersforreengagementmessages`, usersForReEngagementSettings
    );
  }

  createMarker(marker: Marker) {
    return this.http.post<Marker>(`${environment.apiUrl}/api/nubble/createmarker`, marker
    );
  }

  listMarkers() {
    return this.http.get<Marker[]>(`${environment.apiUrl}/api/nubble/listmarkers`)
  }

  deleteMarker(id: any) {
    return this.http.delete(`${environment.apiUrl}/api/nubble/deletemarker/` + id)
  }

  disableMarker(markerIsDisabled: MarkerIsDisabled) {
    return this.http.post(`${environment.apiUrl}/api/nubble/disablemarker`, markerIsDisabled)
  }

  updateMarkerSegements(marker: Marker) {
    return this.http.post(`${environment.apiUrl}/api/nubble/updatemarkersegments/`, marker)
  }

  findMarkerSegments(id: string) {
    return this.http.get<Marker>(`${environment.apiUrl}/api/nubble/findmarker/` + id)
  }

  getMarkerQueryCount(marker: Marker) {
    return this.http.post<number>(`${environment.apiUrl}/api/nubble/markerquerycount`, marker);
  }

  getMarkerResultsAsync(marker: Marker) {
    return this.http.post<any>(`${environment.apiUrl}/api/nubble/markerqueryresults`, marker);
  }

  saveUserNotes(userSaveNotes: any) {
    return this.http.post<string>(`${environment.apiUrl}/api/nubble/usersavenotes`, userSaveNotes);
  }
}
