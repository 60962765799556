<div class="card">
  <div class="card-header">
    <h6>API Keys</h6>
  </div>

  <div #keyContainer class="card-body">
    <div class="container-fluid">
      <div class="row">
        <div class="me-2">
          <button (click)="addKey()" class="btn btn-outline-secondary float-end" role="button" [disabled]="loading">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            New Key
          </button>
        </div>
      </div>
      <div *ngIf="keys.length > 0" class="row mt-2">
        <div class="col-12">
          <p-table [value]="keys" [tableStyle]="{ 'min-width': '20rem' }" styleClass="p-datatable-striped" dataKey="sortKey"
                   [autoLayout]="true"
                   [paginator]="true" [rows]="5"
                   [showCurrentPageReport]="true"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} API keys."
                   [rowsPerPageOptions]="[5, 10, 25]">
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="createDate">Date</th>
                <th pSortableColumn="id" colspan="2">Client Id</th>
                <th pSortableColumn="sortKey">Client Secret</th>
                <th pSortableColumn="isActive" colspan="3">Status</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-key>
              <tr>
                <td>{{ key.createDate.slice(0, 10) }}</td>
                <td>
                  <table class="limitedTable">
                    <tr>
                      <td class="limitedText" [title]="key.id">
                        {{ key.id }}
                      </td>
                    </tr>
                  </table>
                </td>
                <td>
                  <i ngxClipboard [container]="keyContainer" [cbContent]="key.id" (click)="this.toastr.info('Copied to clipboard')" class="fa fa-copy" style="font-size: 12px"></i>
                </td>
                <td>
                  <table class="limitedTable">
                    <tr>
                      <td class="limitedText" [title]="key.sortKey">
                        {{ key.sortKey }}
                      </td>
                    </tr>
                  </table>
                </td>
                <td>
                  <i ngxClipboard [container]="keyContainer" [cbContent]="key.sortKey" (click)="this.toastr.info('Copied to clipboard')" class="fa fa-copy" style="font-size: 12px"></i>
                </td>
                <td>
                  <p-inputSwitch inputId="statusSwitch" [(ngModel)]="key.isActive" (click)="confirm(key, 'toggle', $event)" [readonly]="key.isActive" (onChange)="confirm(key, 'toggle', $event)" [attr.data-bs-toggle]="key.isActive ? 'modal' : ''" data-bs-target="#confirmModal"></p-inputSwitch>
                </td>
                <td class="m-0 ps-0 pt-0 pb-0 pe-1">
                  <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger" [disabled]="loading" data-bs-toggle="modal" data-bs-target="#confirmModal" (click)="confirm(key, 'delete', $event)"></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Confirm Modal -->
<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="confirmModalTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="confirmModalTitle">Are you sure?</h5>
      </div>
      <div class="modal-body">
        {{ modalBody }}
      </div>
      <div class="modal-footer">
        <button #closeConfirmModal type="button" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
        <button [disabled]="confirming" (click)="this.confirmAction(this.selectedKey, this.selectedAction)" class="btn btn-primary">
          <span *ngIf="confirming" class="spinner-border spinner-border-sm mr-1"></span>
          Yes
        </button>
      </div>
    </div>
  </div>
</div>
