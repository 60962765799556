import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'prettyprint'
})
export class PrettyPrintPipe implements PipeTransform {
  transform(val) {

    var jsonObject = JSON.parse(val);

    return JSON.stringify(jsonObject, null, 2)
      .replace(' ', '&nbsp;')
      .replace('\n', '<br/>');
  }
}
