<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item active" aria-current="page">Review User</li>
  </ol>
</nav>

<!-- Laid out in a x2 component per row grid -->
<div class="card-body">
  <div class="row">
    <div class="col" *ngIf="!userIsLoaded">
      <div class="h-100 d-flex justify-content-center align-items-center">
        <app-loading-icon></app-loading-icon>
      </div>
    </div>
    <ng-container *ngIf="userIsLoaded">
    <!-- <div class="col-2">
      <div class="h-100 d-flex justify-content-center align-items-center" *ngIf="!userIsLoaded">
        <app-loading-icon></app-loading-icon>
      </div>
      <button type="button" class="btn btn-outline-secondary">
        Number of Days Installed
        <div class="spinner-border spinner-border-sm text-primary" role="status" *ngIf="!daysInstalledIsLoaded">
          <span class="sr-only">Loading...</span>
        </div>
        <span class="badge bg-primary" *ngIf="daysInstalledIsLoaded">{{kpiData.daysInstalled}}</span>
      </button>
    </div>
    <div class="col-2">
      <button type="button" class="btn btn-outline-secondary">
        Number of Foursquare Visits
        <div class="spinner-border spinner-border-sm text-primary" role="status" *ngIf="!noFoursquareVisitsIsLoaded">
          <span class="sr-only">Loading...</span>
        </div>
        <span class="badge bg-primary" *ngIf="noFoursquareVisitsIsLoaded">{{kpiData.noFoursquareVisits}}</span>
      </button>
    </div>
    <div class="col-2">
      <button type="button" class="btn btn-outline-secondary">
        Number of Foursquare Home Visits
        <div class="spinner-border spinner-border-sm text-primary" role="status" *ngIf="!noFoursquareHomeVisitsIsLoaded">
          <span class="sr-only">Loading...</span>
        </div>
        <span class="badge bg-primary" *ngIf="noFoursquareHomeVisitsIsLoaded">{{kpiData.noFoursquareHomeVisits}}</span>
      </button>
    </div>
    <div class="col-2">
      <button type="button" class="btn btn-outline-secondary">
        Number of Points Earned
        <div class="spinner-border spinner-border-sm text-primary" role="status" *ngIf="!totalEarnedIsLoaded">
          <span class="sr-only">Loading...</span>
        </div>
        <span class="badge bg-primary" *ngIf="totalEarnedIsLoaded">{{kpiData.totalEarned}}</span>
      </button>
    </div>
    <div class="col-2">
      <button type="button" class="btn btn-outline-secondary">
        Number of Points Redeemed
        <div class="spinner-border spinner-border-sm text-primary" role="status" *ngIf="!totalRedeemedIsLoaded">
          <span class="sr-only">Loading...</span>
        </div>
        <span class="badge bg-primary" *ngIf="totalRedeemedIsLoaded">{{kpiData.totalRedeemed}}</span>
      </button>
    </div> -->

    <div class="row">
      <div class="col-6">
        <app-user-details [user]="user" [isFullReviewPage]="true"></app-user-details>
      </div>
      <div class="col-6">
        <app-user-fraud-flags [user]="user"></app-user-fraud-flags>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <app-notes-wyswyg [user]="user"></app-notes-wyswyg>
      </div>
      <div class="col-6">
        <app-survey-history [userId]="userId"></app-survey-history>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-6">
        <app-quicksight-appusage-chart [userid]="userId"></app-quicksight-appusage-chart>
      </div>
      <div class="col-6" *ngIf="fsqVisitsIsLoaded">
        <app-quicksight-geolocation-map [installid]="installid"></app-quicksight-geolocation-map>
      </div>
    </div> -->

    <div class="row">
      <div class="col-6">
        <app-reward-history-view [userId]="userId" (action)="receiveKPIDataRewardHistory($event)"></app-reward-history-view>
      </div>
      <div class="col-6">
        <app-points-history-view [userId]="userId" (action)="receiveKPIDataPointsHistory($event)"></app-points-history-view>
      </div>
    </div>

    <!-- Container for advanced Quicksight Dashboard if needed later -->
    <!-- <div class="row mt-3">
        <app-quicksight-dashboard>
          <div id="dashboardContainer"></div>
        </app-quicksight-dashboard>
      </div> -->

      <div class="row">
        <div class="col-6">
          <app-rm-compliance-report [userId]="userId"></app-rm-compliance-report>
        </div>
        <div class="col-6">
          <app-website-visits [userId]="userId"></app-website-visits>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <app-user-locations-list [userId]="userId"></app-user-locations-list>
        </div>
        <div class="col-6">
          <app-user-app-history-list [userId]="userId"></app-user-app-history-list>
        </div>
      </div>
    </ng-container>
  </div>
</div>
