import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NubbleUser } from 'src/app/_models/nubble-user';
import { RewardEditIsReviewed } from 'src/app/_models/reward-edit-is-reviewed';
import { NubbleService } from 'src/app/_services/nubble.service';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

@Component({
  selector: 'app-reward-history-view',
  templateUrl: './reward-history-view.component.html',
  styleUrls: ['./reward-history-view.component.css']
})
export class RewardHistoryViewComponent implements OnInit {

  @Input() userId: string;

  user: NubbleUser;
  isLoaded: boolean = false;
  cardTitle: string = 'Reward History';

  rewardEditIsReviewed: RewardEditIsReviewed;

  layout:any[] =[

    {label:"Order Created",id:"orderCreatedDateTime",type:"onlydate"},
    {label:"Amount",id:"amount",type:""},
    {label:"Name",id:"name",type:""},
    {label:"Tremendous ID",id:"tremendousId",type:"tremendousLink", link: "tremendousLink"},
    {label:"Is Reviewed",id:"isReviewed",type:"toggle"}
  ];

  actions: any[] = [];

  rewards:any[]=[];

  @Output() action = new EventEmitter();

  constructor(
    private nubbleService: NubbleService,
    private toastr: ToastrService
    ) {}

  ngOnInit() {
    this.nubbleService.userfind(this.userId).subscribe((data) =>{
      this.user = data;
    }, error =>{
        this.toastr.error('Failed to fetch user');
    }, () => {
        //complete
        this.isLoaded = true;
    })

    this.getRewardHistory();
  }

  getRewardHistory(){
    this.nubbleService.rewardlist(this.userId).subscribe(data=>{
      this.rewards = data;
    }, error =>{
        this.toastr.error('Failed to fetch reward history');
    }, () => {
        this.sendKPIDataRewardHistory();
    })
  }

  handleAction(action: any) {
    if (action.type === 'toggle') {
      this.verifyRewardIsReviewed(action.row);
    }
  }

  //Browser Alert: Verify Mark User as Fraud
  verifyRewardIsReviewed($event) {

    this.rewardEditIsReviewed = { rewardId: $event.id, isReviewed: $event.isReviewed };

    if ($event.isReviewed == true) {

      if (window.confirm("Are you sure you would like to mark as reviewed?") == true) {

        this.markAsReviewed("Marked as reviewed", "Failed to mark as reviewed");

      } else {

        /*
        Sets the row in the lists toggle value back to not reviewed and updates the item in the
          userfraudMarkers array to revert toggle
        */

        $event.isReviewed = !$event.isReviewed;
        this.ngOnInit();
      }
    }

    else if ($event.isReviewed == false) {

      if (window.confirm("Are you sure you would like to unmark as reviewed?") == true) {

        this.markAsReviewed("Unmarked as reviewed", "Failed to unmark as reviewed");

      } else {

        /*
          Sets the row in the lists toggle value back to reviewed and updates the item in the
          userfraudMarkers array to revert toggle
        */

        $event.isReviewed = !$event.isReviewed;
        this.ngOnInit();
      }
    }

    this.rewards[$event.id] = $event;
  }

  //Toggle: Mark fraud marker as reviewed
  markAsReviewed(successMsg, failureMsg) {
    this.nubbleService.rewardEditIsReviewed(this.rewardEditIsReviewed).subscribe((data) => {},
    error =>{

      //this.user.personalEmailVerified = !this.user.personalEmailVerified;
      this.toastr.error(failureMsg);
    }, () => {
        this.toastr.success(successMsg);
        this.isLoaded = true;
    })
  }

  sendKPIDataRewardHistory() {
    this.action.emit(this.rewards);
  }
}
