<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item active" aria-current="page">Fraud User Review List</li>
  </ol>
</nav>

  <div class="row">
    <ng-container *ngIf="instanceIsLoaded">
      <div class="col-6">
        <p-toolbar [style]="{'height': '110px', 'margin-bottom': '40px'}">
            <div class="col-8">
              <div class="row" [style]="{'width': '300px'}">
                <span>Review Actions</span>
              </div>
              <div class="row pt-2">
                <div class="col">
                  <button pButton pRipple [label]="'Okay'" [style]="{'margin': '0px'}" icon="pi pi-check" class="p-button-default p-button-raised" (click)="editReviewStatusSelectedUsers('markOkay')" [disabled]="!selectedUsers || !selectedUsers.length"></button>
                </div>
                <div class="col">
                  <button pButton pRipple [label]="'Fraud'" [style]="{'margin': '0px'}" icon="pi pi-exclamation-triangle" class="p-button-warning p-button-raised" (click)="editReviewStatusSelectedUsers('markSuspectedOfFraud')" [disabled]="!selectedUsers || !selectedUsers.length"></button>
                </div>
                <div class="col">
                  <button pButton pRipple [label]="'Ban'" [style]="{'margin': '0px'}" icon="pi pi-ban" class="p-button-danger p-button-raised" (click)="editReviewStatusSelectedUsers('markPermanentlyBanned')" [disabled]="!selectedUsers || !selectedUsers.length"></button>
                </div>
              </div>
            </div>

          <!-- ADD BACK IN LATER -->
          <!-- <div class="col-4">
            <div class="row" [style]="{'width': '300px'}">
              <span>User Count</span>
            </div>
            <div class="row">
                <span class="badge bg-light text-dark">Needs Reviewing: 1000</span>
                <span class="badge bg-warning text-dark">Suspected Of Fraud: 1000</span>
                <span class="badge bg-danger text-dark">Permanently Banned: 1000</span>
              </div>
          </div> -->

        </p-toolbar>
      </div>
      <div class="col-6">
        <p-toolbar [style]="{'height': '110px', 'margin-bottom': '40px'}">
          <div class="row">
            <span>Instance Settings</span>
          </div>
          <div class="row">
            <div class="col">
              <span class="badge bg-light text-dark" *ngIf="!instance.fraudBatteryCheckEnabled">
                Fraud Battery: <del>{{fraudSettingsTextMutation(instance.fraudBatteryCheckEnabled)}}</del>
              </span>
              <span class="badge bg-success text-light" *ngIf="instance.fraudBatteryCheckEnabled">
                Fraud Battery: {{fraudSettingsTextMutation(instance.fraudBatteryCheckEnabled)}}
              </span>
            </div>
            <div class="col">
              <span class="badge bg-light text-dark" *ngIf="!instance.fraudHomeCheckEnabled">
                Fraud Home: <del>{{fraudSettingsTextMutation(instance.fraudHomeCheckEnabled)}}</del>
              </span>
              <span class="badge bg-success text-light" *ngIf="instance.fraudHomeCheckEnabled">
                Fraud Home: {{fraudSettingsTextMutation(instance.fraudHomeCheckEnabled)}}
              </span>          </div>
            <div class="col">
              <span class="badge bg-light text-dark" *ngIf="!instance.fraudSurveyCheckEnabled">
                Fraud Survey: <del>{{fraudSettingsTextMutation(instance.fraudSurveyCheckEnabled)}}</del>
              </span>
              <span class="badge bg-success text-light" *ngIf="instance.fraudSurveyCheckEnabled">
                Fraud Survey: {{fraudSettingsTextMutation(instance.fraudSurveyCheckEnabled)}}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="badge bg-light text-dark" *ngIf="!instance.fraudLocationCheckEnabled">
                Fraud Location: <del>{{fraudSettingsTextMutation(instance.fraudLocationCheckEnabled)}}</del>
              </span>
              <span class="badge bg-success text-light" *ngIf="instance.fraudLocationCheckEnabled">
                Fraud Location: {{fraudSettingsTextMutation(instance.fraudLocationCheckEnabled)}}
              </span>
            </div>
            <div class="col">
              <span class="badge bg-light text-dark" *ngIf="!instance.fraudOnboardingCheckEnabled">
                Fraud Onboarding: <del>{{fraudSettingsTextMutation(instance.fraudOnboardingCheckEnabled)}}</del>
              </span>
              <span class="badge bg-success text-light" *ngIf="instance.fraudOnboardingCheckEnabled">
                Fraud Onboarding: {{fraudSettingsTextMutation(instance.fraudOnboardingCheckEnabled)}}
              </span>
            </div>
            <div class="col">
              <span class="badge bg-light text-dark" *ngIf="!instance.fraudAppCheckEnabled">
                Fraud App: <del>{{fraudSettingsTextMutation(instance.fraudAppCheckEnabled)}}</del>
              </span>
              <span class="badge bg-success text-light" *ngIf="instance.fraudAppCheckEnabled">
                Fraud App: {{fraudSettingsTextMutation(instance.fraudAppCheckEnabled)}}
              </span>
            </div>
          </div>
        </p-toolbar>
      </div>
    </ng-container>
  </div>

<div class="card">

  <p-table #dt [value]="users" [(selection)]="selectedUsers" dataKey="userId" styleClass="p-datatable-users"
          [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
          [paginator]="false" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"[filterDelay]="0">
        <ng-template pTemplate="header">
            <tr class="no-border">
              <th style="width: 3rem">
              </th>
              <th style="width: 10rem">
                <span pTooltip="Personal Email of the Flagged User" tooltipPosition="top">Personal Email</span>
              </th>
              <th style="width: 10rem">
                <span pTooltip="Fraud Status" tooltipPosition="top">Status</span>
              </th>
              <th style="width: 8rem">
                <span pTooltip="Date User was Flagged On" tooltipPosition="top">Flagged On</span>
              </th>
              <th>
                <span pTooltip="Fraud Battery Check" tooltipPosition="top">Battery</span>
              </th>
              <th>
                <span pTooltip="Fraud Location Check" tooltipPosition="top">Location</span>
              </th>
              <th>
                <span pTooltip="Fraud Survey Check" tooltipPosition="top">Survey</span>
              </th>
              <th>
                <span pTooltip="Fraud Home Check" tooltipPosition="top">Home</span>
              </th>
              <th>
                <span pTooltip="Fraud Application Check" tooltipPosition="top">Application</span>
              </th>
              <th>
                <span pTooltip="Fraud Email Domain Check" tooltipPosition="top">Email Domain</span>
              </th>
              <th>
                <span pTooltip="Fraud Multiple Emails Check" tooltipPosition="top">Multiple Emails</span>
              </th>
              <th>
                <span pTooltip="Fraud Phone Number Check" tooltipPosition="top">Phone Number</span>
              </th>
              <th>
                <span pTooltip="Fraud Phone Manufacturer Check" tooltipPosition="top">Manufacturer</span>
              </th>
              <th>
                <span pTooltip="Fraud Onboarding Check" tooltipPosition="top">Onboarding</span>
              </th>
            </tr>
            <tr>
              <th>
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th pSortableColumn="personalEmail">
                <p-sortIcon field="personalEmail"></p-sortIcon>
                <p-columnFilter type="text" field="personalEmail" display="menu"></p-columnFilter>
              </th>
              <th>
                <div class="flex justify-content-center align-items-center">
                  <p-sortIcon field="fraudStatus"></p-sortIcon>
                  <p-columnFilter field="fraudStatus" matchMode="in" display="menu" [showMatchModes]="false"
                    [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false">
                      <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <p-multiSelect [ngModel]="value" [options]="reviewStatusFilters" (onChange)="filter($event.value)">
                              <ng-template let-option pTemplate="item">
                                  <div class="p-multiselect-fraudstatus">
                                    <span class="ml-1">{{option}}</span>
                                  </div>
                              </ng-template>
                          </p-multiSelect>
                      </ng-template>
                  </p-columnFilter>
                </div>
              </th>

              <th pSortableColumn="flaggedOn">
                <div class="flex justify-content-center align-items-center">
                  <p-sortIcon field="flaggedOn"></p-sortIcon>
                  <p-columnFilter type="date" field="flaggedOn" display="menu"></p-columnFilter>
                </div>
              </th>
              <th pSortableColumn="fraudBatteryStatus">
                <p-sortIcon field="fraudBatteryStatus"></p-sortIcon>
                <p-columnFilter field="fraudBatteryStatus" matchMode="in" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-multiSelect [ngModel]="value" [options]="statusFilters" (onChange)="filter($event.value)">
                          <ng-template let-option pTemplate="item">
                              <div class="p-multiselect-fraudstatus-option">
                                <span class="ml-1">{{option}}</span>
                              </div>
                          </ng-template>
                      </p-multiSelect>
                  </ng-template>
              </p-columnFilter>
              </th>
              <th pSortableColumn="fraudLocationStatus">
                <p-sortIcon field="fraudLocationStatus"></p-sortIcon>
                <p-columnFilter field="fraudLocationStatus" matchMode="in" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-multiSelect [ngModel]="value" [options]="statusFilters" (onChange)="filter($event.value)">
                          <ng-template let-option pTemplate="item">
                              <div class="p-multiselect-fraudstatus-option">
                                <span class="ml-1">{{option}}</span>
                              </div>
                          </ng-template>
                      </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </th>
              <th pSortableColumn="fraudSurveyStatus">
                <p-sortIcon field="fraudSurveyStatus"></p-sortIcon>
                <p-columnFilter field="fraudSurveyStatus" matchMode="in" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-multiSelect [ngModel]="value" [options]="statusFilters" (onChange)="filter($event.value)">
                          <ng-template let-option pTemplate="item">
                              <div class="p-multiselect-fraudstatus-option">
                                <span class="ml-1">{{option}}</span>
                              </div>
                          </ng-template>
                      </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </th>
              <th pSortableColumn="fraudHomeStatus">
                <p-sortIcon field="fraudHomeStatus"></p-sortIcon>
                <p-columnFilter field="fraudHomeStatus" matchMode="in" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-multiSelect [ngModel]="value" [options]="statusFilters" (onChange)="filter($event.value)">
                          <ng-template let-option pTemplate="item">
                              <div class="p-multiselect-fraudstatus-option">
                                <span class="ml-1">{{option}}</span>
                              </div>
                          </ng-template>
                      </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </th>
              <th pSortableColumn="fraudAppsStatus">
                <p-sortIcon field="fraudAppsStatus"></p-sortIcon>
                <p-columnFilter field="fraudAppsStatus" matchMode="in" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-multiSelect [ngModel]="value" [options]="statusFilters" (onChange)="filter($event.value)">
                          <ng-template let-option pTemplate="item">
                              <div class="p-multiselect-fraudstatus-option">
                                <span class="ml-1">{{option}}</span>
                              </div>
                          </ng-template>
                      </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </th>
              <th pSortableColumn="fraudEmailDomainStatus">
                <p-sortIcon field="fraudEmailDomainStatus"></p-sortIcon>
                <p-columnFilter field="fraudEmailDomainStatus" matchMode="in" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-multiSelect [ngModel]="value" [options]="statusFilters" (onChange)="filter($event.value)">
                          <ng-template let-option pTemplate="item">
                              <div class="p-multiselect-fraudstatus-option">
                                <span class="ml-1">{{option}}</span>
                              </div>
                          </ng-template>
                      </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </th>
              <th pSortableColumn="fraudMultipleEmailsStatus">
                <p-sortIcon field="fraudMultipleEmailsStatus"></p-sortIcon>
                <p-columnFilter field="fraudMultipleEmailsStatus" matchMode="in" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-multiSelect [ngModel]="value" [options]="statusFilters" (onChange)="filter($event.value)">
                          <ng-template let-option pTemplate="item">
                              <div class="p-multiselect-fraudstatus-option">
                                <span class="ml-1">{{option}}</span>
                              </div>
                          </ng-template>
                      </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </th>
              <th pSortableColumn="fraudPhoneNumberStatus">
                <p-sortIcon field="fraudPhoneNumberStatus"></p-sortIcon>
                <p-columnFilter field="fraudPhoneNumberStatus" matchMode="in" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-multiSelect [ngModel]="value" [options]="statusFilters" (onChange)="filter($event.value)">
                          <ng-template let-option pTemplate="item">
                              <div class="p-multiselect-fraudstatus-option">
                                <span class="ml-1">{{option}}</span>
                              </div>
                          </ng-template>
                      </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </th>
              <th pSortableColumn="fraudPhoneManufacturerStatus">
                <p-sortIcon field="fraudPhoneManufacturerStatus"></p-sortIcon>
                <p-columnFilter field="fraudPhoneManufacturerStatus" matchMode="in" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-multiSelect [ngModel]="value" [options]="statusFilters" (onChange)="filter($event.value)">
                          <ng-template let-option pTemplate="item">
                              <div class="p-multiselect-fraudstatus-option">
                                <span class="ml-1">{{option}}</span>
                              </div>
                          </ng-template>
                      </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </th>
              <th pSortableColumn="fraudOnboardingStatus">
                <p-sortIcon field="fraudOnboardingStatus"></p-sortIcon>
                <p-columnFilter field="fraudOnboardingStatus" matchMode="in" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-multiSelect [ngModel]="value" [options]="statusFilters" (onChange)="filter($event.value)">
                          <ng-template let-option pTemplate="item">
                              <div class="p-multiselect-fraudstatus-option">
                                <span class="ml-1">{{option}}</span>
                              </div>
                          </ng-template>
                      </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user let-index>
            <tr class="p-selectable-row" [pSelectableRow]="user">
                <td>
                  <p-tableCheckbox [value]="user"></p-tableCheckbox>
                </td>
                <td>
                  <a [routerLink]="" (click)="openUserDetailsInNewWindow(user.userId)">
                    {{user.personalEmail}}
                  </a>
                </td>
                <td>
                  <ng-container *ngIf="user.fraudStatus == 'Needs Reviewing'">
                    <p-badge [value]="user.fraudStatus" severity="info"></p-badge>
                  </ng-container>
                  <ng-container *ngIf="user.fraudStatus == 'Suspected of Fraud'">
                    <p-badge [value]="user.fraudStatus" severity="warning"></p-badge>
                  </ng-container>
                  <ng-container *ngIf="user.fraudStatus == 'Permanently Banned'">
                    <p-badge [value]="user.fraudStatus" severity="danger"></p-badge>
                  </ng-container>
                </td>
                <td>
                    {{user.flaggedOn | date: 'MM/dd/yyyy'}}
                </td>
                <td [ngClass]="getStyle(user.fraudBatteryStatus)">
                  <div *ngIf="user.fraudBatteryStatus == 'Excluded'; else includeBattery">
                    <del>
                      {{user.fraudBatteryStatus}}
                    </del>
                  </div>
                  <ng-template #includeBattery>
                    {{user.fraudBatteryStatus}}
                  </ng-template>
                </td>
                <td [ngClass]="getStyle(user.fraudLocationStatus)">
                  <div *ngIf="user.fraudLocationStatus == 'Excluded'; else includeLocation">
                    <del>
                      {{user.fraudLocationStatus}}
                    </del>
                  </div>
                  <ng-template #includeLocation>
                    {{user.fraudLocationStatus}}
                  </ng-template>
                </td>
                <td [ngClass]="getStyle(user.fraudSurveyStatus)">
                  <div *ngIf="user.fraudSurveyStatus == 'Excluded'; else includeSurvey">
                    <del>
                      {{user.fraudSurveyStatus}}
                    </del>
                  </div>
                  <ng-template #includeSurvey>
                    {{user.fraudSurveyStatus}}
                  </ng-template>
                </td>
                <td [ngClass]="getStyle(user.fraudHomeStatus)">
                  <div *ngIf="user.fraudHomeStatus == 'Excluded'; else includeHome">
                    <del>
                      {{user.fraudHomeStatus}}
                    </del>
                  </div>
                  <ng-template #includeHome>
                    {{user.fraudHomeStatus}}
                  </ng-template>
                </td>
                <td [ngClass]="getStyle(user.fraudAppsStatus)">
                  <div *ngIf="user.fraudAppsStatus == 'Excluded'; else includeApp">
                    <del>
                      {{user.fraudAppsStatus}}
                    </del>
                  </div>
                  <ng-template #includeApp>
                    {{user.fraudAppsStatus}}
                  </ng-template>
                </td>
                <td [ngClass]="getStyle(user.fraudEmailDomainStatus)">
                  <div *ngIf="user.fraudEmailDomainStatus == 'Excluded'; else includeEmailDomain">
                    <del>
                      {{user.fraudEmailDomainStatus}}
                    </del>
                  </div>
                  <ng-template #includeEmailDomain>
                    {{user.fraudEmailDomainStatus}}
                  </ng-template>
                </td>
                <td [ngClass]="getStyle(user.fraudMultipleEmailsStatus)">
                  <div *ngIf="user.fraudEmailDomainStatus == 'Excluded'; else includeMultipleEmails">
                    <del>
                      {{user.fraudMultipleEmailsStatus}}
                    </del>
                  </div>
                  <ng-template #includeMultipleEmails>
                    {{user.fraudMultipleEmailsStatus}}
                  </ng-template>
                </td>
                <td [ngClass]="getStyle(user.fraudPhoneNumberStatus)">
                  <div *ngIf="user.fraudPhoneNumberStatus == 'Excluded'; else includePhoneNumber">
                    <del>
                      {{user.fraudPhoneNumberStatus}}
                    </del>
                  </div>
                  <ng-template #includePhoneNumber>
                    {{user.fraudPhoneNumberStatus}}
                  </ng-template>
                </td>
                <td [ngClass]="getStyle(user.fraudPhoneManufacturerStatus)">
                  <div *ngIf="user.fraudPhoneManufacturerStatus == 'Excluded'; else includePhoneManufacturer">
                    <del>
                      {{user.fraudPhoneManufacturerStatus}}
                    </del>
                  </div>
                  <ng-template #includePhoneManufacturer>
                    {{user.fraudPhoneManufacturerStatus}}
                  </ng-template>
                </td>
                <td [ngClass]="getStyle(user.fraudOnboardingStatus)" >
                    {{user.fraudOnboardingStatus}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8">No users found.</td>
            </tr>
        </ng-template>
  </p-table>
</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
