import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExampleService {

constructor(
  private http: HttpClient
) { }


downloadCSV(){
  return this.http.get('https://localhost:44320/api/Examples/csvdownload', { responseType: 'text'});
}


uploadCSV(fileToUpload: File) {
  const formData: FormData = new FormData();
  formData.append('fileKey', fileToUpload, fileToUpload.name);
  return this.http.post("https://localhost:44320/api/Examples/csvupload", formData);
}

}
