import { AuthService } from 'src/app/_services/auth.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {


            if ([401, 403].includes(err.status) && this.authService.user) {
                // auto logout if 401 or 403 response returned from api
                this.authService.logout();
            }

            const applicationError = err.headers.get('Application-Error');
            if(applicationError){
              return throwError(applicationError);
            }

            const serverError = err.error;
            let modelStateErrors ='';
            if(serverError.errors && typeof serverError.errors === 'object'){
              for(const key in serverError.errors){
                  if(serverError.errors[key]){
                    modelStateErrors+=serverError.errors[key] + '\n';

                  }
              }
            }

            const allerrors = modelStateErrors || err.error?.message || err.statusText || 'Server Error';
            return throwError(allerrors);
        }))
    }
}
