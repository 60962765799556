import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Toggle } from 'src/app/_models/toggle';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  @Input() items: any[]=[];
  @Input() layout: any[]=[];
  @Input() actions: any[]=[];
  @Input() links: any[]=[];
  @Input() showCreateNew=true;

  @Output() action = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  actionSelected(row: any, type: any) {
    this.action.emit({row, type});
  }
}
