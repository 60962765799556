<div class="card h-100 shadow-sm">
  <div class="card-header lead">
    <div class="row">
      <div class="col-6">
        <span class="float-start">{{cardTitle}}</span>
      </div>
      <div class="col-6" *ngIf="isLoaded">
        <span class="float-end" [ngClass]="setReviewStatus()">{{userStatus}}</span>
      </div>
    </div>
  </div>

  <div class="h-100 d-flex justify-content-center align-items-center" *ngIf="!isLoaded">
    <app-loading-icon></app-loading-icon>
  </div>

  <div class="card-body" *ngIf="isLoaded">
    <div class="row">
      <div class="row">
        <div class="col-6">

          <!-- Fraud Battery Internal Card-->
          <div class="card h-60 shadow-sm">
            <div class="internalCardHeader card-header lead">
              <div class="row">
                <div class="col-6">
                  <span class="float-start fraudFlagHeader" pTooltip="Fraud Battery Check" tooltipPosition="top"><del *ngIf="user.excludeFromFraudBatteryCheck; else batteryIncluded">Battery</del></span>
                  <ng-template #batteryIncluded>
                    <span class="float-start fraudFlagHeader" pTooltip="Fraud Battery Check" tooltipPosition="top">Battery</span>
                  </ng-template>
                </div>
                <div class="col-6">
                  <div class="form-check form-switch d-inline-block" (change)="editUserAttribute($event, 'excludeFromFraudBatteryCheck')">
                    <input type="checkbox" class="form-check-input" id="site_state" style="cursor: pointer;" [(ngModel)]="user.excludeFromFraudBatteryCheck">
                    <label for="site_state" class="form-check-label">Exclude</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-center">
                <span class="statusFlag" [ngClass]="getStyle(user.fraudBatteryStatus)">
                  {{user.fraudBatteryStatus}}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6">

          <!-- Fraud Home Internal Card-->
          <div class="card h-60 shadow-sm">
            <div class="internalCardHeader card-header lead">
              <div class="row">
                <div class="col-6">
                  <span class="float-start fraudFlagHeader" pTooltip="Fraud Home Check" tooltipPosition="top"><del *ngIf="user.excludeFromFraudHomeCheck; else homeIncluded">Home</del></span>
                  <ng-template #homeIncluded>
                    <span class="float-start fraudFlagHeader" pTooltip="Fraud Home Check" tooltipPosition="top">Home</span>
                  </ng-template>
                </div>
                <div class="col-6">
                  <div class="form-check form-switch d-inline-block" (change)="editUserAttribute($event, 'excludeFromFraudHomeCheck')">
                    <input type="checkbox" class="form-check-input" id="site_state" style="cursor: pointer;" [(ngModel)]="user.excludeFromFraudHomeCheck">
                    <label for="site_state" class="form-check-label">Exclude</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body justify-content-center">
              <div class="row justify-content-center">
                <span class="statusFlag" [ngClass]="getStyle(user.fraudHomeStatus)">
                  {{user.fraudHomeStatus}}
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-6">

          <!-- Fraud Survey Internal Card-->
          <div class="card h-60 shadow-sm">
            <div class="internalCardHeader card-header lead">
              <div class="row">
                <div class="col-6">
                  <span class="float-start fraudFlagHeader" pTooltip="Fraud Survey Check" tooltipPosition="top"><del *ngIf="user.excludeFromFraudSurveyCheck; else surveyIncluded">Survey</del></span>
                  <ng-template #surveyIncluded>
                    <span class="float-start fraudFlagHeader" pTooltip="Fraud Survey Check" tooltipPosition="top" >Survey</span>
                  </ng-template>
                </div>
                <div class="col-6">
                  <div class="form-check form-switch d-inline-block" (change)="editUserAttribute($event, 'excludeFromFraudSurveyCheck')">
                    <label class="form-check-label" for="defaultCheck1">Exclude</label>
                      <input type="checkbox" class="form-check-input" id="site_state" style="cursor: pointer;" [(ngModel)]="user.excludeFromFraudSurveyCheck">
                    </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-center">
                <span class="statusFlag" [ngClass]="getStyle(user.fraudSurveyStatus)">
                  {{user.fraudSurveyStatus}}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6">

          <!-- Fraud Location Internal Card-->
          <div class="card h-60 shadow-sm">
            <div class="internalCardHeader card-header lead">
              <div class="row">
                <div class="col-6">
                  <span class="float-start fraudFlagHeader" pTooltip="Fraud Location Check" tooltipPosition="top"><del *ngIf="user.excludeFromFraudLocationCheck; else locationIncluded">Location</del></span>
                  <ng-template #locationIncluded>
                    <span class="float-start fraudFlagHeader" pTooltip="Fraud Location Check" tooltipPosition="top">Location</span>
                  </ng-template>
                </div>
                <div class="col-6">
                  <div class="form-check form-switch d-inline-block" (change)="editUserAttribute($event, 'excludeFromFraudLocationCheck')">
                    <input type="checkbox" class="form-check-input" id="site_state" style="cursor: pointer;" [(ngModel)]="user.excludeFromFraudLocationCheck">
                    <label for="site_state" class="form-check-label">Exclude</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-center">
                <span class="statusFlag" [ngClass]="getStyle(user.fraudLocationStatus)">
                  {{user.fraudLocationStatus}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">

          <!-- Fraud Email Domain Internal Card-->
          <div class="card h-60 shadow-sm">
            <div class="internalCardHeader card-header lead">
              <div class="row">
                <div class="col-6">
                  <span class="float-start fraudFlagHeader" pTooltip="Fraud Email Domain Check" tooltipPosition="top"><del *ngIf="user.excludeFromFraudEmailDomainCheck; else emailDomainIncluded">Email Domain</del></span>
                  <ng-template #emailDomainIncluded>
                    <span class="float-start fraudFlagHeader" pTooltip="Fraud Email Domain Check" tooltipPosition="top">Email Domain</span>
                  </ng-template>
                </div>
                <div class="col-6">
                  <div class="form-check form-switch d-inline-block" (change)="editUserAttribute($event, 'excludeFromFraudEmailDomainCheck')">
                    <input type="checkbox" class="form-check-input" id="site_state" style="cursor: pointer;" [(ngModel)]="user.excludeFromFraudEmailDomainCheck">
                    <label for="site_state" class="form-check-label">Exclude</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-center">
                <span class="statusFlag" [ngClass]="getStyle(user.fraudEmailDomainStatus)">
                  {{user.fraudEmailDomainStatus}}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6">

          <!-- Fraud Multiple Emails Internal Card-->
          <div class="card h-60 shadow-sm">
            <div class="internalCardHeader card-header lead">
              <div class="row">
                <div class="col-6">
                  <span class="float-start fraudFlagHeader" pTooltip="Fraud Multiple Emails Check" tooltipPosition="top"><del *ngIf="user.excludeFromFraudMultipleEmailsCheck; else multipleEmailsIncluded">Multiple Email</del></span>
                  <ng-template #multipleEmailsIncluded>
                    <span class="float-start fraudFlagHeader" pTooltip="Fraud Multiple Emails Check" tooltipPosition="top">Multiple Email</span>
                  </ng-template>
                </div>
                <div class="col-6">
                  <div class="form-check form-switch d-inline-block" (change)="editUserAttribute($event, 'excludeFromFraudMultipleEmailsCheck')">
                    <input type="checkbox" class="form-check-input" id="site_state" style="cursor: pointer;" [(ngModel)]="user.excludeFromFraudMultipleEmailsCheck">
                    <label for="site_state" class="form-check-label">Exclude</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-center">
                <span class="statusFlag" [ngClass]="getStyle(user.fraudMultipleEmailsStatus)">
                  {{user.fraudMultipleEmailsStatus}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">

          <!-- Fraud App Internal Card-->
          <div class="card h-60 shadow-sm">
            <div class="internalCardHeader card-header lead">
              <div class="row">
                <div class="col-6">
                  <span class="float-start fraudFlagHeader" pTooltip="Fraud Application Check" tooltipPosition="top"><del *ngIf="user.excludeFromFraudAppsCheck; else appIncluded">Application</del></span>
                  <ng-template #appIncluded>
                    <span class="float-start fraudFlagHeader" pTooltip="Fraud Application Check" tooltipPosition="top">Application</span>
                  </ng-template>
                </div>
                <div class="col-6">
                  <div class="form-check form-switch d-inline-block" (change)="editUserAttribute($event, 'excludeFromFraudAppsCheck')">
                    <input type="checkbox" class="form-check-input" id="site_state" style="cursor: pointer;" [(ngModel)]="user.excludeFromFraudAppsCheck">
                    <label for="site_state" class="form-check-label">Exclude</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-center">
                <span class="statusFlag" [ngClass]="getStyle(user.fraudAppsStatus)">
                  {{user.fraudAppsStatus}}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6">

          <!-- Fraud Phone Manufacturer Internal Card-->
          <div class="card h-60 shadow-sm">
            <div class="internalCardHeader card-header lead">
              <div class="row">
                <div class="col-6">
                  <span class="float-start fraudFlagHeader" pTooltip="Fraud Phone Manufacturer Check" tooltipPosition="top"><del *ngIf="user.excludeFromFraudPhoneManufacturerCheck; else phoneManufacturerIncluded">Phone Manuf...</del></span>
                  <ng-template #phoneManufacturerIncluded>
                    <span class="float-start fraudFlagHeader">Phone Manuf...</span>
                  </ng-template>
                </div>
                <div class="col-6">
                  <div class="form-check form-switch d-inline-block" (change)="editUserAttribute($event, 'excludeFromFraudPhoneManufacturerCheck')">
                    <input type="checkbox" class="form-check-input" id="site_state" style="cursor: pointer;" [(ngModel)]="user.excludeFromFraudPhoneManufacturerCheck">
                    <label for="site_state" class="form-check-label">Exclude</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-center">
                <span class="statusFlag" [ngClass]="getStyle(user.fraudPhoneManufacturerStatus)">
                  {{user.fraudPhoneManufacturerStatus}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">

        <div class="col-6">

          <!-- Fraud Phone Manufacturer Internal Card-->
          <div class="card h-60 shadow-sm">
            <div class="internalCardHeader card-header lead">
              <div class="row">
                <div class="col-6">
                  <span class="float-start fraudFlagHeader" pTooltip="Fraud Phone Number Check" tooltipPosition="top"><del *ngIf="user.excludeFromFraudPhoneNumberCheck; else phoneNumberIncluded">Phone Number</del></span>
                  <ng-template #phoneNumberIncluded>
                    <span class="float-start fraudFlagHeader" pTooltip="Fraud Phone Number Check" tooltipPosition="top">Phone Number</span>
                  </ng-template>
                </div>
                <div class="col-6">
                  <div class="form-check form-switch d-inline-block" (change)="editUserAttribute($event, 'excludeFromFraudPhoneNumberCheck')">
                    <input type="checkbox" class="form-check-input" id="site_state" style="cursor: pointer;" [(ngModel)]="user.excludeFromFraudPhoneNumberCheck">
                    <label for="site_state" class="form-check-label">Exclude</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-center">
                <span class="statusFlag" [ngClass]="getStyle(user.fraudPhoneNumberStatus)">
                  {{user.fraudPhoneNumberStatus}}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6">

          <!-- Fraud Onboarding Internal Card-->
          <div class="card h-60 shadow-sm">
            <div class="internalCardHeader card-header lead">
              <div class="row">
                <div class="col-7">
                  <span class="float-start fraudFlagHeader" pTooltip="Fraud Onboarding Check" tooltipPosition="top">Onboarding</span>
                </div>
                <div class="col-5 d-inline-block">
                  <button type="button" class="btn btn-onboarding btn-primary" (click)="handleAction(content)">JSON</button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-center">
                <span class="statusFlag" [ngClass]="getStyle(user.fraudOnboardingStatus)">
                  {{user.fraudOnboardingStatus}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="user.needsReviewing && !user.suspectedOfFraud">
        <div class="row m-1">
          <div class="col-6">
            <button type="button" class="btn actionBtn btn-warning" data-toggle="button" aria-pressed="false" autocomplete="off" (click)="editUserStatus('suspectedOfFraud')">
              Suspected of Fraud
            </button>
          </div>
          <div class="col-6">
          </div>
        </div>
      </ng-container>

        <div class="row m-1">

            <ng-container *ngIf="user.permanentlyBanned == true || user.suspectedOfFraud == true || user.needsReviewing == true">
              <div class="col-6">
                <button type="button" class="btn actionBtn btn-primary" data-toggle="button" aria-pressed="false" autocomplete="off" (click)="editUserStatus('markOkay')">
                  Mark Okay
                </button>
              </div>
            </ng-container>

            <ng-container *ngIf="user.permanentlyBanned != true">
              <div class="col-6">
                <button type="button" class="btn actionBtn btn-danger" data-toggle="button" aria-pressed="false" autocomplete="off" (click)="editUserStatus('permanentlyBanned')">
                  Permanently Ban
                </button>
              </div>
            </ng-container>
        </div>
    </div>
  </div>
</div>

<!-- Modal -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Onboarding JSON</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p *ngIf="user.fraudOnboardingJson != ''" [innerHTML]="user.fraudOnboardingJson | prettyprint">{{user.fraudOnboardingJson}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-info" (click)="copyToClipboard(user.fraudOnboardingJson)">Copy</button>
    <button type="button" class="btn btn-outline-danger" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
