import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NubbleUser } from 'src/app/_models/nubble-user';
import { SearchUserDTO } from 'src/app/_models/searchUserDTO';
import { NubbleService } from 'src/app/_services/nubble.service';

@Component({
  selector: 'app-search-list-users',
  templateUrl: './search-list-users.component.html',
  styleUrls: ['./search-list-users.component.scss']
})
export class SearchListUsersComponent implements OnInit {

  filteredItems: any[] = [];
  userItems: NubbleUser[] = [];

  usersIsLoaded: boolean = true;

  searchUserDTO: SearchUserDTO = {value: '', allowScan: false};

  layoutUsers: any[] = [
    { label: 'PhoneNumber', id: 'phoneNumber', type: '' },
    { label: 'Email', id: 'personalEmail', type: 'text' },
  ];

  actions: any[] = [{ label: 'Review', icon: '', type: 'view' }];

  constructor(private toastr: ToastrService, private nubbleService: NubbleService, private router: Router) { }

  ngOnInit() {}

  searchEmail() {
    this.usersIsLoaded = false;

    this.nubbleService.userFindByEmail(this.searchUserDTO).subscribe(
      (data) => {
        this.userItems = data;
      },
      (error) => {
        this.toastr.error('Failed to fetch user');
      },
      () => {
        this.usersIsLoaded = true;

        if (this.userItems.length < 1) {
          this.toastr.error("No Users Found");
        }
      }
    );
  }

  clear() {
    this.searchUserDTO.value = '';
    this.userItems = [];

    this.toastr.info("Search Input Cleared")
  }

  searchPhonenumber() {
    this.usersIsLoaded = false;

    // api doesn't accept +
    let searchUserDTONoPlus = {value: this.searchUserDTO.value.replace('+', ''), allowScan: this.searchUserDTO.allowScan};

    this.nubbleService.userFindByPhone(searchUserDTONoPlus).subscribe(
      (data) => {
        this.userItems = data;
      },
      (error) => {
        this.toastr.error('Failed to fetch user');
      },
      () => {
        this.usersIsLoaded = true;

        if (this.userItems.length < 1) {
          this.toastr.error("No Users Found");
        }
      }
    );
  }

  inputChange(event: any) {
    this.searchUserDTO.value = event.value;
  }

  allowScan() {
    this.searchUserDTO.allowScan = !this.searchUserDTO.allowScan;

    if (this.searchUserDTO.allowScan) {
      this.toastr.info("Scan Enabled")
    }
    else if (!this.searchUserDTO.allowScan) {
      this.toastr.warning("Scan Disabled")
    }

  }

  getScanButtonStyle() {
    if (this.searchUserDTO.allowScan === true) {
      return 'btn btn-primary bi bi-upc-scan me-1 ms-1';
    }
    else {
      return 'btn btn-outline-primary bi bi-upc-scan me-1 ms-1';
    }
  }

  openUserDetailsInNewWindow(action: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/reviewuser/${action.row.userId}`])
    );

    window.open(url, '_blank');
  }
}
