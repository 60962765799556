<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item active" aria-current="page">Pending Rewards</li>
  </ol>
</nav>

<div class="card-body">

  <div class="row mt-3">
    <div class="col">
      <div class="card">
        <div class="card-header">Pending Rewards (${{rewardItems.length}})</div>
        <div class="card-body">
          <div class="h-100 d-flex justify-content-center align-items-center" *ngIf="!pendingRewardsIsLoaded">
            <app-loading-icon></app-loading-icon>
          </div>
          <div class="row" *ngIf="pendingRewardsIsLoaded">
            <app-list
              [items]="rewardItems"
              [layout]="layoutRewards"
              [actions]="actions"
              [showCreateNew]="false"
              (action)="openUserDetailsInNewWindow($event)"
            ></app-list>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="card-footer">
    <div class="form-group float-end">
      <button type="submit" [disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Save
      </button>
      <a routerLink="/projects" class="btn btn-link">Cancel</a>
    </div>
  </div> -->
</div>
