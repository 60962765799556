<app-card [cardTitle]="cardTitle">
  <div class="h-100 d-flex justify-content-center align-items-center" *ngIf="!isLoaded">
    <app-loading-icon></app-loading-icon>
  </div>
  <div class="container vertical-scrollable">
    <div class="row" *ngIf="isLoaded">
      <app-list
      [showCreateNew]=false
      [items]="rewards"
      [layout]="layout"
      (action)="handleAction($event)"></app-list>
    </div>
  </div>
</app-card>
