import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Client } from '../_models/client';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private http: HttpClient
  ) { }

  list() {
    return this.http.get<Client[]>(`${environment.apiUrl}/api/client`)
  }

  find(id: string) {
    return this.http.get<Client>(`${environment.apiUrl}/api/client/`+id)
  }

  add(model: Client) {
    return this.http.post(`${environment.apiUrl}/api/client`,model)
  }

  update(model: Client) {
    return this.http.put(`${environment.apiUrl}/api/client`,model)
  }


  delete(model: Client) {
    return this.http.delete(`${environment.apiUrl}/api/client/`+ model.id)
  }

}
