<div class="card">
  <div class="card-header">
    <h6>Dash+ Users</h6>
  </div>
  <div class="card-body">
    <app-list [items]="users" [actions]="actions" [layout]="layout" (action)="handleAction($event)"></app-list>
  </div>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add New User</h4>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form [formGroup]="form">
      <div class="modal-body">
        <div class="form-row">
          <input type="hidden" formControlName="clientId" class="form-control" />
          <input type="hidden" formControlName="email" *ngIf="!isNew" />

          <div class="row">
            <div class="col">
              <label class="lead" for="firstName">First name </label>
              <input type="text" formControlName="firstName" id="firstName"
                     class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
              <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors?.required">
                  First name is required
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <label class="lead" for="lastName">Last name</label>
              <input type="text"
                     formControlName="lastName" id="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
              <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors?.required">Last name is required</div>
              </div>
            </div>
          </div>
          <div class="row mt-3" *ngIf="isNew">
            <div class="col">
              <label class="lead" for="email">Email</label>
              <input type="email" formControlName="email" id="email" class="form-control" email [ngClass]="{ 'is-invalid': submitted && f.email.errors  }" />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors?.required">Email address is required</div>
                <div *ngIf="f.email.errors?.email">Invalid email address</div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <label class="lead" for="accountType">Role</label>
              <select
                required
                class="form-select"
                formControlName="accountType"
                [ngClass]="{ 'is-invalid': submitted && f.accountType.errors }"
              >
                <option value="">Choose Role</option>
                <option value="user">user</option>
                <option value="admin">admin</option>
              </select>
              <div
                *ngIf="submitted && f.accountType.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.accountType.errors?.required">
                  Role is required
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="form-group mt-3">
          <button [disabled]="loading" (click)="onSubmit(content)" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Save
          </button>
          <a (click)="modal.dismiss('Cancel click')" class="btn btn-link">Cancel</a>
        </div>
      </div>
    </form>
  </ng-template>
</div>
