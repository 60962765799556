import { ClientService } from '../../../_services/client.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { DropDownService } from '../../../_services/drop-down.service';
import { DropDown } from '../../../_models/drop-down';

@Component({
  selector: 'app-add-update-client',
  templateUrl: './add-update-client.component.html',
  styleUrls: ['./add-update-client.component.css']
})
export class AddUpdateClientComponent implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  users: DropDown[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
    private toastr: ToastrService,
    private dd: DropDownService
  ) {

    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.form = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      cx: ['', Validators.required],
      salesRep: [''],
      notes: ['']
    });
  }

  ngOnInit() {
    this.getUsers();
    if (!this.isAddMode) {
      this.clientService.find(this.id)
        .pipe(first())
        .subscribe(x => this.form.patchValue(x));
    }
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.createClient();
    } else {
      this.updateClient();
    }
  }

  private createClient() {
    this.clientService.add(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastr.success('Client added successfully');
          this.router.navigate(['/clients']);
        },
        error: error => {
          this.toastr.error(error);
          this.loading = false;
        }
      });
  }

  getUsers() {
    this.dd.users().subscribe(
      {
        next: (data) => {
          this.users = data;
        },
        error: (e) => {
          this.toastr.error('Unable to fetch users');
        }
      }
    );
  }


  private updateClient() {
    this.clientService.update(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastr.success('Client updated successfully');
          this.router.navigate(['/clients'], { relativeTo: this.route });
        },
        error: error => {
          this.toastr.error(error);
          this.loading = false;
        }
      });
  }

}
