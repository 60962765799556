<div class="card shadow-sm">
  <div class="card-header lead">
    <span>User History</span>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col">
    
        <app-list [showCreateNew]=false [layout]="layout" ></app-list>
      </div>

    </div>
  </div>
</div>
