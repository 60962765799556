import { Demographics } from '../../../_models/demographics';
import { SegmentForEdit } from '../../../_models/project-for-edit';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { DropDown } from '../../../_models/drop-down';
import { ProjectForEdit } from '../../../_models/project-for-edit';
import { AuthService } from '../../../_services/auth.service';
import { DropDownService } from '../../../_services/drop-down.service';
import { NavigationService } from '../../../_services/navigation.service';
import { ProjectService } from '../../../_services/project.service';
import { InstanceService } from '../../../_services/instance.service';
import { Constants } from '../../../_models/constants';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.css']
})
export class EditProjectComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  clients: any[] = [];
  vendors: any[] = [];
  instance: any;
  users: any[] = [];
  deadline = NgbDate.from({ year: 1789, month: 7, day: 14 });
  quotaStats: any;
  participants: any[] = [];
  errorMessage: string = '';
  hideClientSurveyEntrySigning = true;
  countryList = Constants.COUNTRY_LIST;

  otherProjects: any[] = [];
  projectId: string;
  project: any;
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    enableCheckAll: false,
    idField: 'value',
    textField: 'option',
    allowSearchFilter: true,
  };
  pII: DropDown[] = [];
  selectedPII: DropDown[] = [];
  selectedItems: DropDown[] = [];
  dropdownList = [];
  languages: any[] = [];
  quotaMet = false;
  dashCodePrefix: string = Constants.DASHCODE_PREFIX;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private toastr: ToastrService,
    public auth: AuthService,
    private dd: DropDownService,
    private navigation: NavigationService,
    public instanceService: InstanceService,
  ) {
    this.projectId = this.route.snapshot.params['id'];

    this.form = this.formBuilder.group({
      id: ['', Validators.required],
      projectName: ['', Validators.required],
      dashCode: [''],
      country: ['', Validators.required],
      languageCode: ['', Validators.required],
      projectStatus: ['', Validators.required],
      category: ['', Validators.required],
      clientId: ['', Validators.required],
      projectManager: ['', Validators.required],
      salesRep: ['', Validators.required],
      deadline: ['', Validators.required],
      liveUrl: ['', Validators.required],
      testUrl: ['', Validators.required],
      tuneUrl: [''],
      softLaunchQuota: [0, Validators.required], //
      enableQuotas: [false, Validators.required],
      quotaByStarts: [false, Validators.required],
      sellCPI: [0, Validators.required], //
      lOI: [0, Validators.required],
      estimateIR: [1, Validators.required],
      autogenReferralCode: [false, Validators.required],
      fraudCheckType: ['Standard', Validators.required],
      dupeOnParticpant: [true, Validators.required],
      require_cx: [false, Validators.required],
      override_cx: [''],
      fraudThreshold: [{ value: 25, disabled: false }, Validators.required],
      dupeOtherProjectsOnParticpant: [false, Validators.required],
      dupeOtherProjectsOnComplete: [false, Validators.required],
      dupeOtherProjectsOnStart: [false, Validators.required],
      dupeOtherProjectsOnQC: [false, Validators.required],
      dupeOtherProjectsOnTerm: [false, Validators.required],
      dupeOtherProjectsOnOQ: [false, Validators.required],
      dedupeOtherProjects: [[]],
      fullLaunchQuota: ['', Validators.required],
      enableUniqueIds: [false],
      uniqueIds: [[]],
      enableTargetVIDs: [false],
      targetVIDs: [[]],
      idParameter: [''],
      deviceType: ['All'],
      sampleIds: [''],
      sampleTargetVIDs: [''],
      demographics: [
        {
          targets: [],
          quotas: [],
          enablePrescreenerQuotas: false,
          zips: [],
          ageTarget: {
            target: {
              min: 0,
              max: 0,
            },
            buckets: [],
          },
        },
      ],
      segments: this.formBuilder.array([]),
      visibleToClient: [false, Validators.required],
      enableClientSurveyEntrySigning: [false],
    });

    this.pII = [
      { option: 'Name', value: 'piiName' },
      { option: 'First Name', value: 'piiFirstName' },
      { option: 'Email', value: 'piiEmail' },
      { option: 'Email Validation', value: 'piiEmailValidation' },
      { option: 'Address', value: 'piiAddress' },
      { option: 'State', value: 'piiState' },
      { option: 'Zip +', value: 'piiZipPlusSix' },
      { option: 'Phone', value: 'piiPhone' },
      { option: 'Phone Validation', value: 'piiPhoneValidation' },
    ];
  }

  ngOnInit() {
    this.getInstance();
    this.findProjectForEdit();
    this.getClients();
    this.getVendors();
    this.getUsers();
    this.getOtherProjects();
    this.getLanguages();
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    let model = this.form.value;

    if (
      this.isQuotaMet(model.fullLaunchQuota) &&
      model.projectStatus === 'Full Launch' &&
      this.project.projectStatus !== 'Full Launch' &&
      this.project.enableQuotas
    ) {
      this.errorMessage =
        'Cannot set status to Live because the quota has been met.';
      return;
    }

    if (
      model.segments.some((segment) => segment.buyCPI > 500) &&
      this.auth.user.userDetails.dash == false
    ) {
      if (
        window.confirm(
          'Buy CPI for one or more vendors is over $5. Continue?',
        ) == false
      ) {
        return;
      }
    }

    if (model.targetVIDs.length > 0 && model.enableTargetVIDs == false) {
      if (
        window.confirm(
          'Target VIDS are disabled but VIDS have been uploaded. Are you sure you want to continue?',
        ) == false
      ) {
        return;
      }
    }

    if (model.targetVIDs.length == 0 && model.enableTargetVIDs == true) {
      if (
        window.confirm(
          'Target VIDS are enabled, but no VIDS have been uploaded. Are you sure you want to continue?',
        ) == false
      ) {
        return;
      }
    }

    if (
      model.segments.some(
        (segment) =>
          segment.vendorId === '5eb4bb47-edfb-482d-8c90-883dc4bcf6b5',
      ) &&
      model.clientId === '6564f442-da88-4b14-a16f-84898d575bfb'
    ) {
      this.errorMessage =
        'SAGO API cannot be selected as a vendor if SAGO is the client.';
      return;
    }

    this.loading = true;

    let uids = model.uniqueIds.slice();

    if (model.uniqueIds.length < 6) {
      model.sampleIds = uids.splice(0, uids.length).join(' </br> </br>');
    } else {
      model.sampleIds = uids.splice(0, 5).join('  </br> </br>');
    }
    let vids = model.targetVIDs.slice();
    if (model.targetVIDs.length < 6) {
      model.sampleTargetVIDs = vids.splice(0, vids.length).join(' </br> </br>');
    } else {
      model.sampleTargetVIDs = vids.splice(0, 5).join('  </br> </br>');
    }

    model.piiName = this.selectedPII.some((pii) => pii.value === 'piiName');
    model.piiFirstName = this.selectedPII.some(
      (pii) => pii.value === 'piiFirstName',
    );
    model.piiEmail = this.selectedPII.some((pii) => pii.value === 'piiEmail');
    model.piiEmailValidation = this.selectedPII.some(
      (pii) => pii.value === 'piiEmailValidation',
    );
    model.piiAddress = this.selectedPII.some(
      (pii) => pii.value === 'piiAddress',
    );
    model.piiState = this.selectedPII.some((pii) => pii.value === 'piiState');
    model.piiZipPlusSix = this.selectedPII.some(
      (pii) => pii.value === 'piiZipPlusSix',
    );
    model.piiPhone = this.selectedPII.some((pii) => pii.value === 'piiPhone');
    model.piiPhoneValidation = this.selectedPII.some(
      (pii) => pii.value === 'piiPhoneValidation',
    );
    this.projectService
      .update(model)
      .pipe(first())
      .subscribe({
        next: () => {
          // get return url from query parameters or default to home page
          //const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
          this.toastr.success('Project updated');
          this.router.navigate(['/projects/view/' + this.project.id]);
        },
        error: (error) => {
          this.toastr.warning(error);
          this.loading = false;
        },
      });
  }

  onQuotaChange(newQuota: number) {
    this.quotaMet = this.isQuotaMet(newQuota);
  }

  get f() {
    return this.form.controls;
  }

  get getsegments(): FormArray {
    return this.form.get('segments') as FormArray;
  }

  isQuotaMet(quota: number): boolean {
    let liveQuota =
      this.project?.fullLaunchQuota === quota
        ? this.project?.fullLaunchQuota
        : quota;

    if (this.project?.quotaByStarts) {
      return (
        this.participants.filter(
          (s) =>
            !s.surveyStatus.toLowerCase().includes('overquota') &&
            !s.surveyStatus.toLowerCase().includes('duplicate'),
        ).length >= liveQuota && this.project?.fullLaunchQuota > 0
      );
    } else {
      return (
        this.participants.filter((s) =>
          s.surveyStatus.toLowerCase().includes('complete'),
        ).length >= liveQuota && this.project?.fullLaunchQuota > 0
      );
    }
  }

  getQuotaStats(vendorId?: string) {
    if (vendorId) {
      return (
        'S: ' +
        this.participants.filter(
          (s) =>
            !s.surveyStatus.toLowerCase().includes('overquota') &&
            !s.surveyStatus.toLowerCase().includes('duplicate') &&
            s.vendorId === vendorId,
        ).length +
        '; C: ' +
        this.participants.filter(
          (s) =>
            s.surveyStatus.toLowerCase().includes('complete') &&
            s.vendorId === vendorId,
        ).length
      );
    } else {
      return (
        'S: ' +
        this.participants.filter(
          (s) =>
            !s.surveyStatus.toLowerCase().includes('overquota') &&
            !s.surveyStatus.toLowerCase().includes('duplicate'),
        ).length +
        '; C: ' +
        this.participants.filter((s) =>
          s.surveyStatus.toLowerCase().includes('complete'),
        ).length
      );
    }
  }

  getParticipants() {
    this.projectService.projectParticipants(this.projectId).subscribe(
      (data) => {
        this.participants = data;
      },
      (error) => {
        this.toastr.error('Failed to fetch participants');
      },
      () => {
        this.quotaStats = this.getQuotaStats();
        this.quotaMet = this.isQuotaMet(this.project?.fullLaunchQuota);
      },
    );
  }

  getInstance() {
    this.instanceService
      .getInstance(this.auth.user?.userDetails?.instanceId.toString())
      .subscribe(
        (data) => {
          this.instance = data;
        },
        (error) => {
          this.toastr.error('Failed to fetch instance');
        },
      );
  }

  private findProjectForEdit() {
    this.projectService.findForEdit(this.projectId).subscribe(
      (data) => {
        this.project = data;
      },
      (error) => {
        this.toastr.error('Unable to find project');
        this.router.navigate(['/projects']);
      },
      () => {
        // this.toastr.success('Fetched instances');

        this.selectedItems = this.otherProjects?.filter((f) =>
          this.project?.dedupeOtherProjects?.includes(f.value),
        );

        this.project?.piiName
          ? this.selectedPII.push({ option: 'Name', value: 'piiName' })
          : '';
        this.project?.piiFirstName
          ? this.selectedPII.push({
              option: 'First Name',
              value: 'piiFirstName',
            })
          : '';
        this.project?.piiEmail
          ? this.selectedPII.push({ option: 'Email', value: 'piiEmail' })
          : '';
        this.project?.piiEmailValidation
          ? this.selectedPII.push({
              option: 'Email Validation',
              value: 'piiEmailValidation',
            })
          : '';
        this.project?.piiAddress
          ? this.selectedPII.push({ option: 'Address', value: 'piiAddress' })
          : '';
        this.project?.piiState
          ? this.selectedPII.push({ option: 'State', value: 'piiState' })
          : '';
        this.project?.piiZipPlusSix
          ? this.selectedPII.push({ option: 'Zip +', value: 'piiZipPlusSix' })
          : '';
        this.project?.piiPhone
          ? this.selectedPII.push({ option: 'Phone', value: 'piiPhone' })
          : '';
        this.project?.piiPhoneValidation
          ? this.selectedPII.push({
              option: 'Phone Validation',
              value: 'piiPhoneValidation',
            })
          : '';
        this.selectedPII = [...this.selectedPII];

        this.bindForm();
        this.getParticipants();
      },
    );
  }

  bindForm() {
    if (this.project) {
      this.setDate(this.project.deadline);

      this.dashCodePrefix =
        this.instance?.dashCodePrefix !== '' &&
        this.instance?.dashCodePrefix !== undefined
          ? this.instance.dashCodePrefix
          : Constants.DASHCODE_PREFIX;
      this.form.controls.id.setValue(this.project.id);
      this.form.controls.projectName.setValue(this.project.projectName);
      this.form.controls.dashCode.setValue(this.project.dashCode);
      this.form.controls.dashCode.setValidators(
        Validators.pattern(`^${this.dashCodePrefix}\\d{4,6}$`),
      );

      this.form.controls.country.setValue(this.project.country);
      this.form.controls.languageCode.setValue(this.project.languageCode);
      this.form.controls.projectStatus.setValue(this.project.projectStatus);

      // If project status value is not Test
      // Add Validators.Required to dashCode form control
      this.form.controls.projectStatus.valueChanges.subscribe((x) => {
        if (x !== 'Test') {
          this.form.controls.dashCode.addValidators([Validators.required]);
        } else {
          this.form.controls.dashCode.removeValidators([Validators.required]);
        }

        this.form.controls.dashCode.updateValueAndValidity();
      });

      // Set the projectStatus value again to trigger valueChanges
      this.form.controls.projectStatus.setValue(this.project.projectStatus);

      this.form.controls.category.setValue(this.project.category);
      this.form.controls.clientId.setValue(this.project.clientId);
      this.form.controls.projectManager.setValue(this.project.projectManager);
      this.form.controls.salesRep.setValue(this.project.salesRep);
      this.form.controls.deadline.setValue(this.project.deadline);
      this.form.controls.liveUrl.setValue(this.project.liveUrl);
      this.form.controls.testUrl.setValue(this.project.testUrl);
      this.form.controls.tuneUrl.setValue(this.project.tuneUrl);
      this.form.controls.fullLaunchQuota.setValue(this.project.fullLaunchQuota);
      this.form.controls.softLaunchQuota.setValue(this.project.softLaunchQuota);
      this.form.controls.enableQuotas.setValue(this.project.enableQuotas);
      this.form.controls.quotaByStarts.setValue(this.project.quotaByStarts);
      this.form.controls.sellCPI.setValue(this.project.sellCPI);
      this.form.controls.lOI.setValue(this.project.loi);

      this.form.controls.estimateIR.setValue(this.project.estimateIR);
      this.form.controls.autogenReferralCode.setValue(
        this.project.autogenReferralCode,
      );
      this.form.controls.fraudCheckType.setValue(this.project.fraudCheckType);
      this.form.controls.require_cx.setValue(this.project.require_cx ?? false);
      this.form.controls.override_cx.setValue(this.project.override_cx ?? '');
      this.form.controls.dupeOtherProjectsOnParticpant.setValue(
        this.project.dupeOtherProjectsOnParticpant,
      );
      this.form.controls.dupeOtherProjectsOnComplete.setValue(
        this.project.dupeOtherProjectsOnComplete,
      );
      this.form.controls.dupeOtherProjectsOnStart.setValue(
        this.project.dupeOtherProjectsOnStart,
      );
      this.form.controls.dupeOtherProjectsOnQC.setValue(
        this.project.dupeOtherProjectsOnQC,
      );
      this.form.controls.dupeOtherProjectsOnTerm.setValue(
        this.project.dupeOtherProjectsOnTerm,
      );
      this.form.controls.dupeOtherProjectsOnOQ.setValue(
        this.project.dupeOtherProjectsOnOQ,
      );
      this.form.controls.dedupeOtherProjects.setValue(
        this.project.dedupeOtherProjects,
      );

      this.form.controls.enableUniqueIds.setValue(this.project.enableUniqueIds);
      this.form.controls.enableTargetVIDs.setValue(
        this.project.enableTargetVIDs,
      );
      this.form.controls.idParameter.setValue(this.project.idParameter);
      this.form.controls.deviceType.setValue(this.project.deviceType);
      this.form.controls.demographics.setValue(this.project.demographics);
      this.form.controls.sampleIds.setValue(this.project.sampleIds);
      this.form.controls.sampleTargetVIDs.setValue(
        this.project.sampleTargetVIDs,
      );
      this.form.controls.visibleToClient.setValue(this.project.visibleToClient);
      this.form.controls.enableClientSurveyEntrySigning.setValue(
        this.project.enableClientSurveyEntrySigning,
      );

      this.project.segments.forEach((item: SegmentForEdit) => {
        (<FormArray>this.form.get('segments')).push(
          this.addExistingVendorFormGroup(item),
        );
      });

      this.clientChange();
    }
  }

  addVendor() {
    (<FormArray>this.form.get('segments')).push(this.addVendorFormGroup());
  }

  addExistingVendorFormGroup(segment: SegmentForEdit): FormGroup {
    let hasstarts = false;
    if (segment.starts > 0) {
      hasstarts = true;
    }
    return this.formBuilder.group({
      id: [segment.id],
      starts: [segment.starts],
      vendorId: [segment.vendorId, Validators.required],
      vendorSoftLaunchQuota: [
        segment.vendorSoftLaunchQuota,
        Validators.required,
      ],
      vendorFullLaunchQuota: [
        segment.vendorFullLaunchQuota,
        Validators.required,
      ],
      buyCPI: [segment.buyCPI, Validators.required],
      // totalVendorCost: [segment.totalVendorCost, Validators.required],
      segmentStatus: [segment.segmentStatus, Validators.required],
    });
  }

  addVendorFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      starts: [0],
      vendorId: ['', Validators.required],
      vendorSoftLaunchQuota: [0, Validators.required],
      vendorFullLaunchQuota: [0, Validators.required],
      buyCPI: [0, Validators.required],
      segmentStatus: ['', Validators.required],
      //totalVendorCost: [0, Validators.required],
    });
  }

  getClients() {
    this.dd.clients().subscribe(
      (data) => {
        this.clients = data;
      },
      (error) => {
        this.toastr.error('Unable to fetch clients');
      },
      () => {
        // this.toastr.success('Fetched instances');
      },
    );
  }

  getVendors() {
    this.dd.vendors().subscribe(
      (data) => {
        this.vendors = data;
      },
      (error) => {
        this.toastr.error('Unable to fetch vendors');
      },
      () => {
        // this.toastr.success('Fetched instances');
      },
    );
  }

  getUsers() {
    this.dd.users().subscribe(
      (data) => {
        this.users = data;
      },
      (error) => {
        this.toastr.error('Unable to fetch users');
      },
      () => {
        // this.toastr.success('Fetched instances');
      },
    );
  }

  getLanguages() {
    this.dd.languagesList().subscribe(
      (data) => {
        this.languages = data;
      },
      (error) => {
        this.toastr.error('Unable to fetch languages');
      },
      () => {},
    );
  }

  removeVendor(index: number): void {
    (<FormArray>this.form.get('segments')).removeAt(index);
  }

  projectStatusChange(target: any) {
    (<FormArray>this.form.get('segments')).controls.forEach((item) => {
      item.get('segmentStatus')?.setValue(target.value);
    });
  }

  getOtherProjects() {
    this.dd.projects().subscribe(
      (data) => {
        this.otherProjects = data;
      },
      (error) => {
        this.toastr.error('Unable to fetch other projects');
      },
      () => {
        // this.toastr.success('Fetched instances');
        this.selectedItems = this.otherProjects?.filter((f) =>
          this.project?.dedupeOtherProjects?.includes(f.value),
        );
      },
    );
  }

  onDateSelect(event: any) {
    this.form
      .get('deadline')
      ?.setValue(event.year + '-' + event.month + '-' + event.day);
  }

  setDate(dateString: string) {
    let date = dateString.split('-');
    this.deadline = NgbDate.from({
      year: parseInt(date[0], 10),
      month: parseInt(date[1], 10),
      day: parseInt(date[2], 10),
    });
  }

  onPiiSelect(item: any) {
    if (item.value === 'piiEmailValidation') {
      if (!this.selectedPII.some((pii) => pii.value === 'piiEmail')) {
        this.selectedPII.push({ option: 'Email', value: 'piiEmail' });
        this.selectedPII = [...this.selectedPII];
      }
    }

    if (item.value === 'piiPhoneValidation') {
      if (!this.selectedPII.some((pii) => pii.value === 'piiPhone')) {
        this.selectedPII.push({ option: 'Phone', value: 'piiPhone' });
        this.selectedPII = [...this.selectedPII];
      }
    }
  }

  onPiiDeSelect(item: any) {
    if (item.value === 'piiEmail') {
      if (this.selectedPII.some((pii) => pii.value === 'piiEmailValidation')) {
        let piiEmail = this.selectedPII.findIndex(
          (pii) => pii.value === 'piiEmailValidation',
        );
        this.selectedPII.splice(piiEmail, 1);
        this.selectedPII = [...this.selectedPII];
      }
    }

    if (item.value === 'piiPhone') {
      if (this.selectedPII.some((pii) => pii.value === 'piiPhoneValidation')) {
        let piiPhone = this.selectedPII.findIndex(
          (pii) => pii.value === 'piiPhoneValidation',
        );
        this.selectedPII.splice(piiPhone, 1);
        this.selectedPII = [...this.selectedPII];
      }
    }
  }

  onItemSelect(item: any) {
    //just take ids for form
    this.form.get('dedupeOtherProjects')?.setValue(
      this.selectedItems.map(function (item) {
        return item?.value;
      }),
    );
  }

  onDeSelect(items: any) {
    //just take ids for form
    this.form.get('dedupeOtherProjects')?.setValue(
      this.selectedItems.map(function (item) {
        return item?.value;
      }),
    );
  }

  onCancel() {
    this.navigation.back();
  }

  targetVIDSUploadToggle() {
    if (this.f.enableTargetVIDs.value == true) {
      return { 'pointer-events': 'auto' };
    } else this.f.enableTargetVIDs.value == false;
    {
      return { 'pointer-events': 'none', opacity: 0.5 };
    }
  }

  clientChange() {
    if (
      this.form.get('clientId')?.value ===
      'e842ba3f-5ba3-4d4c-b18a-81e4c62f7798'
    ) {
      // If Big Village, show hash checkbox
      this.hideClientSurveyEntrySigning = false;
    } else {
      this.hideClientSurveyEntrySigning = true;
    }
  }
}
