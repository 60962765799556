import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NubbleService } from 'src/app/_services/nubble.service';

@Component({
  selector: 'app-user-locations-list',
  templateUrl: './user-locations-list.component.html',
  styleUrls: ['./user-locations-list.component.css']
})
export class UserLocationsListComponent implements OnInit {

  @Input() userId: string;
  locationHistoryList: any[] = [];

  isLoading: boolean = false;
  isLoaded: boolean = false;

  cardTitle: string = 'Location History List';

  layout: any[] = [
    { label: 'Venue Name', id: 'location', type: 'text' },
    { label: 'Address', id: 'address', type: 'text' },
    { label: 'Time', id: 'time', type: 'onlydate' }
  ];

  constructor(private nubbleService: NubbleService, private toastr: ToastrService) { }

  ngOnInit() {}

  listLocationHistory() {
    this.isLoading = true;
    this.nubbleService.userListLocationHistory(this.userId).subscribe((data) => {
        this.locationHistoryList = data;
      },
      (error) => {
        this.isLoaded = true;
        this.isLoading = false;
        this.toastr.warning(error);
      },
      () => {
        this.isLoaded = true;
        this.isLoading = false;
      }
    );
  }
}
