import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '../../../_services/project.service';

@Component({
  selector: 'app-project-performance',
  templateUrl: './project-performance.component.html',
  styleUrls: ['./project-performance.component.css'],
})
export class ProjectPerformanceComponent implements OnInit {
  @Input() participants: any[] = [];
  totals: any;
  byvendor: any[] = [];
  byday: any[] = [];
  lastComplete: Date;

  constructor(
    private projectService: ProjectService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.totals = this.Totals();
    this.byvendor = this.ByVendor();
    this.byday = this.ByDayByVendor();

    const completes = this.participants
      .filter((x) => x.surveyStatus.toLowerCase() === 'complete')
      .sort((a, b) => {
        const dateA = new Date(a.outTime || new Date()).getTime();
        const dateB = new Date(b.outTime || new Date()).getTime();
        return dateB - dateA;
      });

    if (completes && completes.length > 0) {
      this.lastComplete = completes[0].outTime;
      console.log(this.lastComplete);
    }
  }

  Totals() {
    return this.rowPerformance(this.participants);
  }

  ByVendor() {
    // console.log('by vendor');
    if (!this.participants.length) {
      return [];
    }
    let grouped = this.groupBy('vendorId', this.participants);
    let list: any[] = [];
    grouped.forEach((element) => {
      list.push(this.rowPerformance(element.value));
    });
    return list;
  }

  ByDayByVendor() {
    if (!this.participants.length) {
      return [];
    }

    let list: any[] = [];
    //group paticipants by day
    let grouped = this.groupBy('day', this.participants);

    grouped.forEach((element) => {
      //get performance for day
      const day = this.rowPerformance(element.value);
      //group participants for day by vendor
      const dayVendors = this.groupBy('vendorId', element.value);
      dayVendors.forEach((vendors) => {
        day.vendors.push(this.rowPerformance(vendors.value));
      });

      list.push(day);
    });
    return list;
  }

  rowPerformance(parList: any[]) {
    const vendors: any[] = [];

    return {
      day: parList[0]?.day,
      vendor: parList[0]?.vendorName,
      entries: parList.filter((s) =>
        s.surveyStatus.toLowerCase().includes('entry'),
      ).length,
      starts: this.getStarts(parList).length,
      completes: parList.filter((s) =>
        s.surveyStatus.toLowerCase().includes('complete'),
      ).length,
      oq: parList.filter((s) =>
        s.surveyStatus.toLowerCase().includes('overquota'),
      ).length,
      fraud: parList.filter((s) =>
        s.surveyStatus.toLowerCase().includes('fraud'),
      ).length,
      dupes: parList.filter((s) =>
        s.surveyStatus.toLowerCase().includes('duplicate'),
      ).length,
      clientOQ: parList.filter((s) =>
        s.surveyStatus.toLowerCase().includes('oq'),
      ).length,
      clientQC: parList.filter((s) =>
        s.surveyStatus.toLowerCase().includes('qc'),
      ).length,
      clientTerms: parList.filter((s) =>
        s.surveyStatus.toLowerCase().includes('terminate'),
      ).length,
      resolved: this.calculateResolved(parList),
      ir: this.calculateIR(parList),
      dor: this.calculateDOR(parList),
      medianLoi: this.calculateMedianLOI(parList),
      cost: this.calculateCost(parList),
      vendors: vendors, //
    };
  }

  calculateResolved(parList: any[]): number {
    return parList.filter(
      (s) =>
        s.surveyStatus.toLowerCase().includes('oq') ||
        s.surveyStatus.toLowerCase().includes('qc') ||
        s.surveyStatus.toLowerCase().includes('terminate') ||
        s.surveyStatus.toLowerCase().includes('complete'),
    ).length;
  }

  calculateIR(parList: any[]): number {
    const completes = parList.filter((s) =>
      s.surveyStatus.toLowerCase().includes('complete'),
    ).length;
    if (completes < 1) {
      return 0;
    }
    const terms = parList.filter((s) =>
      s.surveyStatus.toLowerCase().includes('terminate'),
    ).length;
    return completes / (terms + completes);
  }

  calculateDOR(parList: any[]): number {
    const resolved = this.calculateResolved(parList);
    const starts = this.getStarts(parList).length;

    if (starts < 1) {
      return 0;
    }

    if (resolved < 1) {
      return 1;
    }

    if (resolved >= starts) {
      return 0;
    }

    return (starts - resolved) / starts;
  }

  calculateMedianLOI(parList: any[]): number {
    let values = parList
      .filter((s) => s.surveyStatus.toLowerCase().includes('complete'))
      .map((n) => n.loi);
    if (values.length === 0) return 0;

    values.sort(function (a, b) {
      return a - b;
    });

    var half = Math.floor(values.length / 2);

    if (values.length % 2) return values[half];

    return Math.floor((values[half - 1] + values[half]) / 2.0);
  }

  calculateCost(parList: any[]): number {
    return parList
      .filter((s) => s.surveyStatus.toLowerCase().includes('complete'))
      .reduce((sum, current) => sum + current.buyCPI, 0);
  }

  groupBy(key: string, arr: any[]): any[] {
    const groupedObj = arr.reduce((prev, cur) => {
      if (!prev[cur[key]]) {
        prev[cur[key]] = [cur];
      } else {
        prev[cur[key]].push(cur);
      }
      return prev;
    }, {});
    const source = Object.keys(groupedObj).map((key) => ({
      key,
      value: groupedObj[key],
    }));
    return source;
  }

  getStarts(parList: any[]) {
    return parList.filter(
      (s) =>
        s.surveyStarted ||
        (!s.surveyStatus.toLowerCase().includes('entry') &&
          !s.surveyStatus.toLowerCase().includes('overquota') &&
          !s.surveyStatus.toLowerCase().includes('duplicate') &&
          // exclude any pre-start fraud
          !s.surveyStatus.toLowerCase().includes('fraud') &&
          !s.note.includes('cx')),
    );
  }
}
