import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { FraudMarkerEditIsReviewed } from 'src/app/_models/fraud-marker-edit-is-reviewed';
import { PrettyPrintPipe } from 'src/app/_pipes/prettyPrintPipe.pipe';
import { NubbleService } from 'src/app/_services/nubble.service';
import { FraudService } from '../../../_services/fraud.service';

@Component({
  selector: 'app-user-fraud-flags',
  templateUrl: './user-fraud-flags.component.html',
  styleUrls: ['./user-fraud-flags.component.scss'],
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .dark-modal .modal-content {
      background-color: #292b2c;
      color: white;
    }
    .dark-modal .close {
      color: white;
    }
    .light-blue-backdrop {
      background-color: #5cb3fd;
    }
  `],
  providers: [ PrettyPrintPipe ]
})

export class UserFraudFlagsComponent implements OnInit {

  @Input() user: any;
  @Output() updatedUser = new EventEmitter<any>();

  userStatus: string = '';

  isLoaded: boolean = false;

  fraudMarkerIsReviewed: FraudMarkerEditIsReviewed;
  cardTitle: string = 'Fraud Settings';

  actions: any[] = [];

  ticksToDate: any;
  date: any;

  constructor(private modalService: NgbModal,
              private fraudService: FraudService,
              private nubbleService: NubbleService,
              private clipboardApi: ClipboardService,
              private toastr: ToastrService) {}

  ngOnInit() {

    this.userFind(this.user.userId);
    this.statusHandler(this.user);
    this.isLoaded = true;

    this.ticksToDate = require('ticks-to-date');
    this.date = this.ticksToDate(this.user.coolingOffDeadline);
  }

  statusHandler(user: any) {
    this.setStatus(user.fraudBattery, user.excludeFromFraudBatteryCheck, 'fraudBatteryStatus');
    this.setStatus(user.fraudLocation, user.excludeFromFraudLocationCheck, 'fraudLocationStatus');
    this.setStatus(user.fraudSurvey, user.excludeFromFraudSurveyCheck, 'fraudSurveyStatus');
    this.setStatus(user.fraudHome, user.excludeFromFraudHomeCheck, 'fraudHomeStatus');
    this.setStatus(user.fraudApp, user.excludeFromFraudAppsCheck, 'fraudAppsStatus');
    this.setStatus(user.fraudEmailDomain, user.excludeFromFraudEmailDomainCheck, 'fraudEmailDomainStatus');
    this.setStatus(user.fraudMultipleEmails, user.excludeFromFraudMultipleEmailsCheck, 'fraudMultipleEmailsStatus');
    this.setStatus(user.fraudPhoneManufacturer, user.excludeFromFraudPhoneManufacturerCheck, 'fraudPhoneManufacturerStatus');
    this.setStatus(user.fraudPhoneNumber, user.excludeFromFraudPhoneNumberCheck, 'fraudPhoneNumberStatus');
    this.setStatus(user.fraudOnboarding, false, 'fraudOnboardingStatus');
  }

  setStatus(value: boolean, isExcluded: boolean, statusProp: string) {

    if (value == false && isExcluded == false) {
      this.user[statusProp] = 'Okay';
    }
    else if (value == true  && isExcluded == false) {
      this.user[statusProp] = 'In Breach';
    }
    else if (value == false && isExcluded == true) {
      this.user[statusProp] = 'Excluded';
    }
    else if (value == true && isExcluded == true) {
      this.user[statusProp] = 'Excluded';
    }
  }

  setReviewStatus() {

    if (this.user.permanentlyBanned == 1) {
      this.userStatus = 'Permanently Banned';

      return 'badge badge-outline-dark bg-danger text-light';

    } else if (this.user.permanentlyBanned == 0 && this.user.suspectedOfFraud == true) {
      this.userStatus = 'Suspected Of Fraud';

      return 'badge badge-outline-dark bg-warning text-light';

    } else if (this.user.permanentlyBanned == 0 && this.user.suspectedOfFraud == false && this.user.needsReviewing == 1) {
      this.userStatus = 'Needs Reviewing';

      return 'badge badge-outline-dark bg-secondary text-light';

    } else if (this.user.permanentlyBanned == 0 && this.user.suspectedOfFraud == false
              && this.user.needsReviewing == 0 && this.ticksToDate(this.user.coolingOffDeadline) > new Date()) {

      this.date = this.ticksToDate(this.user.coolingOffDeadline);
      this.userStatus = 'Cooling Off until ' + this.date.getUTCDate() + '/' + (this.date.getUTCMonth() + 1) + '/' + this.date.getUTCFullYear();

      return 'badge badge-outline-dark bg-info text-light';

    } else if (this.user.permanentlyBanned == 0 && this.user.suspectedOfFraud == false
              && this.user.needsReviewing == 0 && this.ticksToDate(this.user.coolingOffDeadline) < new Date()) {
      this.userStatus = 'Okay';

      return 'badge badge-outline-dark bg-primary text-light';
    }

    else {
      this.userStatus = 'No Data';
      return ' badge badge-outline-dark bg-light text-dark';
    }
  }

  getStyle(status: string) {

    if (status == 'Okay') {
      return 'badge bg-success text-light';
    }
    else if (status == 'In Breach')
    {
      return 'badge bg-warning text-light';
    }
    else (status == 'Excluded')
      return 'badge bg-secondary text-light';
  }

  editUserStatus(attribute: string) {

    var fraudStatus = {userIds: [this.user.userId], permanentlyBanned: 0, suspectedOfFraud: false, needsReviewing: 0, setCooling: false};

    if (attribute == 'markOkay') {

      fraudStatus.permanentlyBanned = 0;
      fraudStatus.suspectedOfFraud = false;
      fraudStatus.needsReviewing = 0;
      fraudStatus.setCooling = true;
    }

    if (attribute == 'suspectedOfFraud') {

      fraudStatus.permanentlyBanned = 0;
      fraudStatus.suspectedOfFraud = true;
      fraudStatus.needsReviewing = 0;
      fraudStatus.setCooling = false;
    }

    if (attribute == 'permanentlyBanned') {

      fraudStatus.permanentlyBanned = 1;
      fraudStatus.suspectedOfFraud = true;
      fraudStatus.needsReviewing = 0;
      fraudStatus.setCooling = false;
    }

    this.fraudService.updateUserFraudStatus(fraudStatus).subscribe(data => {
    }, error =>{
      this.toastr.error('Error updating status');
    }, () => {
        //complete
        this.userFind(this.user.userId);
        this.toastr.success('Save completed');
    })
  }

  userFind(userId) {

    this.nubbleService.userfind(userId).subscribe(data => {
      this.user = data;

    }, error =>{
      this.toastr.error('Error reloading user');
    }, () => {
        //complete
        this.statusHandler(this.user);
        this.updateUser();
    })
  }

  updateUser() {
    this.updatedUser.emit(this.user);
  }

  editUserAttribute(action: any, attribute: string) {

    var fraudSetting = {userId: this.user.userId, attributeName: attribute, value: action.target.checked};

    this.fraudService.updateFraudAttribute(fraudSetting).subscribe(data => {
    }, error =>{
      this.toastr.error('Error editing attribute');
    }, () => {
        //complete
        this.statusHandler(this.user);
        this.user[attribute] = action.target.checked;

        this.toastr.success('Save completed');
    })
  }

  handleAction(content) {
    this.modalService.open(content, { centered: true, scrollable: true, size: 'lg' });
  }

  copyToClipboard(text) {
    this.clipboardApi.copyFromContent(text);
    this.toastr.info("JSON Copied to Clipboard")
  }

  // //Browser Alert: Verify Mark User as Fraud
  // verifyFraudMarkerIsReviewed(fraudMarkerEditIsReviewed,) {

  //   if (fraudMarkerEditIsReviewed.isReviewed == true) {

  //     if (window.confirm("Are you sure you would like to mark as reviewed?") == true) {

  //       this.markAsFraud("Marked as reviewed", "Failed to mark as reviewed", fraudMarkerEditIsReviewed);

  //     } else {

  //       /*
  //       Sets the row in the lists toggle value back to not reviewed and updates the item in the
  //         userfraudMarkers array to revert toggle
  //       */

  //         fraudMarkerEditIsReviewed.isReviewed = !fraudMarkerEditIsReviewed.isReviewed;
  //         this.ngOnInit();
  //     }
  //   }

  //   else if (fraudMarkerEditIsReviewed.isReviewed == false) {

  //     if (window.confirm("Are you sure you would like to unmark as reviewed?") == true) {

  //       this.markAsFraud("Unmarked as reviewed", "Failed to unmark as reviewed", fraudMarkerEditIsReviewed);

  //     } else {

  //       /*
  //         Sets the row in the lists toggle value back to reviewed and updates the item in the
  //         userfraudMarkers array to revert toggle
  //       */

  //         fraudMarkerEditIsReviewed.isReviewed = !fraudMarkerEditIsReviewed.isReviewed;
  //         this.ngOnInit();
  //     }
  //   }

  //   this.userFraudMarkers[fraudMarkerEditIsReviewed.fraudMarkerId] = fraudMarkerEditIsReviewed;
  // }

  //Toggle: Mark fraud marker as reviewed
  // markAsFraud(successMsg, failureMsg, fraudMarkerEditIsReviewed) {
  //   this.userFraudService.fraudMarkerEditIsReviewed(fraudMarkerEditIsReviewed).subscribe((data) => {},
  //   error =>{

  //     //this.user.personalEmailVerified = !this.user.personalEmailVerified;
  //     this.toastr.error(failureMsg);
  //   }, () => {
  //       this.toastr.success(successMsg);
  //       this.isLoaded = true;
  //   })
  // }
}
