import { VendorService } from '../../../_services/vendor.service';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-add-update-vendor',
  templateUrl: './add-update-vendor.component.html',
  styleUrls: ['./add-update-vendor.component.css'],
})
export class AddUpdateVendorComponent implements OnInit {
  form: FormGroup;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private vendorService: VendorService,
    private toastr: ToastrService
  ) {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.form = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      canEdit: [true, Validators.required],
      completeUrl: ['', Validators.required],
      termUrl: ['', Validators.required],
      oqUrl: ['', Validators.required],
      qcUrl: ['', Validators.required],
      signRedirects: [false, Validators.required],
      signingKey: [''],
    });

    if (!this.isAddMode) {
      this.vendorService
        .find(this.id)
        .pipe(first())
        .subscribe((x) => this.form.patchValue(x));
    }
  }

  ngOnInit() {}

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.createVendor();
    } else {
      this.updateVendor();
    }
  }

  private createVendor() {
    this.vendorService
      .add(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastr.success('Vendor added successfully');
          this.router.navigate(['/vendors']);
        },
        error: (error) => {
          this.toastr.error(error);
          this.loading = false;
        },
      });
  }

  private updateVendor() {
    this.vendorService
      .update(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastr.success('Vendor updated successfully');
          this.router.navigate(['/vendors'], { relativeTo: this.route });
        },
        error: (error) => {
          this.toastr.error(error);
          this.loading = false;
        },
      });
  }
}
