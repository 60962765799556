import { QuestionService } from '../../../_services/question.service';
import { DropDownService } from '../../../_services/drop-down.service';
import { ProjectService } from '../../../_services/project.service';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../_services/auth.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DropDown } from '../../../_models/drop-down';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstanceService } from '../../../_services/instance.service';
import { lastValueFrom } from 'rxjs';
import { Constants } from '../../../_models/constants';
import { ClientService } from '../../../_services/client.service';
import { Client } from '../../../_models/client';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css'],
})
export class AddProjectComponent implements OnInit {
  form: FormGroup;
  instance: any;
  loading = false;
  submitted = false;
  clients: any[] = [];
  vendors: any[] = [];
  users: any[] = [];
  otherProjects: any[] = [];
  questionsForDD: any[] = [];
  selectedQuestions: any[] = [];
  questions: any[] = [];
  targets: any[] = [];
  ageTarget: any;
  zips: any[] = [];
  languages: any[] = [];
  errorMessage: string = '';
  hideClientSurveyEntrySigning = true;
  dashCodePrefix: string = Constants.DASHCODE_PREFIX;
  countryList = Constants.COUNTRY_LIST;
  clientsList: Client[] = [];

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    enableCheckAll: false,
    idField: 'value',
    textField: 'option',
    allowSearchFilter: true,
  };
  pII: DropDown[] = [];
  selectedPII: DropDown[] = [];

  multiconfig = {
    displayKey: 'optionText', // if objects array passed which key to be displayed defaults to description
    search: false, // true/false for the search functionality defaults to false,
    height: 'auto', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: 'more', // text to be displayed when more than one items are selected like Option 1 + 5 more.
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search', // label thats displayed in search input,
    searchOnKey: 'optionText', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  selectedItems: DropDown[] = [];
  dropdownList = [];

  constructor(
    private formBuilder: FormBuilder,
    private questionService: QuestionService,
    private router: Router,
    private projectService: ProjectService,
    private toastr: ToastrService,
    public auth: AuthService,
    private modalService: NgbModal,
    private dd: DropDownService,
    public instanceService: InstanceService,
    public clientService: ClientService
  ) {
    this.form = this.formBuilder.group({
      projectName: ['', Validators.required],
      dashCode: [''],
      country: [null, Validators.required],
      languageCode: ['', Validators.required],
      category: ['', Validators.required],
      client: ['', Validators.required],
      salesRep: ['', Validators.required],
      deadline: ['', Validators.required],
      liveUrl: ['', Validators.required],
      testUrl: ['', Validators.required],
      tuneUrl: [''],
      fullLaunchQuota: [0, Validators.required], //
      softLaunchQuota: [0, Validators.required], //
      quotaByStarts: [false, Validators.required],
      enableQuotas: [false, Validators.required],
      sellCPI: [0, Validators.required], //
      lOI: [0, Validators.required],
      estimateIR: [1, Validators.required],
      autogenReferralCode: [false, Validators.required],
      fraudCheckType: ['Standard', Validators.required],
      require_cx: [false, Validators.required],
      override_cx: [''],
      // dupeOnParticpant: [true, Validators.required],
      forensicThreshold: [{ value: 25, disabled: false }, Validators.required],
      fraudThreshold: [{ value: 70, disabled: false }, Validators.required],
      researchDefenderThreshold: [
        { value: 70, disabled: false },
        Validators.required,
      ],
      dupeOtherProjectsOnParticpant: [false, Validators.required],
      dupeOtherProjectsOnComplete: [false, Validators.required],
      dupeOtherProjectsOnStart: [false, Validators.required],
      dupeOtherProjectsOnQC: [false, Validators.required],
      dupeOtherProjectsOnTerm: [false, Validators.required],
      dupeOtherProjectsOnOQ: [false, Validators.required],
      enableUniqueIds: [false],
      uniqueIds: [[]],
      idParameter: [''],
      enableTargetVIDs: [false],
      targetVIDs: [[]],

      deviceType: ['', Validators.required],
      dedupeOtherProjects: [[]],
      demographics: [
        {
          targets: [],
          enablePrescreenerQuotas: false,
          quotas: [],
          zips: [],
          ageTarget: {
            target: {
              min: 0,
              max: 0,
            },
            buckets: [],
          },
        },
      ],
      segments: this.formBuilder.array([this.addVendorFormGroup()]),
      visibleToClient: [false, Validators.required],
      enableClientSurveyEntrySigning: [false, Validators.required],
    });

    this.pII = [
      { option: 'Name', value: 'piiName' },
      { option: 'First Name', value: 'piiFirstName' },
      { option: 'Email', value: 'piiEmail' },
      { option: 'Email Validation', value: 'piiEmailValidation' },
      { option: 'Address', value: 'piiAddress' },
      { option: 'State', value: 'piiState' },
      { option: 'Zip +', value: 'piiZipPlusSix' },
      { option: 'Phone', value: 'piiPhone' },
      { option: 'Phone Validation', value: 'piiPhoneValidation' },
    ];
  }

  ngOnInit() {
    this.getInstance();
    this.getClients();
    this.getOtherProjects();
    this.getVendors();
    this.getUsers();
    this.getLanguages();
  }

  get f() {
    return this.form.controls;
  }

  async getInstance() {
    this.instance = await lastValueFrom(
      this.instanceService.getInstance(
        this.auth.user?.userDetails?.instanceId.toString(),
      ),
    );
    this.dashCodePrefix =
      this.instance?.dashCodePrefix !== '' &&
      this.instance?.dashCodePrefix !== undefined
        ? this.instance.dashCodePrefix
        : Constants.DASHCODE_PREFIX;
    this.form.controls.dashCode.setValidators(
      Validators.pattern(`^${this.dashCodePrefix}\\d{4,6}$`),
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let model = this.form.value;

    if (
      model.segments.some((segment) => segment.buyCPI > 500) &&
      this.auth.user.userDetails.dash == false
    ) {
      if (
        window.confirm(
          'Buy CPI for one or more vendors is over $5. Continue?',
        ) == false
      ) {
        return;
      }
    }

    if (model.targetVIDs.length > 0 && model.enableTargetVIDs == false) {
      if (
        window.confirm(
          'Target VIDS are disabled but VIDS have been uploaded, are you sure you want to continue?',
        ) == false
      ) {
        return;
      }
    }

    if (model.targetVIDs.length == 0 && model.enableTargetVIDs == true) {
      if (
        window.confirm(
          'Target VIDS are enabled but no VIDS have been uploaded, are you sure you want to continue?',
        ) == false
      ) {
        return;
      }
    }

    if (
      model.segments.some(
        (segment) =>
          segment.vendorId === '5eb4bb47-edfb-482d-8c90-883dc4bcf6b5',
      ) &&
      model.client.id === '6564f442-da88-4b14-a16f-84898d575bfb'
    ) {
      this.errorMessage =
        'SAGO API cannot be selected as a vendor if SAGO is the client.';
      return;
    }

    model.piiName = this.selectedPII.some((pii) => pii.value === 'piiName');
    model.piiFirstName = this.selectedPII.some(
      (pii) => pii.value === 'piiFirstName',
    );
    model.piiEmail = this.selectedPII.some((pii) => pii.value === 'piiEmail');
    model.piiEmailValidation = this.selectedPII.some(
      (pii) => pii.value === 'piiEmailValidation',
    );
    model.piiAddress = this.selectedPII.some(
      (pii) => pii.value === 'piiAddress',
    );
    model.piiState = this.selectedPII.some((pii) => pii.value === 'piiState');
    model.piiZipPlusSix = this.selectedPII.some(
      (pii) => pii.value === 'piiZipPlusSix',
    );
    model.piiPhone = this.selectedPII.some((pii) => pii.value === 'piiPhone');
    model.piiPhoneValidation = this.selectedPII.some(
      (pii) => pii.value === 'piiPhoneValidation',
    );

    model.clientId = model.client.id;

    this.loading = true;

    let uids = model.uniqueIds.slice();

    if (model.uniqueIds.length < 6) {
      model.sampleIds = uids.splice(0, uids.length).join(' </br> </br>');
    } else {
      model.sampleIds = uids.splice(0, 5).join('  </br> </br>');
    }

    let vids = model.targetVIDs.slice();

    if (model.targetVIDs.length < 6) {
      model.sampleTargetVIDs = vids.splice(0, vids.length).join(' </br> </br>');
    } else {
      model.sampleTargetVIDs = vids.splice(0, 5).join('  </br> </br>');
    }

    this.projectService.add(model).subscribe(
      (data) => {
        let project: any = data;
        this.toastr.success('Project updated');
        this.router.navigate(['/projects/view/' + project.id]);
      },
      (error) => {
        this.toastr.warning(error);
        this.loading = false;
      },
      () => {},
    );
  }

  get getsegments(): FormArray {
    return this.form.get('segments') as FormArray;
  }

  addVendor() {
    (<FormArray>this.form.get('segments')).push(this.addVendorFormGroup());
  }

  addVendorFormGroup(): FormGroup {
    return this.formBuilder.group({
      vendorId: ['', Validators.required],
      vendorSoftLaunchQuota: [0, Validators.required],
      vendorFullLaunchQuota: [0, Validators.required],
      buyCPI: [0, Validators.required],
    });
  }

  getClients() {
    this.clientService.list().subscribe(
      {
        next: (data) => {
          this.clientsList = data;
        },
        error: (e) => {
          this.toastr.error('Unable to fetch clients');
        }

      }
    )
  }

  getUsers() {
    this.dd.users().subscribe(
      (data) => {
        this.users = data;
      },
      (error) => {
        this.toastr.error('Unable to fetch users');
      },
      () => {
        //this.toastr.success('Fetched instances');
      },
    );
  }

  getVendors() {
    this.dd.vendors().subscribe(
      (data) => {
        this.vendors = data;
      },
      (error) => {
        this.toastr.error('Unable to fetch vendors');
      },
      () => {
        // this.toastr.success('Fetched instances');
      },
    );
  }

  getLanguages() {
    this.dd.languagesList().subscribe(
      (data) => {
        this.languages = data;
      },
      (error) => {
        this.toastr.error('Unable to fetch languages');
      },
      () => {},
    );
  }

  removeVendor(index: number): void {
    (<FormArray>this.form.get('segments')).removeAt(index);
  }

  getOtherProjects() {
    this.dd.projects().subscribe(
      (data) => {
        this.otherProjects = data;
      },
      (error) => {
        this.toastr.error('Unable to fetch other projects');
      },
      () => {
        // this.toastr.success('Fetched instances');
      },
    );
  }

  onDateSelect(event: any) {
    this.form
      .get('deadline')
      ?.setValue(event.year + '-' + event.month + '-' + event.day);
  }

  onPiiSelect(item: any) {
    if (item.value === 'piiEmailValidation') {
      if (!this.selectedPII.some((pii) => pii.value === 'piiEmail')) {
        this.selectedPII.push({ option: 'Email', value: 'piiEmail' });
        this.selectedPII = [...this.selectedPII];
      }
    }

    if (item.value === 'piiPhoneValidation') {
      if (!this.selectedPII.some((pii) => pii.value === 'piiPhone')) {
        this.selectedPII.push({ option: 'Phone', value: 'piiPhone' });
        this.selectedPII = [...this.selectedPII];
      }
    }
  }

  onPiiDeSelect(item: any) {
    if (item.value === 'piiEmail') {
      if (this.selectedPII.some((pii) => pii.value === 'piiEmailValidation')) {
        let piiEmail = this.selectedPII.findIndex(
          (pii) => pii.value === 'piiEmailValidation',
        );
        this.selectedPII.splice(piiEmail, 1);
        this.selectedPII = [...this.selectedPII];
      }
    }

    if (item.value === 'piiPhone') {
      if (this.selectedPII.some((pii) => pii.value === 'piiPhoneValidation')) {
        let piiPhone = this.selectedPII.findIndex(
          (pii) => pii.value === 'piiPhoneValidation',
        );
        this.selectedPII.splice(piiPhone, 1);
        this.selectedPII = [...this.selectedPII];
      }
    }
  }

  onItemSelect(item: any) {
    //just take ids for form
    this.form.get('dedupeOtherProjects')?.setValue(
      this.selectedItems.map(function (item) {
        return item?.value;
      }),
    );
  }
  onDeSelect(items: any) {
    //just take ids for form
    this.form.get('dedupeOtherProjects')?.setValue(
      this.selectedItems.map(function (item) {
        return item?.value;
      }),
    );
  }

  targetVIDSUploadToggle() {
    if (this.f.enableTargetVIDs.value == true) {
      return { 'pointer-events': 'auto' };
    } else this.f.enableTargetVIDs.value == false;
    {
      return { 'pointer-events': 'none', opacity: 0.5 };
    }
  }

  clientChange() {
    this.form.controls.salesRep.setValue(this.form.controls.client.value.salesRep);
    if (
      this.form.get('client')?.value.id ===
      'e842ba3f-5ba3-4d4c-b18a-81e4c62f7798'
    ) {
      // If Big Village, show hash checkbox
      this.hideClientSurveyEntrySigning = false;
    } else {
      this.hideClientSurveyEntrySigning = true;
    }
  }
}
