
<ng-container *ngIf="participants">

<div class="row mb-3">
  <div class="col">
    <div class="card">
      <div class="card-header lead">
        Performance
      </div>
      <div class="card-body">
        <div *ngIf="this.participants.length > 0" class="row">
          <div class="col table-responsive">
            <table class="table">
              <caption>Totals</caption>
              <thead>
                <tr>
                  <th scope="col">Starts</th>
                  <th scope="col">Completes</th>
                  <th scope="col">OQ</th>
                  <th scope="col">Fraud</th>
                  <th scope="col">Dupes</th>
                  <th scope="col">Client OQ</th>
                  <th scope="col">Client QC</th>
                  <th scope="col">Client Term's</th>
                  <th scope="col">Resolved</th>
                  <th scope="col">IR</th>
                  <th scope="col">DOR</th>
                  <th scope="col">Cost</th>
                  <th scope="col">Median LOI</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{this.totals.starts}}</td>
                  <td>{{this.totals.completes}}</td>
                  <td>{{this.totals.oq}}</td>
                  <td>{{this.totals.fraud}}</td>
                  <td>{{this.totals.dupes}}</td>
                  <td> {{this.totals.clientOQ}}</td>
                  <td> {{this.totals.clientQC}}</td>
                  <td>{{this.totals.clientTerms}}</td>
                  <td>{{this.totals.resolved}}</td>
                  <td>{{ this.totals.ir| percent }}</td>
                  <td>{{ this.totals.dor| percent }}</td>
                  <td>{{ this.totals.cost| currency }}</td>
                  <td>{{ this.totals.medianLoi | minuteSeconds }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row" *ngIf="byvendor.length>0">
          <div class="col table-responsive">
            <table class="table">
              <caption>Vendor Breakdown</caption>
              <thead>
                <tr>
                  <th scope="col">Vendor</th>
                  <th scope="col">Starts</th>
                  <th scope="col">Completes</th>
                  <th scope="col">OQ</th>
                  <th scope="col">Fraud</th>
                  <th scope="col">Dupes</th>
                  <th scope="col">Client OQ</th>
                  <th scope="col">Client QC</th>
                  <th scope="col">Client Term's</th>
                  <th scope="col">Resolved</th>
                  <th scope="col">IR</th>
                  <th scope="col">DOR</th>
                  <th scope="col">Cost</th>
                  <th scope="col">Median LOI</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let segment of byvendor">
                  <td>{{ segment?.vendor }}</td>
                  <td>{{ segment?.starts }}</td>
                  <td>{{ segment?.completes }}</td>
                  <td> {{ segment?.oq }}</td>
                  <td>{{ segment?.fraud }}</td>
                  <td>{{ segment?.dupes }}</td>
                  <td> {{ segment?.clientOQ }}</td>
                  <td> {{ segment?.clientQC }}</td>
                  <td>{{ segment?.clientTerms }}</td>
                  <td>{{ segment?.resolved }}</td>
                  <td>{{ segment?.ir| percent }}</td>
                  <td>{{ segment?.dor| percent }}</td>
                  <td>{{ segment?.cost| currency }}</td>
                  <td>{{segment?.medianLoi | minuteSeconds}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row" *ngIf="byday.length>0">
          <div class="col table-responsive">
            <table class="table">
              <caption>Daily Performance</caption>
              <thead>
                <tr>
                  <th scope="col">Day</th>
                  <th scope="col">Starts</th>
                  <th scope="col">Completes</th>
                  <th scope="col">OQ</th>
                  <th scope="col">Fraud</th>
                  <th scope="col">Dupes</th>
                  <th scope="col">Client OQ</th>
                  <th scope="col">Client QC</th>
                  <th scope="col">Client Term's</th>
                  <th scope="col">Resolved</th>
                  <th scope="col">IR</th>
                  <th scope="col">DOR</th>
                  <th scope="col">Cost</th>
                  <th scope="col">Median LOI</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let day of byday">
                  <tr >
                    <td>

                      <a class="btn btn-link" data-bs-toggle="collapse" [href]="'#collapseExample'+ day?.day.replace(' ', '').replace(' ', '')" role="button" aria-expanded="false" [attr.aria-controls]="'collapseExample'+ day?.day.replace(' ', '').replace(' ', '')">
                        {{ day?.day }}
                      </a>
                    </td>
                    <td>{{ day?.starts }}</td>
                    <td>{{ day?.completes }}</td>
                    <td> {{ day?.oq }}</td>
                    <td>{{ day?.fraud }}</td>
                    <td>{{ day?.dupes }}</td>
                    <td> {{ day?.clientOQ }}</td>
                    <td> {{ day?.clientQC }}</td>
                    <td>{{ day?.clientTerms }}</td>
                    <td>{{ day?.resolved }}</td>
                    <td>{{ day?.ir| percent }}</td>
                    <td>{{ day?.dor| percent }}</td>
                    <td>{{ day?.cost| currency }}</td>
                    <td>{{day?.medianLoi | minuteSeconds}}</td>
                  </tr>
                  <tr  class="collapse bs-secondary" [id]="'collapseExample'+ day?.day.replace(' ', '').replace(' ', '')"> <!-- makeID unique string for collapse -->
                    <td> </td>
                    <td colspan="13">
                      <table class="table">
                        <caption>Day Performance by Vendor</caption>
                        <thead class=" table-dark">
                          <tr>
                            <th scope="col">Vendor</th>
                            <th scope="col">Starts</th>
                            <th scope="col">Completes</th>
                            <th scope="col">OQ</th>
                            <th scope="col">Fraud</th>
                            <th scope="col">Dupes</th>
                            <th scope="col">Client OQ</th>
                            <th scope="col">Client QC</th>
                            <th scope="col">Client Term's</th>
                            <th scope="col">Resolved</th>
                            <th scope="col">IR</th>
                            <th scope="col">DOR</th>
                            <th scope="col">Cost</th>
                            <th scope="col">Median LOI</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let vendor of day?.vendors">
                            <td>{{ vendor?.vendor }}</td>
                            <td>{{ vendor?.starts }}</td>
                            <td>{{ vendor?.completes }}</td>
                            <td> {{ vendor?.oq }}</td>
                            <td>{{ vendor?.fraud }}</td>
                            <td>{{ vendor?.dupes }}</td>
                            <td> {{ vendor?.clientOQ }}</td>
                            <td> {{ vendor?.clientQC }}</td>
                            <td>{{ vendor?.clientTerms }}</td>
                            <td>{{ vendor?.resolved }}</td>
                            <td>{{ vendor?.ir| percent }}</td>
                            <td>{{ vendor?.dor| percent }}</td>
                            <td>{{ vendor?.cost| currency }}</td>
                            <td>{{vendor?.medianLoi | minuteSeconds }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>

            <div *ngIf="lastComplete">Last complete was on {{ lastComplete }}</div>

          </div>
        </div>


      </div>
    </div>
  </div>
</div>

