import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { Marker } from 'src/app/_models/marker';
import { MarkerType } from 'src/app/_models/marker-type';
import { PrettyPrintPipe } from 'src/app/_pipes/prettyPrintPipe.pipe';
import { NubbleService } from 'src/app/_services/nubble.service';

@Component({
  selector: 'app-add-update-marker',
  templateUrl: './add-update-marker.component.html',
  styleUrls: ['./add-update-marker.component.scss'],
  providers: [ PrettyPrintPipe ]
})
export class AddUpdateMarkerComponent implements OnInit {
  loading: boolean = false;
  queryLoading: boolean = false;
  asyncQueryLoading: boolean = false;
  isSending: boolean = false;
  submitted: boolean = false;
  isAddMode: boolean;
  queryCardTitle: string = 'Marker';
  resultsCardTitle: string = 'Query Results';
  id: string;
  markersForm: FormGroup;
  markerQueryResults: any[] = [];

  // markerTypeSelection: {type: string, attributes: MarkerType[]}[]  = [{type: 'foursqvisits', attributes: [{attributeName: 'locationtype', type: 'String'}, {attributeName: 'confidencelevel', type: 'String'}, {attributeName: 'venuename', type: 'String'},
  //                                                                 {attributeName: 'city', type: 'String'}, {attributeName: 'state', type: 'String'}, {attributeName: 'category_primary', type: 'String'}]},

  //                                                                 {type: 'reallifesocial', attributes: [{attributeName: 'osname', type: 'String'}, {attributeName: 'service', type: 'String'},
  //                                                                 {attributeName: 'eventtype', type: 'String'}, {attributeName: 'advertiser', type: 'String'}, {attributeName: 'promotiontype', type: 'String'}]},

  //                                                                 {type: 'reallifeweb', attributes: [{attributeName: 'osname', type: 'String'}, {attributeName: 'pagedomain', type: 'String'}, {attributeName: 'categoryv2', type: 'String'}]},

  //                                                                 {type: 'reallifemediaplaysessions', attributes: [{attributeName: 'mediaplayer', type: 'String'}, {attributeName: 'advert', type: 'String'}, {attributeName: 'category', type: 'String'},
  //                                                                 {attributeName: 'visitduration', type: 'String'}]},

  //                                                                 {type: 'reallifeapp', attributes: [{attributeName: 'osname', type: 'String'}, {attributeName: 'appcategory', type: 'String'}, {attributeName: 'appname', type: 'String'}]},

  //                                                                 {type: 'reallifeshopper2', attributes: [{attributeName: 'osname', type: 'String'}, {attributeName: 'retailer', type: 'String'},
  //                                                                 {attributeName: 'servicename', type: 'String'}, {attributeName: 'eventtype', type: 'String'}, {attributeName: 'productname', type: 'String'}, {attributeName: 'price', type: 'String'},
  //                                                                 {attributeName: 'category', type: 'String'}, {attributeName: 'searchterms', type: 'String'}]}];

  markerTypeSelection: { type: string; attributes: string[] }[] = [
    {
      type: 'foursqvisits',
      attributes: [
        'locationtype',
        'confidencelevel',
        'venuename',
        'city',
        'state',
        'category_primary',
      ],
    },
    {
      type: 'reallifesocial',
      attributes: [
        'osname',
        'service',
        'eventtype',
        'advertiser',
        'promotiontype',
      ],
    },
    { type: 'reallifeweb', attributes: ['osname', 'pagedomain', 'categoryv2'] },
    {
      type: 'reallifemediaplaysessions',
      attributes: ['mediaplayer', 'advert', 'category', 'visitduration'],
    },
    { type: 'reallifeapp', attributes: ['osname', 'appcategory', 'appname'] },
    {
      type: 'reallifeshopper2',
      attributes: [
        'osname',
        'retailer',
        'servicename',
        'eventtype',
        'productname',
        'price',
        'category',
        'searchterms',
      ],
    },
  ];

  categorySelection: string[] = ['restaurants'];
  attributeNameSelection: string[] = [];
  typeSelection: string[] = ['String', 'Number', 'Binary', 'Boolean', 'Null'];
  conditionSelection: string[] = ['Contains'];
  operatorSelection: string[] = ['OR', 'AND'];
  valueSelection: string = '';
  timeframeSelection: { key: string; value: number }[] = [
    { key: 'Last 2 Days', value: 2 },
    { key: 'Last 3 Days', value: 3 },
    { key: 'Last 4 Days', value: 4 },
    { key: 'Last 5 Days', value: 5 },
    { key: 'Last 6 Days', value: 6 },
    { key: 'Last 7 Days', value: 7 },
    { key: 'Last 8 Days', value: 8 },
    { key: 'Last 9 Days', value: 9 },
    { key: 'Last 10 Days', value: 10 },
    { key: 'Last 11 Days', value: 11 },
    { key: 'Last 12 Days', value: 12 },
    { key: 'Last 13 Days', value: 13 },
    { key: 'Last 14 Days', value: 14 },
    { key: 'Last 15 Days', value: 15 },
    { key: 'Last 16 Days', value: 16 },
  ];

  constructor(
    private toastr: ToastrService,
    private nubbleService: NubbleService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.markersForm = this.formBuilder.group({
      id: new FormControl(''),
      name: new FormControl('', Validators.required),
      isDisabled: new FormControl(),
      markerType: new FormControl('', Validators.required),
      timeframe: new FormControl('', Validators.required),
      markerSegments: this.formBuilder.array([]),
    });

    if (!this.isAddMode) {
      this.nubbleService
        .findMarkerSegments(this.id)
        .pipe(first())
        .subscribe((x) => {
          this.markersForm.patchValue(x);
          this.setAttributes(this.markersForm.get('markerType'));
          x.markerSegments.forEach((marker) => {
            let existingMarker = this.addExistingMarker(marker);
            this.markerSegments.push(existingMarker);
          });
        });
    }

    if (this.isAddMode) {
      this.markerSegments.push(this.newMarker());
    }
  }

  ngOnInit() {

    // if (!this.isAddMode) {
    //   this.getMarkerQueryResultsAsync();
    // }
  }

  get markerSegments(): FormArray {
    return this.markersForm.get('markerSegments') as FormArray;
  }

  // setAttributes($event) {

  //   this.attributeNameSelection = [];

  //   if ($event.value == '') {
  //     this.attributeNameSelection = ['Choose Attribute'];
  //   }
  //   else {

  //     var arr = this.markerTypeSelection.filter(x => x.type == $event.value)[0].attributes;

  //     arr.forEach(element => {
  //         this.attributeNameSelection.push(element.attributeName);
  //     });
  //   }
  // }

  setAttributes($event) {
    if ($event.value == '') {
      this.attributeNameSelection = ['Choose Attribute'];
    } else {
      this.attributeNameSelection = this.markerTypeSelection.filter(
        (x) => x.type == $event.value
      )[0].attributes;
    }
  }

  newMarker(): FormGroup {
    //Does not require an operator validator for the first segment
    if (this.markerSegments.length < 1) {
      return this.formBuilder.group({
        attributeName: new FormControl('', Validators.required),
        type: new FormControl('', Validators.required),
        condition: new FormControl('', Validators.required),
        value: new FormControl('', Validators.required),
        operator: new FormControl(''),
      });
    } else {
      return this.formBuilder.group({
        attributeName: new FormControl('', Validators.required),
        type: new FormControl('', Validators.required),
        condition: new FormControl('', Validators.required),
        value: new FormControl('', Validators.required),
        operator: new FormControl('', Validators.required),
      });
    }
  }

  addMarkers() {
    this.markerSegments.push(this.newMarker());
  }

  addExistingMarker(marker): FormGroup {
    return this.formBuilder.group({
      attributeName: marker.attributeName,
      type: marker.type,
      condition: marker.condition,
      value: marker.value,
      operator: marker.operator,
    });
  }

  removeMarkers(i: number) {
    this.markerSegments.removeAt(i);
  }

  onSubmit() {
    this.submitted = true;

    if (this.markersForm.invalid) {
      return;
    }

    let model = this.markersForm.value;

    if (!this.isAddMode) {
      this.nubbleService.updateMarkerSegements(model).subscribe(
        (data) => {
          this.router.navigateByUrl('/markers');
          this.toastr.success('Marker has been updated!');
        },
        (error) => {
          this.toastr.error('Unable to update marker!');
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.submitted = false;
        }
      );
    }

    if (this.isAddMode) {
      this.nubbleService.createMarker(model).subscribe(
        (data) => {
          this.markersForm.patchValue(data);
          this.router.navigateByUrl('/markers');
          this.toastr.success('Marker has been created!');
        },
        (error) => {
          this.toastr.error('Unable to create marker!');
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.submitted = false;
        }
      );
    }
  }

  getMarkerQueryCount() {
    this.submitted = true;
    this.queryLoading = true;

    if (this.markersForm.invalid) {
      this.queryLoading = false;
      return;
    }

    let model = this.markersForm.value;

    this.nubbleService.getMarkerQueryCount(model).subscribe(
      (data) => {
        this.toastr.success('Marker Query Count: ' + data);
      },
      (error) => {
        this.toastr.error('Unable to get marker count!');
        this.queryLoading = false;
      },
      () => {
        this.queryLoading = false;
        this.submitted = false;
      }
    );
  }

  getMarkerQueryResultsAsync() {

    let model = this.markersForm.value;
    this.asyncQueryLoading = true;

    this.nubbleService.getMarkerResultsAsync(model).subscribe((data) => {
      this.markerQueryResults = data;
    },
    (error) => {
      this.toastr.error('Unable to get marker count!');
      this.asyncQueryLoading = false;
    },
    () => {
      this.asyncQueryLoading = false;
    }
  );
  }

  // Only AlphaNumeric
  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
