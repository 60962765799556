import { AuthService } from './../_services/auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


      //currentle not working // jwt interceptor logs out people not authorized

      const roles = route.firstChild?.data['roles']  as Array<string>;

      if(roles) {
        const match = this.authService.roleMatch(roles);
        if(match){
          return true;
        }
        else {
          return false;
        }
      }

      if(this.authService.userValue){
        return true;
      }

      this.router.navigate(['']);
      return true;

    }
}
