import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ClientUser } from '../_models/client-user';

@Injectable({
  providedIn: 'root'
})
export class ClientUserService {

  constructor(
    private http: HttpClient
  ) { }

  list(clientId) {
    return this.http.get<ClientUser[]>(`${environment.apiUrl}/api/clientuser/` + clientId);
  }

  find(clientId: string, email: string) {
    return this.http.get<ClientUser>(`${environment.apiUrl}/api/clientuser/` + clientId + '/' + encodeURIComponent(email));
  }

  add(model: ClientUser) {
    return this.http.post(`${environment.apiUrl}/api/clientuser`, model);
  }

  update(model: ClientUser) {
    return this.http.put(`${environment.apiUrl}/api/clientuser`, model);
  }

  delete(model: ClientUser) {
    return this.http.delete(`${environment.apiUrl}/api/clientuser/` + model.clientId + '/' + encodeURIComponent(model.email));
  }
}
