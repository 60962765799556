import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MarkerIsDisabled } from 'src/app/_models/marker-is-disabled';
import { Toggle } from 'src/app/_models/toggle';
import { NubbleService } from 'src/app/_services/nubble.service';

@Component({
  selector: 'app-markers',
  templateUrl: './markers.component.html',
  styleUrls: ['./markers.component.css']
})
export class MarkersComponent implements OnInit {

  markerIsDisabled: MarkerIsDisabled = {markerId: '', isDisabled: false};

  layout:any[] =[
    {label:"Name",id:"name",type:""}
  ];

  actions:any[] =[
    {label:"Edit",icon:"",type:"edit"},
    {label:"Delete",icon:"",type:"delete"},
    //{label:"Disable",id:"disable",type:"disable"}
  ];

  items:any[]=[];

  @Output() action = new EventEmitter();
  
  constructor(private nubbleService: NubbleService, private toastr: ToastrService, private router: Router) { }

  ngOnInit() {
    this.getMarkers();
  }

  handleAction($event){

    if($event.type==='edit'){
      this.router.navigate(['/markers/edit/'+$event.row.id]);
    }
    else if($event.type==='add'){
      this.router.navigate(['/markers/add']);
    }
    else if($event.type==='delete') {

      if (window.confirm("Are you sure you would like to delete this marker?") == true) {
        this.nubbleService.deleteMarker($event.row.id).subscribe(data=>{
        }, error =>{
          this.toastr.error('Failed to delete ' + $event.row.name);
        }, () => {
          this.getMarkers();
          this.toastr.success('Deleted ' + $event.row.name + ' Marker');
        })
      }
    }

    else if($event.type==='disable') {

      if (window.confirm("Are you sure you would like to disable this marker?") == true) {
  
        this.nubbleService.disableMarker(this.markerIsDisabled).subscribe((data) =>{
  
          if (this.markerIsDisabled.isDisabled == true) {
            this.toastr.success("Marker Disabled");
          }
          else if (this.markerIsDisabled.isDisabled == false) {
            this.toastr.success("Marker Enabled");
          }
          }, error =>{
  
            this.markerIsDisabled.isDisabled = !this.markerIsDisabled.isDisabled;
  
            if (this.markerIsDisabled.isDisabled == true) {
              this.toastr.error("Failed to Disable Marker");
            }
            else if (this.markerIsDisabled.isDisabled == false) {
              this.toastr.error("Failed to Enable Marker");
            }
          }, () => {
          })
        } else {
  
          this.markerIsDisabled.markerId = $event.row.id;
          this.markerIsDisabled.isDisabled = $event.row.isDisabled;

          $event.preventDefault();
        }
    }
  }

  getMarkers(){
    this.nubbleService.listMarkers().subscribe(data=>{
      this.items = data;
    }, error =>{
      this.toastr.error('Failed to fetch markers');
    }, () => {
      // this.toastr.success('Fetched instances');
    })
  }
}
