import { environment } from './environments/environment';

const awsmobile = {
  aws_project_region: environment.region,
  aws_cognito_region: environment.region,
  aws_user_pools_id: environment.userPoolId,
  aws_user_pools_web_client_id: environment.appClientId,
  oauth: {},
};

export default awsmobile;
