import {Component, ElementRef, Input, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {VendorApiKey} from "../../../_models/vendor-api-key";
import {first} from "rxjs/operators";
import {VendorKeyService} from "../../../_services/vendor-key.service";
import {Vendor} from "../../../_models/vendor";

@Component({
  selector: 'app-vendor-keys',
  templateUrl: './vendor-keys.component.html',
  styleUrls: ['./vendor-keys.component.css']
})

export class VendorKeysComponent implements OnInit {
  @Input() vendorId: string;
  @ViewChild('closeConfirmModal') private closeConfirmModal: ElementRef;

  keys:VendorApiKey[]=[];
  loading = false;
  confirming = false;
  modalBody = "";
  selectedKey: VendorApiKey;
  selectedAction = "";
  disabling = false;

  constructor(
    private vendorKeyService: VendorKeyService,
    public toastr: ToastrService)
  {

  }

  ngOnInit() {
    this.getVendorKeys();
  }

  confirm(apiKey: VendorApiKey, action: string, event: any) {
    this.selectedKey = apiKey;
    this.selectedAction = action;

    if (event.checked) {
      //We are enabling the key. No need to confirm.
      this.updateKey(apiKey, event.checked)
      return;
    }

    if (action === 'toggle') {
      this.modalBody = "Disabling this key will cause supplier API calls to fail that use this key. Are you sure you want to disable this key?";
      this.disabling = true;
    }
    if (action === 'delete') {
      this.modalBody = "Deleting this key is permanent and will cause supplier API calls to fail that use this key. Are you sure you want to delete this key?";
    }
  }

  updateKey(apiKey: VendorApiKey, newStatus: boolean) {
    this.selectedKey = apiKey;
    apiKey.isActive = newStatus;

    this.vendorKeyService.update(apiKey).subscribe(data=>{

    }, error =>{
      this.toastr.error('Failed to ' + newStatus ? "enable API key" : "disable API key");
    }, () => {
      this.toastr.success(newStatus ? "Enabled API key" : "Disabled API key");
    })
  }

/*  updateKey(apiKey: VendorApiKey, event: any) {
    this.selectedKey = apiKey;

    apiKey.isActive = event.checked;
    this.vendorKeyService.update(apiKey).subscribe(data=>{

    }, error =>{
      this.toastr.error('Failed to ' + event.checked ? "enable API key" : "disable API key");
    }, () => {
      this.toastr.success(event.checked ? "Enabled API key" : "Disabled API key");
    })
  }*/

  disableKey(apiKey: VendorApiKey) {
    apiKey.isActive = false;
    this.confirming = true;
    this.vendorKeyService.update(apiKey).subscribe(data=>{

    }, error =>{
      this.toastr.error('Failed to disable API key');
      this.confirming = false;
      this.disabling = false;
      this.modalBody = "";
    }, () => {
      this.toastr.success("Disabled API key");
      this.confirming = false;
      this.disabling = false;
      this.modalBody = "";
      this.closeConfirmModal.nativeElement.click();
    })
  }

  confirmAction(apiKey: VendorApiKey, action: string) {
    if (action === 'toggle') {
      this.disableKey(apiKey);
    }
    if (action === 'delete') {
      this.deleteKey(apiKey);
    }
  }

  getVendorKeys() {
    this.vendorKeyService.list(this.vendorId).subscribe(data=>{
      this.keys = data;
    }, error =>{
      this.toastr.error('Failed to fetch API keys');
    }, () => {

    })
  }

  addKey() {
    this.loading = true;

    let newKey: VendorApiKey = {
      id: this.vendorId,
      sortKey: '',
      createDate: '',
      isActive: true,
      pmoUserId: ''
    }

    this.vendorKeyService.add(newKey)
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastr.success('New API key added');
          this.getVendorKeys();
          this.loading = false;
        },
        error: error => {
          this.toastr.warning(error);
          this.loading = false;
        }
      });
  }

  deleteKey(apiKey: VendorApiKey) {
    this.confirming = true;
    this.vendorKeyService.delete(apiKey.id, apiKey.sortKey).subscribe(data=>{

    }, error =>{
      this.toastr.error('Failed to delete API key');
      this.confirming = false;
    }, () => {
      this.closeConfirmModal.nativeElement.click();
      this.getVendorKeys();
      this.toastr.success('Deleted API key');
      this.confirming = false;
    })
  }
}
