<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item"><a href="/projects">Projects</a></li>
    <li class="breadcrumb-item active" aria-current="page">Add</li>
  </ol>
</nav>
<!--
{{f.uniqueIds.value|json}}
{{f.uniqueIds.value|json}}
-->
<form [formGroup]="form" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()">
  <div class="card mb-3">
    <div class="card-body">
      <div class="card">
        <div class="card-header">Project Settings</div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div *ngIf="this.errorMessage" class="alert alert-danger" role="alert">
                {{ this.errorMessage }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label class="lead" for="projectName">Project Name</label>
              <input type="text" formControlName="projectName" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.projectName.errors }" />
              <div *ngIf="submitted && f.projectName.errors" class="invalid-feedback">
                <div *ngIf="f.projectName.errors?.required">
                  Project name is required
                </div>
              </div>
            </div>
            <div class="col-2">
              <label class="lead" for="country">Country</label>
              <select required formControlName="country" class="form-select" required
                [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                <option value="" [selected]="true">Choose Country</option>
                <option *ngFor="let country of countryList" [value]="country.code">
                  {{ country.name }}
                </option>
              </select>
              <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                <div *ngIf="f.country.errors?.required">
                  Country is required
                </div>
              </div>
            </div>
            <div class="col-2">
              <label class="lead" for="languageCode">Language</label>
              <select required formControlName="languageCode" class="form-select" required
                [ngClass]="{ 'is-invalid': submitted && f.languageCode.errors }">
                <option [selected]="true" value="">Choose Language</option>
                <option *ngFor="let language of languages" [value]="language.value">
                  {{ language.option }}
                </option>
              </select>
              <div *ngIf="submitted && f.languageCode.errors" class="invalid-feedback">
                <div *ngIf="f.languageCode.errors?.required">
                  Language is required
                </div>
              </div>
            </div>
            <div class="col-2">
              <label class="lead" for="deadline">Deadline</label>

              <div class="form-group">
                <div class="input-group">
                  <input type="date" formControlName="deadline" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.deadline.errors }" />
                </div>
                <div *ngIf="submitted && f.deadline.errors" class="invalid-feedback">
                  <div *ngIf="f.deadline.errors?.required">
                    Deadline is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3">
              <label class="lead" for="category">Client</label>
              <select class="form-select" formControlName="client" (change)="clientChange()"
                [ngClass]="{ 'is-invalid': submitted && f.client.errors }">
                <option [selected]="true" value="">Choose Client</option>
                <option *ngFor="let client of clientsList" [ngValue]="client">
                  {{ client.name }}
                </option>
              </select>
              <div *ngIf="submitted && f.client.errors" class="invalid-feedback">
                <div *ngIf="f.client.errors?.required">
                  Client is required
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="lead" for="salesRep">Sales Rep</label>
              <select class="form-select" formControlName="salesRep"
                [ngClass]="{ 'is-invalid': submitted && f.salesRep.errors }">
                <option [selected]="true" value="">Choose Sales Rep</option>
                <option *ngFor="let user of users" [value]="user.value">
                  {{ user.option }}
                </option>
              </select>
              <div *ngIf="submitted && f.salesRep.errors" class="invalid-feedback">
                <div *ngIf="f.salesRep.errors?.required">
                  Sales Rep is required
                </div>
              </div>
            </div>
            <div class="col">
              <label class="lead" for="category">Category</label>
              <select class="form-select" formControlName="category"
                [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
                <option value="" [selected]="true">Choose Category</option>
                <option value="B2C">B2C</option>
                <option value="B2B">B2B</option>
                <option value="Healthcare">Healthcare</option>
              </select>
              <div *ngIf="submitted && f.category.errors" class="invalid-feedback">
                <div *ngIf="f.category.errors?.required">
                  Category is required
                </div>
              </div>
            </div>
            <div class="col">
              <label class="lead" for="deviceType">Device Type</label>
              <select class="form-select" formControlName="deviceType"
                [ngClass]="{ 'is-invalid': submitted && f.deviceType.errors }">
                <option value="" [selected]="true">Choose Device Type</option>
                <option value="All">All</option>
                <option value="Mobile">Mobile</option>
                <option value="Desktop">Desktop</option>
              </select>
              <div *ngIf="submitted && f.deviceType.errors" class="invalid-feedback">
                <div *ngIf="f.deviceType.errors?.required">
                  Device Type is required
                </div>
              </div>
            </div>
            <div class="col">
              <label class="lead" for="sellCPI">Sell CPI</label>
              <input type="number" min="1" step=".1" formControlName="sellCPI" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.sellCPI.errors }" />
              <div *ngIf="submitted && f.sellCPI.errors" class="invalid-feedback">
                <div *ngIf="f.sellCPI.errors?.required">
                  Sell CPI is required
                </div>
              </div>
            </div>
            <div class="col">
              <label class="lead" for="lOI">LOI <small>(minutes)</small></label>
              <input type="number" min="1" step="1" formControlName="lOI" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.lOI.errors }"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57" pattern="[0-9]+" />
              <div *ngIf="submitted && f.lOI.errors" class="invalid-feedback">
                <div *ngIf="f.lOI.errors?.required">LOI is required</div>
                <div *ngIf="f.lOI.errors?.pattern">Whole numbers only</div>
              </div>
            </div>
            <div class="col">
              <label class="lead" for="estimateIR">Est. IR </label>
              <input type="number" min="1" step="1" formControlName="estimateIR" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.estimateIR.errors }"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57" pattern="[1-9]{1}[0-9]{0,9}" />
              <div *ngIf="submitted && f.estimateIR.errors" class="invalid-feedback">
                <div *ngIf="f.estimateIR.errors?.required">
                  Est. IR is required
                </div>
                <div *ngIf="f.estimateIR.errors?.pattern">
                  Whole numbers above zero only
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <label class="lead" for="projectName">Code</label>
              <input type="text" formControlName="dashCode" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.dashCode.errors }" />
              <div *ngIf="f.dashCode.errors" class="invalid-feedback">
                <div *ngIf="f.dashCode.errors?.pattern">
                  The Code format is invalid. It should begin with "{{ dashCodePrefix }}" followed by 4 to 6 digits.
                </div>
              </div>
            </div>
            <div class="col-2">
              <label class="lead" for="visibleToClient">
                <span class="invisible">Visible to client</span>
              </label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" formControlName="visibleToClient" />
                <label class="form-check-label" for="flexCheckDefault">
                  Show in Dash+?
                </label>
              </div>
            </div>
            <div class="col-4" *ngIf="!hideClientSurveyEntrySigning">
              <label class="lead" for="enableClientSurveyEntrySigning">
                <span class="invisible">Enable Client Survey Entry Signing</span>
              </label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" formControlName="enableClientSurveyEntrySigning" />
                <label class="form-check-label" for="flexCheckDefault">
                  Enable client survey entry signing? (Big Village only)
                </label>
              </div>
            </div>
          </div>
          <!-- <div class="row mt-3"  *ngIf="auth.user?.userDetails?.dash != true">
            <div class="col-4">
              <div class="form-check">
                <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                formControlName="autogenReferralCode"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Auto Generate Referral Codes [ValueMe Recruitment]
              </label>
              </div>
            </div>
            <div class="col-8"></div>
          </div> -->
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <div class="card">
            <div class="card-header">Quota Settings</div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <label class="lead" for="enableQuotas"><span class="invisible">hi</span></label>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="enableQuotas" />
                    <label class="form-check-label" for="flexCheckDefault">
                      Enable Quotas
                    </label>
                  </div>
                </div>
                <div class="col">
                  <label class="lead" for="vendorFullLaunchQuota"><span class="invisible">hi</span></label>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="quotaByStarts" />
                    <label class="form-check-label" for="flexCheckDefault">
                      Quota By Starts
                    </label>
                  </div>
                </div>

                <div class="col">
                  <label class="lead" for="fullLaunchQuota">Quota</label>
                  <input type="number" min="0" pattern="\d*" step="1" formControlName="fullLaunchQuota"
                    class="form-control" [ngClass]="{
                      'is-invalid': submitted && f.fullLaunchQuota.errors
                    }" />
                  <div *ngIf="submitted && f.fullLaunchQuota.errors" class="invalid-feedback">
                    <div *ngIf="f.fullLaunchQuota.errors?.required">
                      Full Launch Quota is required
                    </div>
                  </div>
                </div>
                <div class="col" style="display: none">
                  <label class="lead" for="softLaunchQuota">Soft Launch</label>
                  <input type="number" step="1" formControlName="softLaunchQuota" class="form-control" [ngClass]="{
                      'is-invalid': submitted && f.softLaunchQuota.errors
                    }" />
                  <div *ngIf="submitted && f.softLaunchQuota.errors" class="invalid-feedback">
                    <div *ngIf="f.softLaunchQuota.errors?.required">
                      Soft Launch Quota is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 h-100 d-flex" *ngIf="auth.user?.userDetails?.dash == true">
          <div class="card">
            <div class="card-header">
              Unique Ids <small>(Optional)</small>
              <div class="form-check form-check-inline form-switch float-end">
                <input class="form-check-input" formControlName="enableUniqueIds" type="checkbox" />
                <label class="form-check-label">Enable UniqueIDs</label>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <app-unique-ids [sampleIds]="''" [numberOfIds]="0" formControlName="uniqueIds"></app-unique-ids>
                </div>
                <div class="col">
                  <label class="lead" for="idParameter">ID Parameter (Default SID9=)</label>
                  <input formControlName="idParameter" placeholder="SID9" class="form-control" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6" *ngIf="auth.user?.userDetails?.dash != true">
          <div class="card h-100 d-flex w-100">
            <div class="card-header">
              Target VIDs <small>(Optional)</small>
              <div class="form-check form-check-inline form-switch float-end">
                <input class="form-check-input" formControlName="enableTargetVIDs" type="checkbox" />
                <label class="form-check-label">Enable Target VIDs</label>
              </div>
            </div>
            <div class="card-body" [ngStyle]="targetVIDSUploadToggle()">
              <div class="row">
                <div class="col">
                  <app-target-vids [sampleTargetVIDs]="''" [numberOfIds]="0"
                    formControlName="targetVIDs"></app-target-vids>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-3">
        <div class="card-header">Links</div>
        <div class="card-body">
          <div class="row mt-2">
            <div class="col">
              <label class="lead" for="liveUrl">Live Url</label>
              <input type="text" pattern="https?://.*" formControlName="liveUrl"
                placeholder="https://survey.com?vid=#vid#&spid=#spid#" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.liveUrl.errors }" />
              <div *ngIf="submitted && f.liveUrl.errors" class="invalid-feedback">
                <div *ngIf="f.liveUrl.errors?.required">
                  Live Url is required
                </div>
                <div *ngIf="f.liveUrl.errors?.pattern">http:// or https://</div>
              </div>
            </div>

            <div class="col">
              <label class="lead" for="testUrl">Test Url</label>
              <input type="text" pattern="https?://.*" formControlName="testUrl"
                placeholder="https://testsurvey.com?vid=#vid#&spid=#spid#" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.testUrl.errors }" />
              <div *ngIf="submitted && f.testUrl.errors" class="invalid-feedback">
                <div *ngIf="f.testUrl.errors?.required">
                  Test Url is required
                </div>
                <div *ngIf="f.testUrl.errors?.pattern">http:// or https://</div>
              </div>
            </div>

            <div class="col" *ngIf="false">
              <label class="lead" for="tuneUrl">Tune Callback Url</label>
              <input type="text" pattern="https?://.*" formControlName="tuneUrl"
                placeholder="https://go2cloud.org/aff_lsr?transaction_id=" class="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <div class="card">
            <div class="card-header">QC Settings</div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <label class="lead" for="fraudCheckType">Fraud Type</label>
                  <select class="form-select" formControlName="fraudCheckType"
                    [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
                    <option value="None">None</option>
                    <option [selected]="true" value="Standard">Standard</option>
                    <option value="Forensic">Forensic</option>
                    <option value="RelevantId">RelevantId</option>
                    <option value="ResearchDefender">Research Defender</option>
                  </select>
                </div>
                <div class="col-3">
                  <label class="lead" for="require_cx"><span class="invisible">hi</span>
                  </label>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="require_cx" />
                    <label class="form-check-label" for="flexCheckDefault">
                      Require CX
                    </label>
                  </div>
                </div>

                <div class="col">
                  <label class="lead" for="require_cx"> Override CX</label>
                  <input type="text" formControlName="override_cx" class="form-control"
                    placeholder="Take from client" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card h-100">
            <div class="card-header">PII Settings</div>
            <div class="card-body">
              <div class="row mt-4">
                <div class="col">
                  <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Choose PII Settings'"
                    [data]="pII" [ngModelOptions]="{ standalone: true }" [(ngModel)]="selectedPII"
                    (onSelect)="onPiiSelect($event)" (onDeSelect)="onPiiDeSelect($event)">
                  </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <div class="card">
            <div class="card-header">Dedupe Other Projects</div>
            <div class="card-body">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Choose Projects'"
                      [data]="otherProjects" [ngModelOptions]="{ standalone: true }" [(ngModel)]="selectedItems"
                      (onSelect)="onItemSelect($event)" (onDeSelect)="onDeSelect($event)">
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" formControlName="dupeOtherProjectsOnComplete" />
                      <label class="form-check-label" for="flexCheckDefault">
                        Complete
                      </label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" formControlName="dupeOtherProjectsOnStart" />
                      <label class="form-check-label" for="flexCheckDefault">
                        Start
                      </label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" formControlName="dupeOtherProjectsOnQC" />
                      <label class="form-check-label" for="flexCheckDefault">
                        QC
                      </label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" formControlName="dupeOtherProjectsOnTerm" />
                      <label class="form-check-label" for="flexCheckDefault">
                        Term
                      </label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" formControlName="dupeOtherProjectsOnOQ" />
                      <label class="form-check-label" for="flexCheckDefault">
                        OQ
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-3">
        <div class="card-header">Vendors</div>
        <div class="card-body">
          <ng-container formArrayName="segments">
            <div *ngFor="let segment of getsegments.controls; let i = index">
              <div [formGroupName]="i" class="row mt-2">
                <div class="col-5">
                  <label class="lead" for="vendorId">Vendor</label>
                  <select class="form-select" formControlName="vendorId" [ngClass]="{
                      'is-invalid': submitted && segment.get('vendorId')?.errors
                    }">
                    <option [selected]="true" value="">Choose Vendor</option>
                    <option *ngFor="let vendor of vendors" [value]="vendor.value">
                      {{ vendor.option }}
                    </option>
                  </select>

                  <div *ngIf="submitted && segment.get('vendorId')?.errors" class="invalid-feedback">
                    <div *ngIf="segment.get('vendorId')?.errors?.required">
                      Vendor is required
                    </div>
                  </div>
                </div>

                <div class="col">
                  <label class="lead" for="vendorFullLaunchQuota">Quota</label>
                  <input type="number" formControlName="vendorFullLaunchQuota" class="form-control" [ngClass]="{
                      'is-invalid':
                        submitted &&
                        segment.get('vendorFullLaunchQuota')?.errors
                    }" />
                  <div *ngIf="
                      submitted && segment.get('vendorFullLaunchQuota')?.errors
                    " class="invalid-feedback">
                    <div *ngIf="
                        segment.get('vendorFullLaunchQuota')?.errors?.required
                      ">
                      Vendor Quota is required
                    </div>
                  </div>
                </div>

                <div class="col">
                  <label class="lead" for="buyCPI">Buy CPI</label>
                  <input type="number" min="0" step=".1" formControlName="buyCPI" class="form-control" [ngClass]="{
                      'is-invalid': submitted && segment.get('buyCPI')?.errors
                    }" />
                  <div *ngIf="submitted && segment.get('buyCPI')?.errors" class="invalid-feedback">
                    <div *ngIf="segment.get('buyCPI')?.errors?.required">
                      Buy CPI is required
                    </div>
                  </div>
                </div>
                <div class="col invisible">
                  <label class="lead" for="vendorSoftLaunchQuota">Soft Launch Quota</label>
                  <input type="number" formControlName="vendorSoftLaunchQuota" class="form-control" [ngClass]="{
                      'is-invalid':
                        submitted &&
                        segment.get('vendorSoftLaunchQuota')?.errors
                    }" />
                  <div *ngIf="
                      submitted && segment.get('vendorSoftLaunchQuota')?.errors
                    " class="invalid-feedback">
                    <div *ngIf="
                        segment.get('vendorSoftLaunchQuota')?.errors?.required
                      ">
                      Vendor Soft Launch Quota is required
                    </div>
                  </div>
                </div>
                <div class="col-1">
                  <label class="lead" for="vendorFullLaunchQuota"><span class="invisible">hi</span></label>
                  <button *ngIf="getsegments.length > 1" (click)="removeVendor(i)" type="button"
                    class="btn btn-outline-danger form-control bi bi-trash"></button>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="row mt-3">
            <div class="col-3">
              <button type="button" (click)="addVendor()" class="btn btn-outline-success form-control">
                Add Vendor
              </button>
            </div>
          </div>
        </div>
      </div>

      <app-demographics [quotaByTargets]="true" [fullLaunchQuota]="f.fullLaunchQuota.value"
        formControlName="demographics"></app-demographics>
    </div>
    <div class="card-footer">
      <div class="form-group float-end">
        <button type="submit" [disabled]="loading" class="btn btn-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Save
        </button>
        <a routerLink="/projects" class="btn btn-link">Cancel</a>
      </div>
    </div>
  </div>
</form>